import React from 'react';
import { Box, BoxProps } from 'rebass';
import * as R from 'ramda';

const LINEAR_GRADIENT_DEFAULT_STYLE: React.CSSProperties = {
  background:
    'linear-gradient(90deg, #279AE4 0%, #A61ADA 86.75%, #BB05D9 100.86%)',
  borderTopRightRadius: 'inherit',
  borderBottomRightRadius: 'inherit',
  borderBottomLeftRadius: 'inherit',
  borderTopLeftRadius: 'inherit',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const LINEAR_GRADIENT_IN_PROGRESS_STYLE: React.CSSProperties = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
};

interface Props extends Omit<BoxProps, 'css'> {
  gradientWidth: string | number;
}

const LinearGradient: React.FC<Props> = ({
  children,
  gradientWidth,
  style,
  ...props
}) => {
  const linearGradientStyle = React.useMemo(
    () =>
      R.mergeAll([
        LINEAR_GRADIENT_DEFAULT_STYLE,
        R.equals(gradientWidth)('100%') && LINEAR_GRADIENT_IN_PROGRESS_STYLE,
        R.defaultTo({})(style),
        { maxWidth: gradientWidth },
      ]),
    [gradientWidth, style]
  );

  return (
    <Box opacity={0.8} style={linearGradientStyle} {...props}>
      {children}
    </Box>
  );
};

export default LinearGradient;
