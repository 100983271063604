import React from 'react';
import { theme } from '../../contexts/AppTheme';

const FileIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    fill="none"
    viewBox="0 0 31 31"
    {...props}
  >
    <g fill={theme.colors.white} clipPath="url(#clip0)">
      <path d="M22.281 15.5h-3.875a.969.969 0 00-.968.969v7.75c0 .535.433.968.968.968h3.875c1.07 0 1.938-.867 1.938-1.937v-5.813c0-1.07-.868-1.937-1.938-1.937zm0 7.75h-2.906v-5.813h2.906v5.813zM31 17.438V15.5h-4.844a.969.969 0 00-.968.969v8.718h1.937v-3.875H31v-1.937h-3.875v-1.938H31z" />
      <path d="M1.938 28.093V2.906c0-.535.433-.969.968-.969h14.531v3.875c0 1.07.868 1.938 1.938 1.938h3.875v2.906h1.938V6.781a.969.969 0 00-.281-.688L19.094.281A.969.969 0 0018.406 0h-15.5A2.906 2.906 0 000 2.906v25.187A2.906 2.906 0 002.906 31h6.781v-1.938h-6.78a.969.969 0 01-.97-.969z" />
      <path d="M14.531 15.5h-3.875a.969.969 0 00-.969.969v8.718h1.938v-2.906h2.906c1.07 0 1.938-.867 1.938-1.937v-2.907c0-1.07-.868-1.937-1.938-1.937zm0 4.844h-2.906v-2.907h2.906v2.907z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill={theme.colors.white} d="M0 0H31V31H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FileIcon;
