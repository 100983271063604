import React from 'react';
import { Box } from 'rebass';
import * as R from 'ramda';
import { useFirebaseContext } from '../contexts/FirebaseContext';

const EmailLinkHandler: React.FC = () => {
  const { firebaseState } = useFirebaseContext();
  const { error } = firebaseState;

  return !R.isNil(error) ? (
    <Box>Error: {R.propOr('', 'message')(error)}</Box>
  ) : null;
};

export default React.memo(EmailLinkHandler);
