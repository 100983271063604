import React from 'react';
import * as R from 'ramda';

const INIT_CHECKBOX_STATE = false;

const useCheckboxState = (): [
  boolean,
  (e: React.FormEvent<HTMLInputElement>) => void,
] => {
  const [checkboxState, setState] = React.useState(INIT_CHECKBOX_STATE);

  const setCheckboxState = React.useCallback<
    (e: React.FormEvent<HTMLInputElement>) => void
  >(
    (e) =>
      setState(R.pathOr(INIT_CHECKBOX_STATE, ['currentTarget', 'checked'], e)),
    [],
  );

  return [checkboxState, setCheckboxState];
};

export default useCheckboxState;
