import React from 'react';
import { theme } from '../../contexts/AppTheme';

const PlayerPauseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 62 62" fill="none" {...props}>
    <circle cx={31} cy={31} r={31} fill={theme.colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 26h-3v10h3V26zm7 0h-3v10h3V26z"
      fill={theme.colors.primary}
    />
  </svg>
);

export default React.memo(PlayerPauseIcon);
