import React, { memo, useMemo } from 'react';
import { Text, TextProps } from 'rebass';
import { theme } from '../../contexts/AppTheme';
import strings from '../../theme/strings';

export enum HomeButtonType {
  HEADER,
  FORM,
}

interface Props extends Omit<TextProps, 'css' | 'type'> {
  type: HomeButtonType;
}

const HomeButton: React.FC<Props> = ({ type, ...props }) => {
  const color = useMemo(() => {
    switch (type) {
      case HomeButtonType.HEADER:
        return 'primary';
      default:
        return 'white';
    }
  }, [type]);

  const fontSize = useMemo(() => {
    switch (type) {
      case HomeButtonType.HEADER:
        return '1.615rem';
      default:
        return '2.4rem';
    }
  }, [type]);

  return (
    <Text
      color={color}
      fontFamily={theme.fontFamily.logo}
      fontSize={fontSize}
      fontWeight="medium"
      style={{ textTransform: 'uppercase' }}
      {...props}
    >
      {strings.header.homeButton}
    </Text>
  );
};

export default memo(HomeButton);
