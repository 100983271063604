import * as React from 'react';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import authService from '../services/AuthService';

const useAuth = () => {
  const { setIsAuthLoading, loadAuthUser, resetState } = useFirebaseContext();

  React.useEffect(
    () =>
      authService.auth.onAuthStateChanged(async (user) => {
        if (user) {
          const { email, uid } = user;

          await authService.auth.currentUser?.getIdToken(true);

          loadAuthUser({ uid, email });
        } else {
          resetState();
        }

        setIsAuthLoading(false);
      }),
    [loadAuthUser, resetState, setIsAuthLoading],
  );
};

export default useAuth;
