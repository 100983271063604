import React from 'react';
import { Box, Link, Text } from 'rebass';
import * as R from 'ramda';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useTrackSelectors from '../hooks/useTrackSelectors';
import TrackSummary from './TrackSummary';
import { TrackData } from '../models/TrackData';
import FileIcon from './icons/FileIcon';
import strings from '../theme/strings';
import { LINK_DEFAULT_STYLE } from '../theme/constants';
import { COURSE_IMAGE_RESIZE_DURATION_MS } from './CourseImage';
import EndWorkoutButton from './buttons/EndWorkoutButton';

interface Props {
  courseId: string;
  weekId?: number;
}

const TrackList: React.FC<Props> = ({ courseId, weekId }) => {
  const { firebaseState } = useFirebaseContext();
  const { getWeekDocument, getWeekTracks } = useTrackSelectors();

  const weekDocument = React.useMemo(
    () => (!R.isNil(weekId) ? getWeekDocument(firebaseState, weekId) : null),
    [firebaseState, getWeekDocument, weekId]
  );

  const tracks = React.useMemo(
    () => (!R.isNil(weekId) ? getWeekTracks(firebaseState, weekId) : null),
    [firebaseState, getWeekTracks, weekId]
  );

  const renderWeekDocument = React.useCallback(
    () =>
      !R.isNil(weekDocument) && (
        <Link href={weekDocument} target="_blank" style={LINK_DEFAULT_STYLE}>
          <Box
            bg="lightGreen"
            display="flex"
            pt={4}
            pr={12}
            pb={4}
            pl={12}
            style={{ alignItems: 'center' }}
          >
            <FileIcon width="1.8rem" />
            <Text color="white" fontSize={1} lineHeight={0} ml={9}>
              {strings.weekPage.documentLink}
            </Text>
          </Box>
        </Link>
      ),
    [weekDocument]
  );

  const renderTrack = React.useCallback<(track: TrackData) => JSX.Element>(
    (track) => <TrackSummary key={R.prop('id')(track)} track={track} />,
    []
  );

  return (
    <Box
      display="flex"
      overflow="auto"
      style={{
        flexDirection: 'column',
        flexGrow: 0,
        transform: !R.isNil(weekId) ? 'translateY(0px)' : 'translateY(300vh)',
        transitionProperty: 'transform',
        transitionDuration: `${R.divide(
          COURSE_IMAGE_RESIZE_DURATION_MS,
          1000
        )}s`,
        transitionTimingFunction: 'ease',
      }}
    >
      {!R.isNil(weekId) && (
        <>
          <Box overflow="auto">
            {renderWeekDocument()}
            <Box>{!R.isNil(tracks) && R.map(renderTrack, tracks)}</Box>
          </Box>
          <EndWorkoutButton courseId={courseId} weekId={weekId} />
        </>
      )}
    </Box>
  );
};

export default React.memo(TrackList);
