import React from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, Button, Text } from 'rebass';
import { routeLinks } from '../../navigation/routes';
import { LINK_DEFAULT_STYLE } from '../../theme/constants';
import strings from '../../theme/strings';

interface Props extends Omit<BoxProps, 'css'> {
  courseId: string;
}

const MyProgressButton: React.FC<Props> = ({ courseId, ...props }) => (
  <Box display="inline-grid" {...props}>
    <Link
      to={routeLinks.getMyProgressLink(courseId)}
      style={LINK_DEFAULT_STYLE}
    >
      <Button variant="square">
        <Text fontSize={1} lineHeight={0}>
          {strings.coursePage.progressButton}
        </Text>
      </Button>
    </Link>
  </Box>
);

export default React.memo(MyProgressButton);
