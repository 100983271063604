import React from 'react';
import { Box, Text } from 'rebass';
import * as R from 'ramda';
import strings from '../theme/strings';
import WeekSummary from './WeekSummary';
import DetailedWeek from '../models/DetailedWeek';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useCourseSelectors from '../hooks/useCourseSelectors';

export interface Props {
  courseId: string;
}

const WeekList: React.FC<Props> = ({ courseId }) => {
  const { firebaseState } = useFirebaseContext();
  const { getDetailedCourse } = useCourseSelectors();

  const course = React.useMemo(
    () => getDetailedCourse(firebaseState, courseId),
    [courseId, firebaseState, getDetailedCourse]
  );

  const renderWeek = React.useCallback<(week: DetailedWeek) => JSX.Element>(
    (week) => (
      <WeekSummary
        key={R.prop('weekId')(week)}
        courseId={courseId}
        week={week}
      />
    ),
    [courseId]
  );

  return (
    course && (
      <Box
        sx={{
          borderRight: 0,
          borderColor: 'gray',
        }}
      >
        <Text
          as="h2"
          fontWeight="bold"
          fontSize={6}
          lineHeight={5}
          pt={[9, 9, 14, 9]}
          pr={9}
          pb={6}
          pl={9}
        >
          {strings.coursePage.workouts.heading}
        </Text>
        {!R.isNil(course) && R.map(renderWeek, R.propOr([], 'weeks', course))}
      </Box>
    )
  );
};

export default React.memo(WeekList);
