import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Box, SxStyleProp, Text } from 'rebass';
import * as R from 'ramda';
import { Chart, ChartData } from '../models/Chart';
import { formatNumber } from '../utils';
import { theme } from '../contexts/AppTheme';

export const CARTESIAN_GRID_LAST_LINE_STYLE: SxStyleProp = {
  '.recharts-cartesian-grid-horizontal line:last-child, .recharts-cartesian-grid-vertical line:last-child': {
    display: 'none',
  },
};

export const RESPONSIVE_CONTAINER_STYLE: SxStyleProp = {
  '.recharts-responsive-container': {
    overflow: 'hidden',
  },
};

const AFTER_AREA_COLOR_DEFAULT = theme.colors.primary;
const AFTER_AREA_COLOR_IS_BEFORE_DATA = theme.colors.completed;
const AXIS_DOMAIN_DEFAULT = 0;
const TICK_COUNT_DEFAULT = 100;
const MIN_TICK_GAP = 1;
const AREA_STROKE_WIDTH = 2;
const DOT_STROKE_WIDTH = 0.8;
// fixed axis width can cause axis ticks labels displaying bug (example: 4-digit number etc.)
export const AXIS_WIDTH = 32;

interface Props {
  chart: Chart;
}

const AnswersChartSummary: React.FC<Props> = ({ chart }) => {
  const isBeforeData = React.useMemo(
    () => R.any<ChartData>(R.propIs(Number)('before'))(R.prop('data')(chart)),
    [chart]
  );

  const afterAreaColor = React.useMemo(
    () =>
      isBeforeData ? AFTER_AREA_COLOR_IS_BEFORE_DATA : AFTER_AREA_COLOR_DEFAULT,
    [isBeforeData]
  );

  const renderXAxis = React.useCallback(
    () => (
      <XAxis
        dataKey="week"
        axisLine={false}
        tickLine={false}
        tickMargin={8}
        tickFormatter={(tick) => formatNumber(tick)}
        width={AXIS_WIDTH}
        type="number"
        domain={[
          'dataMin',
          `dataMax + ${R.divide(R.length(R.prop('xAxisTicks')(chart)))(15)}`,
        ]}
        ticks={R.prop('xAxisTicks')(chart)}
      />
    ),
    [chart]
  );

  const renderYAxis = React.useCallback(
    () => (
      <YAxis
        axisLine={false}
        tickLine={false}
        allowDecimals={false}
        tickMargin={4}
        tickCount={TICK_COUNT_DEFAULT}
        minTickGap={MIN_TICK_GAP}
        width={AXIS_WIDTH}
        domain={[
          R.defaultTo(AXIS_DOMAIN_DEFAULT)(
            R.head<number>(R.prop('yAxisTicks')(chart))
          ),
          R.defaultTo(AXIS_DOMAIN_DEFAULT)(
            R.last<number>(R.prop('yAxisTicks')(chart))
          ) + R.divide(R.length(R.prop('yAxisTicks')(chart)))(15),
        ]}
        interval="preserveStart"
      />
    ),
    [chart]
  );

  const renderBeforeArea = React.useCallback(
    () =>
      // isAnimationActive={false} fix dots display bug
      isBeforeData && (
        <Area
          dataKey="before"
          stroke={theme.colors.primary}
          strokeWidth={AREA_STROKE_WIDTH}
          fillOpacity={1}
          fill="url(#areaColor)"
          isAnimationActive={false}
          dot={{
            stroke: theme.colors.primary,
            strokeWidth: DOT_STROKE_WIDTH,
            fill: theme.colors.primary,
          }}
        />
      ),
    [isBeforeData]
  );

  const renderAfterArea = React.useCallback(
    // isAnimationActive={false} fix dots display bug
    () => (
      <Area
        dataKey="after"
        stroke={afterAreaColor}
        strokeWidth={AREA_STROKE_WIDTH}
        fillOpacity={1}
        fill="url(#areaColor)"
        isAnimationActive={false}
        dot={{
          stroke: afterAreaColor,
          strokeWidth: DOT_STROKE_WIDTH,
          fill: afterAreaColor,
        }}
      />
    ),
    [afterAreaColor]
  );

  return (
    <Box
      height={0}
      display="flex"
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        text: {
          fontSize: 0,
          fill: 'grayDark',
        },
        ...CARTESIAN_GRID_LAST_LINE_STYLE,
        ...RESPONSIVE_CONTAINER_STYLE,
      }}
    >
      <Text fontWeight="bold" fontSize={3} lineHeight={2} mb={2}>
        {R.prop('name')(chart)}
      </Text>
      <ResponsiveContainer height="100%">
        <AreaChart data={R.prop('data')(chart)}>
          <defs>
            <linearGradient id="areaColor" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.colors.black}
                stopOpacity={0.1}
              />
              <stop
                offset="55%"
                stopColor={theme.colors.black}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          {renderXAxis()}
          {renderYAxis()}
          {renderBeforeArea()}
          {renderAfterArea()}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(AnswersChartSummary);
