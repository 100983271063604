import * as React from 'react';
import { theme } from '../../contexts/AppTheme';

const CircleTickIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <circle
      cx={16}
      cy={16}
      r={15}
      stroke={theme.colors.completed}
      strokeWidth={2}
    />
    <path d="M11 16l4 3 6-7" stroke={theme.colors.completed} strokeWidth={2} />
  </svg>
);

export default React.memo(CircleTickIcon);
