import React from 'react';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';
import { Box, Text } from 'rebass';
import { useTable, Column } from 'react-table';
import useQuestionSelectors from '../hooks/useQuestionSelectors';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import { Routes } from '../navigation/routes';
import useCourseSelectors from '../hooks/useCourseSelectors';
import strings from '../theme/strings';
import { formatNumber } from '../utils';
import { WeekProgressData } from '../models/WeekProgressData';
import useProgressTableSelectors from '../hooks/useProgressTableSelectors';

interface MatchParams {
  courseId: string;
}

const ProgressTable: React.FC = () => {
  const courseMatch = useRouteMatch<MatchParams>(Routes.COURSE_PAGE);

  const { firebaseState } = useFirebaseContext();
  const { getWeeks } = useCourseSelectors();
  const { getWeekProgressData } = useProgressTableSelectors();
  const { getCourseQuestions, getCourseQuizQuestions } = useQuestionSelectors();

  const courseId = React.useMemo(
    () => R.pathOr<string | null>(null, ['params', 'courseId'])(courseMatch),
    [courseMatch],
  );

  const questions = React.useMemo(
    () =>
      !R.isNil(courseId) ? getCourseQuestions(firebaseState, courseId) : null,
    [courseId, firebaseState, getCourseQuestions],
  );

  const courseQuizQuestions = React.useMemo(
    () =>
      !R.isNil(courseId)
        ? getCourseQuizQuestions(firebaseState, courseId)
        : null,
    [courseId, firebaseState, getCourseQuizQuestions],
  );

  const weeks = React.useMemo(
    () => (!R.isNil(courseId) ? getWeeks(firebaseState, courseId) : null),
    [courseId, firebaseState, getWeeks],
  );

  const data = React.useMemo(
    () =>
      !R.isNil(courseId) && !R.isNil(weeks)
        ? R.map(
            (weekId) => getWeekProgressData(firebaseState, courseId, weekId),
            weeks,
          )
        : [],
    [courseId, firebaseState, getWeekProgressData, weeks],
  );

  const columns: Column<WeekProgressData>[] = React.useMemo(
    () =>
      R.unnest([
        [
          {
            accessor: 'weekId', // accessor is the "key" in the data
            Cell: ({ value }: { value: number }) =>
              value && (
                <Text>
                  {strings.coursePage.getWeekName(formatNumber(value))}
                </Text>
              ),
          },
          {
            Header: strings.modals.progressTable.workoutCompleted,
            accessor: 'isWeekFinished',
            Cell: ({ value }: { value: boolean | undefined }) =>
              value ? <Text>x</Text> : '',
          },
        ],
        courseQuizQuestions && !R.isEmpty(courseQuizQuestions)
          ? [
              {
                Header: strings.modals.progressTable.quizCompleted,
                accessor: 'isQuizCompleted',
                Cell: ({ value }: { value: boolean | undefined }) =>
                  value ? <Text>x</Text> : '',
              },
            ]
          : [],
        questions
          ? R.map(
              (question) => ({
                Header: R.prop('question')(question),
                accessor: R.prop('id')(question),
              }),
              questions,
            )
          : [],
      ]),
    [courseQuizQuestions, questions],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<WeekProgressData>({ columns, data });

  return (
    <Box
      bg="white"
      p={10}
      overflow="auto"
      sx={{
        borderRadius: 3,
        table: {
          borderCollapse: 'collapse',
          borderColor: 'gray',
          borderStyle: 0,
          borderWidth: 0,
          borderSpacing: 0,
        },
        th: {
          maxWidth: '6.25rem',
          bg: 'primary',
          color: 'white',
          borderColor: 'gray',
          borderStyle: 0,
          borderWidth: 0,
          textAlign: 'start',
          fontWeight: 'bold',
          fontSize: 1,
          lineHeight: 0,
          paddingX: 5,
          paddingY: 8,
        },
        td: {
          maxWidth: '6.25rem',
          borderColor: 'gray',
          borderStyle: 0,
          borderWidth: 0,
          fontSize: 1,
          lineHeight: 0,
          paddingX: 3,
          paddingY: 4,
          ':first-of-type': {
            fontWeight: 'bold',
          },
        },
      }}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default React.memo(ProgressTable);
