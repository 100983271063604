import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import SignInPage from '../pages/SignInPage';
import { Routes } from './routes';
import ProtectedAuthenticationRoute from './ProtectedAuthenticationRoute';
import CourseNavigator from './CourseNavigator';
import ProtectedCourseRoute from './ProtectedCourseRoute';
import HomeNavigator from './HomeNavigator';

const MainNavigator: React.FC = () => (
  <>
    <Router>
      <Switch>
        <ProtectedAuthenticationRoute
          exact
          path={Routes.SIGN_IN}
          component={SignInPage}
        />
        <ProtectedCourseRoute
          path={Routes.COURSE_PAGE}
          component={CourseNavigator}
        />
        <HomeNavigator />
      </Switch>
    </Router>
  </>
);

export default MainNavigator;
