import React from 'react';
import { useEffectOnce } from 'react-use';
import { usePlayerContext } from '../contexts/PlayerContext';
import useStopwatchIncrease from './useStopwatchIncrease';

const usePlayerEffects = () => {
  const [intervalState, setIntervalState] = React.useState(0);

  const { setIsPlaying, clearPlayer, clearTimeSpent } = usePlayerContext();
  const { stopStopwatch } = useStopwatchIncrease();

  useEffectOnce(() => {
    setIsPlaying(false);
  });

  React.useEffect(() => stopStopwatch, [stopStopwatch]);

  React.useEffect(
    () => () => {
      clearPlayer();
      clearTimeSpent();
    },
    [clearPlayer, clearTimeSpent],
  );

  React.useEffect(() => () => clearInterval(intervalState), [intervalState]);

  return {
    intervalState,
    setIntervalState,
  };
};

export default usePlayerEffects;
