import React from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import DetailedWeek from '../models/DetailedWeek';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserSelectors from '../hooks/useUserSelectors';
import { routeLinks } from '../navigation/routes';
import { LINK_DEFAULT_STYLE } from '../theme/constants';
import WeekSummaryContent from './WeekSummaryContent';

interface Props {
  courseId: string;
  week: DetailedWeek;
}

const WeekSummary: React.FC<Props> = ({ courseId, week }) => {
  const { firebaseState } = useFirebaseContext();
  const { getIsWeekAvailable, getIsWeekOpen } = useUserSelectors();

  const isWeekAvailable = React.useMemo(
    () => getIsWeekAvailable(firebaseState, courseId, R.prop('weekId')(week)),
    [courseId, firebaseState, getIsWeekAvailable, week]
  );

  const weekLinkPath = React.useMemo(
    () =>
      isWeekAvailable
        ? `${routeLinks.getWeekLink(courseId, R.prop('weekId')(week))}`
        : `${routeLinks.getQuizNotCompletedLink(
            courseId,
            R.dec(R.prop('weekId')(week))
          )}`,
    [courseId, isWeekAvailable, week]
  );

  const isWeekOpen = React.useMemo(
    () => getIsWeekOpen(firebaseState, courseId, R.prop('weekId')(week)),
    [courseId, firebaseState, getIsWeekOpen, week]
  );

  return isWeekOpen ? (
    <Link to={weekLinkPath} style={LINK_DEFAULT_STYLE}>
      <WeekSummaryContent week={week} isWeekOpen={isWeekOpen} />
    </Link>
  ) : (
    <WeekSummaryContent week={week} isWeekOpen={isWeekOpen} />
  );
};

export default React.memo(WeekSummary);
