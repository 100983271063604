import React from 'react';
import { Global } from '@emotion/core';
import MainNavigator from './navigation/MainNavigator';
import useAuth from './hooks/useAuth';
import useEmailLinkHandler from './hooks/useEmailLinkHandler';
import { useFirebaseContext } from './contexts/FirebaseContext';

const App: React.FC = () => {
  useEmailLinkHandler();
  useAuth();

  const { firebaseState } = useFirebaseContext();

  const renderNavigator = React.useCallback<() => JSX.Element | null>(
    () => (!firebaseState.isAuthLoading ? <MainNavigator /> : null),
    [firebaseState.isAuthLoading],
  );

  return (
    <>
      {renderNavigator()}
      <Global
        styles={(theme) => ({
          body: {
            fontFamily: theme.fontFamily.body,
            backgroundColor: theme.colors.bg,
            height: theme.sizes[0],
          },
          html: {
            height: theme.sizes[0],
            [`@media (max-width: ${parseFloat(theme.breakpoints[0])}px)`]: {
              fontSize: '12px',
            },
            [`@media (min-width: ${
              parseFloat(theme.breakpoints[0]) + 1
            }px) and (max-width: ${parseFloat(theme.breakpoints[1])}px)`]: {
              fontSize: '14px',
            },
            [`@media (min-width: ${
              parseFloat(theme.breakpoints[1]) + 1
            }px) and (max-width: ${parseFloat(theme.breakpoints[2])}px)`]: {
              fontSize: '16px',
            },
            [`@media (min-width: ${parseFloat(theme.breakpoints[2]) + 1}px)`]: {
              fontSize: '16px',
            },
          },
        })}
      />
    </>
  );
};

export default App;
