import firebase from 'firebase/compat';
import app from '../firebaseConfig';
import actionCodeSettings from '../actionCodeSettings';
import storageService from './StorageService';

class AuthService {
  public readonly auth: firebase.auth.Auth;

  constructor() {
    this.auth = firebase.auth(app);
  }

  async sendSignInLink(email: string) {
    await this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
    storageService.setEmailForSignIn(email);
  }

  async signInWithPassword(email: string, password: string) {
    await this.auth.signInWithEmailAndPassword(email, password);
  }

  async handleSignInLink() {
    if (this.auth.isSignInWithEmailLink(window.location.href)) {
      const email = storageService.getEmailForSignIn();
      if (email != null) {
        await this.auth.signInWithEmailLink(email, window.location.href);
        storageService.removeEmailForSignIn();
      }
    }
  }
}

const authService = new AuthService();

export default authService;
