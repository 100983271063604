const fonts = {
  fontFamily: {
    body: 'Heebo',
    heading: 'inherit',
    logo: 'Quicksand',
  },
  fontSizes: [
    '0.75rem',
    '0.9rem',
    '1rem',
    '1.1rem',
    '1.5rem',
    '1.8rem',
    '2.2rem',
    '2.75rem',
    '3.75rem',
    '4.25rem',
  ],
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
  },
  lineHeights: [
    '1.25rem',
    '1.5rem',
    '1.6rem',
    '2.18rem',
    '2.5rem',
    '3.3rem',
    '3.75rem',
    '4.625rem',
    '6.25rem',
  ],
};

export default fonts;
