import React from 'react';
import { Box, BoxProps, Text } from 'rebass';
import strings from '../theme/strings';
import PlayerControls from './PlayerControls';
import TrackAudioPlayer from './TrackAudioPlayer';
import TrackVideoPlayer from './TrackVideoPlayer';
import useTrackPlayer from '../hooks/useTrackPlayer';
import { theme } from '../contexts/AppTheme';

interface Props extends Omit<BoxProps, 'css'> {
  courseId: string;
  weekId: number;
}

const TrackPlayer: React.FC<Props> = ({ courseId, weekId, ...props }) => {
  const {
    trackAudio,
    trackVideo,
    trackImage,
    isPlaying,
    isTrackReady,
    handlePlayerEnter,
    handlePlayerLeave,
    handlePlayClick,
    handlePauseClick,
  } = useTrackPlayer(courseId, weekId);

  return (
    <Box
      maxHeight="100vh"
      sx={{ flexShrink: 0 }}
      pt={[0, 0, 14, 9]}
      pr={[0, 0, 8, 12]}
      pb={[0, 0, 9]}
      pl={[0, 0, 8, 12]}
      {...props}
    >
      <Text
        as="h3"
        display={['none', 'none', 'block']}
        fontSize={6}
        lineHeight={5}
        mb={4}
      >
        {strings.weekPage.video.heading}
      </Text>
      <Box display="flex" flex={1}>
        <Box
          display="flex"
          flex={1}
          onMouseMove={handlePlayerEnter}
          onMouseLeave={handlePlayerLeave}
          onTouchStart={handlePlayerEnter}
          onTouchEnd={handlePlayerLeave}
          onClick={
            isTrackReady
              ? isPlaying
                ? handlePauseClick
                : handlePlayClick
              : undefined
          }
          style={{
            position: 'relative',
            cursor: isTrackReady ? 'pointer' : 'initial',
          }}
          // fix player bottom line displaying bug
          sx={{
            backgroundColor: theme.colors.black,
            video: {
              marginBottom: '-3px',
            },
          }}
        >
          <TrackVideoPlayer
            display={isTrackReady ? 'initial' : 'none'}
            trackVideo={trackVideo}
            trackImage={trackImage}
            isPlaying={isPlaying}
          />
          {!isTrackReady && (
            <Box
              width={0}
              height={9}
              backgroundColor={theme.colors.grayMediumLight}
            />
          )}
          <PlayerControls />
        </Box>
        <TrackAudioPlayer
          courseId={courseId}
          weekId={weekId}
          trackAudio={trackAudio}
          isPlaying={isPlaying}
        />
      </Box>
    </Box>
  );
};

export default React.memo(TrackPlayer);
