import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { Box, Text } from 'rebass';
import useInputState from './useInputState';
import useCheckboxState from './useCheckboxState';
import { AppSelect, AppType } from '../appSelect';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import PasswordInput from '../components/PasswordInput';
import strings from '../theme/strings';

export enum SIGN_IN_MODE {
  EMAIL_AND_PASSWORD = 'EMAIL_AND_PASSWORD',
  PASSWORDLESS = 'PASSWORDLESS',
}

const useSignInForm = () => {
  const [email, onEmailChange] = useInputState();
  const [password, onPasswordChange] = useInputState();
  const [conditions, onConditionsChange] = useCheckboxState();
  const [privacy, onPrivacyChange] = useCheckboxState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const mode = AppSelect({
    [AppType.KRANKENKASSEN]: SIGN_IN_MODE.EMAIL_AND_PASSWORD,
    default: SIGN_IN_MODE.PASSWORDLESS,
  });

  const { firebaseState, sendSignInLink, signInWithPassword } =
    useFirebaseContext();

  const { error, isSubmitLoading } = firebaseState;

  const isAllCheckboxesChecked = conditions && privacy;

  const isFormSubmitAvailable =
    isAllCheckboxesChecked && !isSubmitLoading && !formSubmitted;

  React.useEffect(() => {
    if (!R.isEmpty(email) || !R.isEmpty(password)) {
      setFormSubmitted(false);
    }
  }, [email, mode, password]);

  const onFormSubmit = React.useCallback<
    (e: React.FormEvent<HTMLInputElement>) => void
  >(
    (e) => {
      e.preventDefault();

      if (!isFormSubmitAvailable) return;

      switch (mode) {
        case SIGN_IN_MODE.EMAIL_AND_PASSWORD:
          signInWithPassword(email, password);
          break;

        case SIGN_IN_MODE.PASSWORDLESS:
          sendSignInLink(email);
          break;
      }

      setFormSubmitted(true);
    },
    [
      isFormSubmitAvailable,
      mode,
      signInWithPassword,
      email,
      password,
      sendSignInLink,
    ],
  );

  const togglePasswordVisibility = useCallback(
    () => setPasswordVisible(R.not),
    [],
  );

  const renderPassword = React.useCallback(
    () =>
      R.equals(SIGN_IN_MODE.EMAIL_AND_PASSWORD, mode) ? (
        <Box>
          <PasswordInput
            visible={passwordVisible}
            onVisibilityClick={togglePasswordVisibility}
            value={password}
            onChange={onPasswordChange}
          />
        </Box>
      ) : null,
    [
      mode,
      passwordVisible,
      togglePasswordVisibility,
      password,
      onPasswordChange,
    ],
  );

  const renderNotification = React.useCallback(() => {
    switch (mode) {
      case SIGN_IN_MODE.EMAIL_AND_PASSWORD:
        return null;

      case SIGN_IN_MODE.PASSWORDLESS:
        return formSubmitted && !isSubmitLoading ? (
          <Text color={error ? 'error' : 'primary'} fontSize={0}>
            {!R.isNil(error)
              ? R.propOr('', 'message')(error)
              : strings.signInPage.getLinkSentMessage(email)}
          </Text>
        ) : null;

      default:
        return null;
    }
  }, [mode, formSubmitted, isSubmitLoading, error, email]);

  return {
    email,
    onEmailChange,
    onConditionsChange,
    onPrivacyChange,
    isFormSubmitAvailable,
    onFormSubmit,
    renderNotification,
    renderPassword,
  };
};

export default useSignInForm;
