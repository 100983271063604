import React, { CSSProperties } from 'react';
import { Box, Flex, SxStyleProp } from 'rebass';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import * as R from 'ramda';
import { Tooltip, Zoom } from '@material-ui/core';
import { TrackData, TrackTypes } from '../models/TrackData';
import useTrackSelectors from '../hooks/useTrackSelectors';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import { isTrackTypeCurrentOrBlocked, scrollToRef } from '../utils';
import { usePlayerContext } from '../contexts/PlayerContext';
import TickIcon from './icons/TickIcon';
import useUserSelectors from '../hooks/useUserSelectors';
import useStopwatchIncrease from '../hooks/useStopwatchIncrease';
import useCourseSelectors from '../hooks/useCourseSelectors';
import { COURSE_IMAGE_RESIZE_DURATION_MS } from './CourseImage';
import TrackProgress from './TrackProgress';
import { theme } from '../contexts/AppTheme';
import LockIcon from './icons/LockIcon';
import strings from '../theme/strings';

interface Props {
  track: TrackData;
}

const ABSOLUTE_FILL_STYLE: CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const BLOCKED_BACKGROUND_STYLE: SxStyleProp = {
  ':hover': { opacity: 1 },
  transition: 'opacity ease-in-out .2s',
};

const TrackSummary: React.FC<Props> = ({ track }) => {
  const { firebaseState, setCurrentTrack } = useFirebaseContext();
  const { clearPlayer } = usePlayerContext();
  const { getCourseCover } = useCourseSelectors();
  const { getTrackPreview } = useTrackSelectors();
  const { getTrackType } = useUserSelectors();
  const { startStopwatch, stopStopwatch } = useStopwatchIncrease();

  const trackRef = React.useRef(null);

  const trackType = React.useMemo(
    () =>
      getTrackType(
        firebaseState,
        R.prop('course')(track),
        R.prop('week')(track),
        track,
      ),
    [firebaseState, getTrackType, track],
  );

  const courseCover = React.useMemo(
    () => getCourseCover(firebaseState, R.prop('course')(track)),
    [firebaseState, getCourseCover, track],
  );

  const trackPreview = React.useMemo(
    () => getTrackPreview(firebaseState, R.prop('id')(track)) || courseCover,
    [courseCover, firebaseState, getTrackPreview, track],
  );

  const renderBackgroundEffect = React.useCallback(() => {
    if (R.equals(trackType)(TrackTypes.COMPLETED)) {
      return (
        <Box
          display="flex"
          bg="rgba(255, 255, 255, 0.5)"
          p={4}
          style={ABSOLUTE_FILL_STYLE}
        >
          <TickIcon />
        </Box>
      );
    }

    if (trackType === TrackTypes.BLOCKED) {
      return (
        <Box
          display="flex"
          p={4}
          style={ABSOLUTE_FILL_STYLE}
          opacity={0}
          sx={BLOCKED_BACKGROUND_STYLE}
        >
          <Tooltip
            arrow
            title={strings.weekPage.blockedTrackTooltip}
            TransitionComponent={Zoom}
          >
            <Flex>
              <LockIcon />
            </Flex>
          </Tooltip>
        </Box>
      );
    }

    return null;
  }, [trackType]);

  useEffectOnce(() => {
    if (R.equals(trackType)(TrackTypes.CURRENT))
      setTimeout(() => scrollToRef(trackRef), COURSE_IMAGE_RESIZE_DURATION_MS);
  });

  useUpdateEffect(() => {
    if (R.equals(trackType)(TrackTypes.CURRENT)) scrollToRef(trackRef);
  }, [trackType]);

  const startCurrentTrack = React.useCallback(() => {
    stopStopwatch();
    clearPlayer();

    setCurrentTrack(track.course, track.week, track.id);
    startStopwatch(track.course, track.week);
  }, [clearPlayer, setCurrentTrack, startStopwatch, stopStopwatch, track]);

  const handleTrackClick = !isTrackTypeCurrentOrBlocked(trackType)
    ? startCurrentTrack
    : undefined;

  return (
    <Box
      display="flex"
      height={6}
      ref={trackRef}
      onClick={handleTrackClick}
      sx={{ borderBottom: 0, borderColor: 'gray' }}
      style={{
        position: 'relative',
        justifyContent: 'space-between',
        cursor: 'pointer',
      }}
    >
      <TrackProgress track={track} trackType={trackType} />
      {renderBackgroundEffect()}
      <Box
        bg="#E1E1E1"
        width={6}
        style={{
          backgroundImage: `${theme.colors.whiteTransparentLightGradient}, url(${trackPreview})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

export default React.memo(TrackSummary);
