import React from 'react';
import {
  Route,
  RouteComponentProps,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import ModalScreen from '../components/ModalScreen';

interface Props extends RouteProps {
  component: React.ComponentType;
  path: string;
  parentPath?: string;
}

const ModalRoute: React.FC<Props> = ({
  component: Component,
  parentPath,
  ...rest
}) => {
  const history = useHistory();

  const handleModalClick = React.useCallback(
    () => (parentPath ? history.push(parentPath) : history.goBack()),
    [history, parentPath],
  );

  const renderRouteContent = React.useCallback<
    (props: RouteComponentProps) => JSX.Element
  >(
    (props) => (
      // @ts-ignore
      <ModalScreen
        onClose={handleModalClick}
        onEscapeKeyDown={handleModalClick}
        // eslint-disable-next-line react/jsx-boolean-value
        open={true}
      >
        <Component {...rest} {...props} />
      </ModalScreen>
    ),
    [Component, handleModalClick, rest],
  );

  return <Route {...rest} render={renderRouteContent} />;
};

export default ModalRoute;
