const strings = {
  header: {
    accountButton: 'Account',
    homeButton: 'Home',
    signOutButton: 'Ausloggen',
  },
  mainPage: {
    heading:
      'Hallo\nKlicke auf deinen gebuchten Kurs und starte mit der Kurseinheit',
    getWeeksNumber: (weeks: string) =>
      parseInt(weeks, 10) > 1 ? `${weeks} Wochen` : `${weeks} Woche`,
    getCourseExpiration: (date: string) => `Verfügbar bis ${date}`,
  },
  signInPage: {
    heading: 'Login',
    homeButton: 'Home',
    emailInputLabel: 'Email',
    loginButton: 'Login',
    passwordInputLabel: 'Password',
    getLinkSentMessage: (email: string) =>
      `Link zum Login wurde an ${email} geschickt`,
  },
  coursePage: {
    progressButton: 'absolvierte Kurseinheiten',
    workouts: {
      heading: 'Kurseinheit wählen',
      and: 'und',
      timeSpent: 'Trainingszeit',
      currentTrack: 'Aktuelle Woche',
    },
    completedWeeks: {
      heading: 'Abgeschlossen',
    },
    progressChart: {
      heading: 'Kursfortschritt',
      yAxisTicks: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      xAxisLabel: 'Woche',
    },
    supportContact: {
      root: 'Kontakt zum Kursleiter und technischer Support: ',
      mail: 'info@fittracks.de',
    },
    getWeekName: (week: string | null) => `Woche ${week}`,
  },
  weekPage: {
    blockedTrackTooltip: 'zum Freischalten vorherigen Titel anhören',
    endWorkoutButton: 'Kurseinheit beenden',
    documentLink: 'Teilnehmerunterlagen herunterladen',
    video: {
      heading: 'aktuelle Kurseinheit',
    },
  },
  modals: {
    courseNotBought: {
      heading: 'Kurs nicht gebucht',
      details:
        'Kliche auf den Button um den Kurs auf unserer Homepage zu buchen',
      homePageLink: 'Zur Homepage',
    },
    quizNotCompleted: {
      heading: 'Quiz der letzten Kurseinheit noch nicht erledigt',
      details:
        'Letzte Kurseinheit muss vollständig absolviert sein um mit einer neuen Einheit zu beginnen',
      prevWeekQuizLink: 'Zum Quiz',
    },
    questionTest: {
      wrongQuizAnswer: 'Falsche Antwort. Versuch es nochmal',
      submitButton: 'Antworten spreichern',
    },
    progressTable: {
      workoutCompleted: 'Kurseinheit absolviert',
      quizCompleted: 'Quiz erledigt',
    },
  },
  other: {
    conditions: 'AGB',
    privacyPolicy: 'Datenschutz',
    cancelButton: 'Abbrechen',
  },
};

export default strings;
