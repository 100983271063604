import React from 'react';
import moment from 'moment';
import 'moment-duration-format';
import * as R from 'ramda';
import strings from './theme/strings';
import { AppSelect, AppType } from './appSelect';
import { TrackTypes } from './models/TrackData';

export type Maybe<T> = null | undefined | T;

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<A extends {} | null>() {
  const ctx = React.createContext<A | undefined>(undefined);

  function useCtx() {
    const c = React.useContext(ctx);
    if (c === undefined)
      throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }

  return [useCtx, ctx] as const; // 'as const' makes TypeScript infer a tuple
}

export const formatNumber = (number: number) =>
  number.toString().padStart(2, '0');

export const ceilDuration = (duration: number) =>
  Math.ceil(duration / 1000) * 1000;

type WeekDay = 'days' | 'hours' | 'minutes' | 'seconds';

interface HumanDurationAccumulator {
  time: number;
  result: string[];
}

export const getHumanDuration = (duration: number) => {
  const unitOfTimes: WeekDay[] = ['days', 'hours', 'minutes', 'seconds'];
  const { result } = unitOfTimes.reduce<HumanDurationAccumulator>(
    // eslint-disable-next-line no-shadow
    ({ time, result }, unit) => {
      const value = moment.duration(time).get(unit);
      const unitDuration = moment.duration(value, unit);
      return {
        time: time - unitDuration.asMilliseconds(),
        result: value > 0 ? [...result, unitDuration.humanize()] : result,
      };
    },
    { time: duration, result: [] },
  );

  return result.join(` ${strings.coursePage.workouts.and} `);
};

export const formatDuration = (duration: number) =>
  moment
    .duration(Math.round(duration), 'seconds')
    .format('mm:ss', { trim: false });

export const formatFullDate = (duration: number) =>
  moment(duration).format('DD.MM.YY');

export const addColon = (string: string) => `${string}: `;

export const scrollToRef = (ref: React.RefObject<HTMLInputElement>) =>
  ref.current &&
  ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

export const getCurrentTimeMs = () => moment().valueOf();

export const getStartOfWeekMs = () =>
  moment().startOf('isoWeek').startOf('day').valueOf();

export const getStartOfNextWeekMs = () =>
  moment().add(1, 'weeks').startOf('isoWeek').startOf('day').valueOf();

export const getIsLandscapeOrientation = () => {
  const rotationAngle = window.screen.orientation
    ? window.screen.orientation.angle
    : // it's deprecated but works fine for iOS Safari
      window.orientation;

  return rotationAngle === 90 || rotationAngle === -90;
};

export const getObjectLength = R.pipe(R.keys, R.length);

export const isAllContentAvailable = () =>
  AppSelect({ [AppType.KRANKENKASSEN]: true, default: false });

export const isTrackTypeCurrentOrBlocked = R.includes(R.__, [
  TrackTypes.CURRENT,
  TrackTypes.BLOCKED,
]);
