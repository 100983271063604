/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as R from 'ramda';
import { PlayerState } from '../store/reducers/playerReducer';
import { PlayerProgress } from '../models/PlayerProgress';

const usePlayerSelectors = () => {
  const getIsPlaying = React.useCallback<(state: PlayerState) => boolean>(
    R.prop('isPlaying'),
    [],
  );

  const getIsControlsVisible = React.useCallback<
    (state: PlayerState) => boolean
  >(R.prop('isControlsVisible'), []);

  const getIsAudioReady = React.useCallback<(state: PlayerState) => boolean>(
    R.prop('isAudioReady'),
    [],
  );

  const getIsVideoReady = React.useCallback<(state: PlayerState) => boolean>(
    R.prop('isVideoReady'),
    [],
  );

  const getIsImageReady = React.useCallback<(state: PlayerState) => boolean>(
    R.prop('isImageReady'),
    [],
  );

  const getPlayerProgress = React.useCallback<
    (state: PlayerState) => PlayerProgress | null
  >(R.prop('playerProgress'), []);

  const getPlayerTimeSpent = React.useCallback<(state: PlayerState) => number>(
    R.prop('playerTimeSpent'),
    [],
  );

  const getIsTrackPlaybackStarted = React.useCallback<
    (state: PlayerState) => boolean
  >(R.pathOr(false, ['playerProgress', 'played']), []);

  return {
    getIsPlaying,
    getIsControlsVisible,
    getIsAudioReady,
    getIsVideoReady,
    getIsImageReady,
    getIsTrackPlaybackStarted,
    getPlayerProgress,
    getPlayerTimeSpent,
  };
};

export default usePlayerSelectors;
