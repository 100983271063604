import React from 'react';
import { Checkbox, CheckboxProps, Label } from '@rebass/forms';
import { Box } from 'rebass';

interface Props extends Omit<CheckboxProps, 'css'> {}

const FTCheckbox: React.FC<Props> = ({ children, ...props }) => (
  <Box
    sx={{
      'input:focus ~ svg': {
        bg: 'initial',
      },
    }}
  >
    <Label style={{ alignItems: 'center' }}>
      <Checkbox {...props} />
      {children}
    </Label>
  </Box>
);

export default FTCheckbox;
