import React from 'react';
import { createCtx } from '../utils';
import playerReducer, { PlayerState } from '../store/reducers/playerReducer';
import { PlayerActions } from '../store/actions/actionTypes';
import { PlayerProgress } from '../models/PlayerProgress';

interface Context {
  playerState: PlayerState;
  setIsPlaying: (isPlaying: boolean) => void;
  setIsControlsVisible: (isControlsVisible: boolean) => void;
  setIsAudioReady: (isAudioReady: boolean) => void;
  setIsVideoReady: (isVideoReady: boolean) => void;
  setIsImageReady: (isImageReady: boolean) => void;
  setPlayerProgress: (playerProgress: PlayerProgress) => void;
  incrementPlayerTimeSpent: (increment: number) => void;
  clearPlayer: () => void;
  clearTimeSpent: () => void;
  stopwatchUpdateInterval: number;
  setStopwatchUpdateInterval: React.Dispatch<React.SetStateAction<number>>;
}

export const [usePlayerContext, PlayerContext] = createCtx<Context>();

export const initPlayerState: PlayerState = {
  isPlaying: true,
  isControlsVisible: true,
  isAudioReady: false,
  isVideoReady: false,
  isImageReady: false,
  playerProgress: null,
  playerTimeSpent: 0,
};

export const PlayerContextProvider: React.FC = ({ children }) => {
  const [playerState, dispatch] = React.useReducer(
    playerReducer,
    initPlayerState,
  );

  const [stopwatchUpdateInterval, setStopwatchUpdateInterval] =
    React.useState(0);

  const clearPlayer = React.useCallback(
    () => dispatch({ type: PlayerActions.CLEAR_PLAYER }),
    [],
  );

  const clearTimeSpent = React.useCallback(
    () => dispatch({ type: PlayerActions.CLEAR_TIME_SPENT }),
    [],
  );

  const setIsPlaying = React.useCallback<(isPlaying: boolean) => void>(
    (isPlaying) =>
      dispatch({ type: PlayerActions.SET_IS_PLAYING, payload: isPlaying }),
    [],
  );

  const setIsAudioReady = React.useCallback<(isAudioReady: boolean) => void>(
    (isAudioReady) =>
      dispatch({
        type: PlayerActions.SET_IS_AUDIO_READY,
        payload: isAudioReady,
      }),
    [],
  );

  const setIsVideoReady = React.useCallback<(isVideoReady: boolean) => void>(
    (isVideoReady) =>
      dispatch({
        type: PlayerActions.SET_IS_VIDEO_READY,
        payload: isVideoReady,
      }),
    [],
  );

  const setIsImageReady = React.useCallback<(isImageReady: boolean) => void>(
    (isImageReady) =>
      dispatch({
        type: PlayerActions.SET_IS_IMAGE_READY,
        payload: isImageReady,
      }),
    [],
  );

  const setIsControlsVisible = React.useCallback<
    (isControlsVisible: boolean) => void
  >(
    (isControlsVisible) =>
      dispatch({
        type: PlayerActions.SET_IS_CONTROLS_VISIBLE,
        payload: isControlsVisible,
      }),
    [],
  );

  const setPlayerProgress = React.useCallback<
    (playerProgress: PlayerProgress) => void
  >(
    (playerProgress) =>
      dispatch({
        type: PlayerActions.SET_PLAYER_PROGRESS,
        payload: playerProgress,
      }),
    [],
  );

  const incrementPlayerTimeSpent = React.useCallback<
    (increment: number) => void
  >(
    (increment) =>
      dispatch({
        type: PlayerActions.INCREMENT_PLAYER_TIME_SPENT,
        payload: increment,
      }),
    [],
  );

  return (
    <PlayerContext.Provider
      value={{
        playerState,
        setIsPlaying,
        setIsControlsVisible,
        setIsAudioReady,
        setIsVideoReady,
        setIsImageReady,
        setPlayerProgress,
        incrementPlayerTimeSpent,
        clearPlayer,
        clearTimeSpent,
        stopwatchUpdateInterval,
        setStopwatchUpdateInterval,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
