import React from 'react';
import { Box, SxStyleProp } from 'rebass';
import * as R from 'ramda';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { AnswerData } from '../models/DetailedCourse';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import AreaChartSummary from './AnswersChartSummary';
import 'pure-react-carousel/dist/react-carousel.es.css';
import PrevChartIcon from './icons/PrevChartIcon';
import NextChartIcon from './icons/NextChartIcon';
import { BUTTON_DEFAULT_STYLES } from '../theme/buttons';
import useCourseSelectors from '../hooks/useCourseSelectors';
import useChartSelectors from '../hooks/useChartSelectors';
import ProgressChartSummary from './ProgressChartSummary';
import useUserSelectors from '../hooks/useUserSelectors';
import MyProgressButton from './buttons/MyProgressButton';
import { theme } from '../contexts/AppTheme';
import SupportContact from './SupportContact';

const CAROUSEL_NAVIGATION_BUTTONS_STYLE: SxStyleProp = {
  '.carousel__back-button, .carousel__next-button': {
    ...BUTTON_DEFAULT_STYLES,
    borderRadius: '100%',
    border: 'none',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
};

const CAROUSEL_DOTS_STYLE: SxStyleProp = {
  '.carousel__dot-group': {
    display: 'flex',
    alignItems: 'center',
  },
  '.carousel__dot': {
    ...BUTTON_DEFAULT_STYLES,
    borderRadius: '100%',
    border: 'none',
    background: theme.colors.grayMediumLight,
    display: 'flex',
    alignItems: 'center',
    marginRight: 3,
    marginLeft: 3,
    width: 4,
    height: 4,
    padding: 0,
    ':first-of-type': {
      marginRight: 0,
    },
    ':last-of-type': {
      marginLeft: 0,
    },
    ':nth-of-type(2)': {
      marginLeft: 3,
    },
  },
  '.carousel__dot--selected': {
    background: theme.colors.primary,
  },
};

export const DEFAULT_SLIDES_NUM = 1;
export const MINIMAL_POINTS_TO_DISPLAY = 2;
export const ICON_WIDTH = '2rem';
export const NATURAL_SLIDE_WIDTH = 100;
export const NATURAL_SLIDE_HEIGHT = 120;

interface Props {
  courseId: string;
}

const ChartCarousel: React.FC<Props> = ({ courseId }) => {
  const { firebaseState } = useFirebaseContext();
  const { getWeeks } = useCourseSelectors();
  const { getFinishedWeeks } = useUserSelectors();
  const {
    getAnswersData,
    getAnswersChart,
    getProgressChart,
  } = useChartSelectors();

  const answersData = React.useMemo(
    () => getAnswersData(firebaseState, courseId),
    [courseId, firebaseState, getAnswersData]
  );

  const answersNum = React.useMemo(
    () => R.length(R.defaultTo([])(answersData)),
    [answersData]
  );

  const weeks = React.useMemo(() => getWeeks(firebaseState, courseId), [
    courseId,
    firebaseState,
    getWeeks,
  ]);

  const finishedWeeks = React.useMemo(
    () => getFinishedWeeks(firebaseState, courseId),
    [courseId, firebaseState, getFinishedWeeks]
  );

  const isSliderBottomVisible = React.useMemo(
    () =>
      !R.isNil(finishedWeeks)
        ? R.gt(answersNum)(0) && !R.isEmpty(finishedWeeks)
        : R.gt(answersNum)(1),
    [answersNum, finishedWeeks]
  );

  const renderAnswersChart = React.useCallback<
    (answerData: AnswerData, index: number) => JSX.Element | null
  >(
    (answerData, index) => {
      if (R.isNil(weeks)) return null;

      const chart = getAnswersChart(answerData, weeks);

      return (
        <Slide key={index} index={index}>
          <AreaChartSummary chart={chart} />
        </Slide>
      );
    },
    [getAnswersChart, weeks]
  );

  const renderProgressChart = React.useCallback(() => {
    if (R.isNil(weeks)) return null;

    const chart = getProgressChart(firebaseState, courseId, weeks);

    return (
      <Slide index={answersNum + 1}>
        <ProgressChartSummary chart={chart} />
      </Slide>
    );
  }, [answersNum, courseId, firebaseState, getProgressChart, weeks]);

  const renderSlider = React.useCallback(
    () => (
      <Box bg={theme.colors.white} pt={5} pr={7} pb={3} pl={7}>
        <Slider>
          {!R.isNil(answersData) &&
            R.addIndex<AnswerData, JSX.Element | null>(R.map)(
              renderAnswersChart,
              answersData
            )}
          {renderProgressChart()}
        </Slider>
      </Box>
    ),
    [answersData, renderAnswersChart, renderProgressChart]
  );

  const renderSliderBottom = React.useCallback(
    () =>
      isSliderBottomVisible ? (
        <Box
          display="flex"
          mt={6}
          sx={{
            justifyContent: 'center',
            ...CAROUSEL_NAVIGATION_BUTTONS_STYLE,
            ...CAROUSEL_DOTS_STYLE,
          }}
        >
          <ButtonBack>
            <PrevChartIcon width={ICON_WIDTH} />
          </ButtonBack>
          <DotGroup />
          <ButtonNext>
            <NextChartIcon width={ICON_WIDTH} />
          </ButtonNext>
        </Box>
      ) : null,
    [isSliderBottomVisible]
  );

  return (
    <Box
      display="flex"
      pt={[12, 12, 12, 14]}
      pr={[12, 14, 12]}
      pb={12}
      pl={[12, 14, 12]}
      style={{
        flexDirection: 'column',
      }}
    >
      {((!R.isNil(answersData) && !R.isEmpty(answersData)) ||
        (!R.isNil(finishedWeeks) && !R.isEmpty(finishedWeeks))) && (
        <Box>
          <CarouselProvider
            naturalSlideWidth={NATURAL_SLIDE_WIDTH}
            naturalSlideHeight={NATURAL_SLIDE_HEIGHT}
            totalSlides={R.defaultTo(DEFAULT_SLIDES_NUM)(R.inc(answersNum))}
            infinite
          >
            {renderSlider()}
            {renderSliderBottom()}
          </CarouselProvider>
        </Box>
      )}
      <MyProgressButton courseId={courseId} mt={9} />
      <SupportContact mt={6} />
    </Box>
  );
};

export default React.memo(ChartCarousel);
