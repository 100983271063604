/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as R from 'ramda';
import { FirebaseState } from '../store/reducers/firebaseReducer';
import { Course } from '../models/Course';
import DetailedCourse, {
  AnswerData,
  AnswerTypes,
} from '../models/DetailedCourse';
import DetailedWeek, { WeekType, WeekTypes } from '../models/DetailedWeek';
import useUserSelectors from './useUserSelectors';
import useQuestionSelectors from './useQuestionSelectors';
import useTrackSelectors from './useTrackSelectors';
import { WeekProgress } from '../models/UserData';
import { CourseCovers } from '../models/CourseCovers';
import { getObjectLength } from '../utils';

const useCourseSelectors = () => {
  const {
    getUserAnswers,
    getUserWeekProgress,
    getIsWeekFinished,
    getWeekFinishedTracks,
  } = useUserSelectors();
  const { getWeekTracks } = useTrackSelectors();
  const { getCourseQuestions, getQuestionAnswers } = useQuestionSelectors();

  const getCourses = React.useCallback<(state: FirebaseState) => Course[]>(
    R.prop('courses'),
    [],
  );

  const getCourse = React.useCallback<
    (state: FirebaseState, courseId: string) => Course | undefined
  >(
    (state, courseId) => R.find(R.propEq('id', courseId), getCourses(state)),
    [getCourses],
  );

  const getCoursesCovers = React.useCallback<
    (state: FirebaseState) => CourseCovers
  >(R.prop('coursesCovers'), []);

  const getCourseCover = React.useCallback<
    (state: FirebaseState, courseId: string) => string
  >((state, courseId) => R.prop(courseId)(getCoursesCovers(state)), []);

  const getCompletedWeeksNum = React.useCallback<
    (detailedWeeks: DetailedWeek[]) => number
  >(
    (detailedWeeks) =>
      R.length(R.filter(R.propEq('type', WeekTypes.COMPLETED), detailedWeeks)),
    [],
  );

  const getWeeks = React.useCallback<
    (state: FirebaseState, courseId: string) => number[] | null
  >(
    (state, courseId) => {
      const course = getCourse(state, courseId);
      if (R.isNil(course)) return null;

      return R.pipe<Course, number, number, number[]>(
        R.prop('weeksNum'),
        R.inc,
        R.range(1),
      )(course);
    },
    [getCourse],
  );

  const getCourseAnswers = React.useCallback<
    (state: FirebaseState, courseId: string) => AnswerData[] | null
  >(
    (state, courseId): AnswerData[] | null => {
      const userAnswers = getUserAnswers(state);
      const weeks = getWeeks(state, courseId);
      if (R.isNil(userAnswers) || R.isNil(weeks)) return null;

      return R.map(
        (question) => ({
          afterAnswers: getQuestionAnswers(
            weeks,
            userAnswers,
            question,
            AnswerTypes.after,
          ),
          answerName: R.prop('question')(question),
          beforeAnswers: getQuestionAnswers(
            weeks,
            userAnswers,
            question,
            AnswerTypes.before,
          ),
        }),
        getCourseQuestions(state, courseId),
      );
    },
    [getCourseQuestions, getQuestionAnswers, getUserAnswers, getWeeks],
  );

  const getWeekType = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => WeekType
  >(
    (state, courseId, weekId) => {
      if (getIsWeekFinished(state, courseId, weekId))
        return WeekTypes.COMPLETED;

      const userWeekProgress = getUserWeekProgress(state, courseId, weekId);
      if (R.propOr(false, 'currentTrack', userWeekProgress)) {
        return WeekTypes.CURRENT;
      }

      return WeekTypes.OTHER;
    },
    [getIsWeekFinished, getUserWeekProgress],
  );

  const getWeekProgress = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => number | null
  >(
    (state, courseId, weekId) => {
      const tracks = getWeekTracks(state, weekId);
      const finishedTracks = getWeekFinishedTracks(state, courseId, weekId);

      return !R.isEmpty(tracks)
        ? R.divide(R.multiply(getObjectLength(finishedTracks))(100))(
            R.length(tracks),
          )
        : 0;
    },
    [getUserWeekProgress, getWeekTracks],
  );

  const getWeekTimeSpent = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
    ) => number | null | undefined
  >(
    (state, courseId, weekId) =>
      R.unless<WeekProgress | undefined, number | null>(
        R.isNil,
        R.propOr(null, 'timeSpent'),
      )(getUserWeekProgress(state, courseId, weekId)),
    [getUserWeekProgress],
  );

  const getDetailedWeeks = React.useCallback<
    (state: FirebaseState, courseId: string) => DetailedWeek[] | null
  >(
    (state, courseId) => {
      const course = getCourse(state, courseId);
      if (R.isNil(course)) return null;

      return R.times((index) => {
        const weekId = R.inc(index);
        const type = getWeekType(state, courseId, weekId);
        const progress = getWeekProgress(state, courseId, weekId);
        const timeSpent = getWeekTimeSpent(state, courseId, weekId);
        return {
          weekId,
          type,
          progress,
          timeSpent,
        };
      })(R.prop('weeksNum')(course));
    },
    [getCourse, getWeekProgress, getWeekTimeSpent, getWeekType],
  );

  const getDetailedCourse = React.useCallback<
    (state: FirebaseState, courseId: string) => DetailedCourse | null
  >(
    (state, courseId) => {
      const course = getCourse(state, courseId);
      const weeks = getDetailedWeeks(state, courseId);
      const answersData = getCourseAnswers(state, courseId);

      if (R.isNil(course)) return null;

      return {
        id: courseId,
        name: R.prop('name')(course),
        cover: R.prop('cover')(course),
        weeksNum: R.prop('weeksNum')(course),
        completedWeeks: getCompletedWeeksNum(R.defaultTo([], weeks)),
        weeks,
        answersData,
      };
    },
    [getCompletedWeeksNum, getCourse, getCourseAnswers, getDetailedWeeks],
  );

  return {
    getCourses,
    getCourseCover,
    getCourseAnswers,
    getDetailedCourse,
    getWeeks,
  };
};

export default useCourseSelectors;
