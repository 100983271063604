import React from 'react';
import { Box } from 'rebass';
import WeekList from '../components/WeekList';
import ChartCarousel from '../components/ChartCarousel';
import ProgressTable from '../components/ProgressTable';
import { ModalRoutes } from '../navigation/routes';
import ModalRoute from '../navigation/ModalRoute';
import QuizNotCompleted from '../components/QuizNotCompleted';

interface Props {
  courseId: string;
}

const CoursePage: React.FC<Props> = ({ courseId }) => (
  <>
    <Box
      display="grid"
      overflow="auto"
      maxHeight={['initial', 'initial', '100vh']}
      sx={{ gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'] }}
    >
      <WeekList courseId={courseId} />
      <ChartCarousel courseId={courseId} />
    </Box>
    <ModalRoute component={ProgressTable} path={ModalRoutes.MY_PROGRESS} />
    <ModalRoute
      component={QuizNotCompleted}
      path={ModalRoutes.QUIZ_NOT_COMPLETED}
    />
  </>
);

export default React.memo(CoursePage);
