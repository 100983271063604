import * as React from 'react';
import { Box } from 'rebass';
import * as R from 'ramda';
import CourseSummary from './CourseSummary';
import { Course } from '../models/Course';

interface Props {
  courses: Course[];
}

const CourseList: React.FC<Props> = ({ courses }) => {
  const renderCourse = React.useCallback<(course: Course) => JSX.Element>(
    (course) => <CourseSummary course={course} key={course.id} />,
    []
  );

  return (
    <Box
      display="grid"
      mr={[8, 10, 11]}
      pb={12}
      ml={[8, 10, 11]}
      sx={{
        gridTemplateColumns: [
          'repeat(auto-fill,minmax(20rem,1fr))',
          'repeat(auto-fill,minmax(20rem,1fr))',
          'repeat(auto-fill,minmax(20rem,1fr))',
          'repeat(auto-fill,minmax(23rem,1fr))',
        ],
        gridAutoFlow: 'row',
        gridAutoRows: '18.75rem',
        gridGap: 7,
      }}
    >
      {R.map(renderCourse)(courses)}
    </Box>
  );
};

export default React.memo(CourseList);
