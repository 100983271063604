import React from 'react';
import { theme } from '../../contexts/AppTheme';

const PlayerPlayIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 62 62" fill="none" {...props}>
    <circle cx={31} cy={31} r={31} fill={theme.colors.white} />
    <path d="M36 31l-7.5 4.33v-8.66L36 31z" fill={theme.colors.primary} />
  </svg>
);

export default React.memo(PlayerPlayIcon);
