import React from 'react';
import { SxStyleProp } from 'rebass';

export enum Links {
  CONDITIONS = 'https://www.fittracks.de/eignung',
  PRIVACY = 'https://www.fittracks.de/datenschutz',
  COURSE_PURCHASE = 'https://www.fittracks.de/',
}

export enum LogoLinks {
  BLACKROLL = 'https://www.blackroll.fit/',
  WELLSYSTEM = 'https://www.wellsystem-fittracks.de/',
  DEFAULT = 'https://www.fittracks.de/',
  KRANKENKASSEN = 'https://www.hoerdichfit.de/',
}

export const LINK_DEFAULT_STYLE: React.CSSProperties = {
  display: 'inherit',
  textDecoration: 'none',
};

export const LINK_DISABLED_STYLE: React.CSSProperties = {
  pointerEvents: 'none',
};

export const IMAGE_TEXT_REBASS_STYLE: SxStyleProp = {
  textShadow: 2,
};
