import React from 'react';
import { Box, BoxProps } from 'rebass';

interface Props extends Omit<BoxProps, 'css'> {}

const LinearBar: React.FC<Props> = ({ style, ...props }) => (
  <Box
    bg="white"
    height={3}
    style={{
      borderRadius: 'inherit',
      transform: 'translate(0%, -50%)',
      position: 'absolute',
      top: '50%',
      left: 0,
      ...style,
    }}
    {...props}
  />
);

export default React.memo(LinearBar);
