import React from 'react';
import { usePrevious, useUpdateEffect } from 'react-use';
import ReactPlayer from 'react-player';
import * as R from 'ramda';
import usePlayerEffects from './usePlayerEffects';
import { usePlayerContext } from '../contexts/PlayerContext';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useTrackSelectors from './useTrackSelectors';
import useCourseSelectors from './useCourseSelectors';
import usePlayerSelectors from './usePlayerSelectors';
import useUserSelectors from './useUserSelectors';
import useStopwatchIncrease from './useStopwatchIncrease';
import { CONTROLS_FADE_OUT_INTERVAL } from '../components/PlayerControls';

const useTrackPlayer = (courseId: string, weekId: number) => {
  const { intervalState, setIntervalState } = usePlayerEffects();

  const { playerState, setIsPlaying, setIsControlsVisible } =
    usePlayerContext();

  const { firebaseState, setCurrentTrack } = useFirebaseContext();

  const { getTrackAudio, getTrackVideo } = useTrackSelectors();
  const { getCourseCover } = useCourseSelectors();

  const { getIsPlaying, getIsAudioReady, getIsVideoReady, getIsImageReady } =
    usePlayerSelectors();

  const { getAuthUser, getCurrentTrackId, getUserWeekProgress } =
    useUserSelectors();

  const { startStopwatch, stopStopwatch, updateRemoteStopwatch } =
    useStopwatchIncrease();

  const authUser = React.useMemo(() => getAuthUser(firebaseState), [
    firebaseState,
    getAuthUser,
  ]);

  const userProgress = React.useMemo(
    () => getUserWeekProgress(firebaseState, courseId, weekId),
    [courseId, firebaseState, getUserWeekProgress, weekId]
  );

  const currentTrackId = React.useMemo(
    () => getCurrentTrackId(firebaseState, courseId, weekId),
    [courseId, firebaseState, getCurrentTrackId, weekId]
  );

  const prevCurrentTrackId = usePrevious(currentTrackId);

  const trackAudio = React.useMemo(
    () =>
      !R.isNil(currentTrackId)
        ? getTrackAudio(firebaseState, currentTrackId)
        : null,
    [currentTrackId, firebaseState, getTrackAudio]
  );

  const trackVideo = React.useMemo(
    () =>
      !R.isNil(currentTrackId)
        ? getTrackVideo(firebaseState, currentTrackId)
        : null,
    [currentTrackId, firebaseState, getTrackVideo]
  );

  const courseCover = React.useMemo(
    () => getCourseCover(firebaseState, courseId),
    [courseId, firebaseState, getCourseCover]
  );

  const trackImage = React.useMemo(() => {
    if (!R.isNil(trackVideo))
      return !ReactPlayer.canPlay(trackVideo) ? trackVideo : null;
    return courseCover;
  }, [courseCover, trackVideo]);

  const isPlaying = React.useMemo(() => getIsPlaying(playerState), [
    getIsPlaying,
    playerState,
  ]);

  const isAudioReady = React.useMemo(() => getIsAudioReady(playerState), [
    getIsAudioReady,
    playerState,
  ]);

  const isVideoReady = React.useMemo(() => getIsVideoReady(playerState), [
    getIsVideoReady,
    playerState,
  ]);

  const isImageReady = React.useMemo(() => getIsImageReady(playerState), [
    getIsImageReady,
    playerState,
  ]);

  const isTrackReady = React.useMemo(
    () => isAudioReady && (isVideoReady || isImageReady),
    [isAudioReady, isImageReady, isVideoReady]
  );

  useUpdateEffect(() => {
    if (isAudioReady && isPlaying) startStopwatch(courseId, weekId);
    else if (!isAudioReady && (!isVideoReady || !isImageReady)) stopStopwatch();
  }, [courseId, isAudioReady, isImageReady, isVideoReady, isPlaying, weekId]);

  // it prevents currentTrackId to become null when currentTrack is not exist on firebase state (as user progress)
  React.useEffect(() => {
    if (
      !R.isNil(currentTrackId) &&
      !R.equals(currentTrackId, prevCurrentTrackId)
    )
      setCurrentTrack(courseId, weekId, currentTrackId);
  }, [courseId, currentTrackId, prevCurrentTrackId, setCurrentTrack, weekId]);

  useUpdateEffect(() => {
    if (
      !R.isNil(authUser) &&
      !R.isNil(userProgress) &&
      !R.isNil(currentTrackId)
    )
      updateRemoteStopwatch(authUser, courseId, weekId, userProgress);
  }, [currentTrackId]);

  const handlePlayerEnter = React.useCallback(() => {
    clearInterval(intervalState);
    setIsControlsVisible(true);
  }, [intervalState, setIsControlsVisible]);

  const handlePlayerLeave = React.useCallback(() => {
    if (!isPlaying) {
      clearInterval(intervalState);
      return;
    }

    const interval = window.setInterval(
      () => setIsControlsVisible(false),
      CONTROLS_FADE_OUT_INTERVAL,
    );

    setIntervalState(interval);
  }, [intervalState, isPlaying, setIntervalState, setIsControlsVisible]);

  const handlePlayClick = React.useCallback(() => {
    setIsPlaying(true);
    setIsControlsVisible(false);
    stopStopwatch();
    startStopwatch(courseId, weekId);
  }, [
    courseId,
    setIsControlsVisible,
    setIsPlaying,
    startStopwatch,
    stopStopwatch,
    weekId,
  ]);

  const handlePauseClick = React.useCallback(() => {
    setIsPlaying(false);
    handlePlayerEnter();
    stopStopwatch();
    if (!R.isNil(authUser) && !R.isNil(userProgress))
      updateRemoteStopwatch(authUser, courseId, weekId, userProgress);
  }, [
    authUser,
    courseId,
    handlePlayerEnter,
    setIsPlaying,
    stopStopwatch,
    updateRemoteStopwatch,
    userProgress,
    weekId,
  ]);

  return {
    trackAudio,
    trackVideo,
    trackImage,
    isAudioReady,
    isVideoReady,
    isImageReady,
    isPlaying,
    isTrackReady,
    handlePlayerEnter,
    handlePlayerLeave,
    handlePlayClick,
    handlePauseClick,
  };
};

export default useTrackPlayer;
