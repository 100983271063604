import React from 'react';
import { Box, BoxProps } from 'rebass';
import * as R from 'ramda';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useCourseSelectors from '../hooks/useCourseSelectors';
import { theme } from '../contexts/AppTheme';

export const COURSE_IMAGE_RESIZE_DURATION_MS = 660;

interface Props extends Omit<BoxProps, 'css'> {
  courseId: string;
}

const CourseImage: React.FC<Props> = ({
  children,
  courseId,
  style,
  ...props
}) => {
  const { firebaseState } = useFirebaseContext();
  const { getCourseCover } = useCourseSelectors();

  const courseCover = React.useMemo(
    () => getCourseCover(firebaseState, courseId),
    [courseId, firebaseState, getCourseCover]
  );

  return (
    <Box
      display="flex"
      bg={theme.colors.gray}
      p={8}
      style={R.mergeRight(
        {
          alignItems: 'flex-end',
          flexGrow: 1,
          backgroundImage: `${theme.colors.blackTopBottomGradient}, url(${courseCover})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transitionProperty: 'height',
          transitionDuration: `${R.divide(COURSE_IMAGE_RESIZE_DURATION_MS)(
            1000
          )}s`,
          transitionTimingFunction: 'ease',
        },
        R.defaultTo({})(style)
      )}
      {...props}
    >
      {children}
    </Box>
  );
};

export default CourseImage;
