import React from 'react';
import { BoxProps, Link, Text } from 'rebass';
import strings from '../theme/strings';

interface Props extends Omit<BoxProps, 'css'> {}

const SupportContact: React.FC<Props> = (props) => {
  const mail = React.useMemo(() => strings.coursePage.supportContact.mail, []);

  return (
    <Text color="grayMedium" fontSize={1} lineHeight={0} {...props}>
      {strings.coursePage.supportContact.root}
      <Link href={`mailto:${mail}`} target="_top" color="primary">
        {mail}
      </Link>
    </Text>
  );
};

export default React.memo(SupportContact);
