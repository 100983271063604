import React from 'react';
import * as R from 'ramda';
import { FitnessTests } from '../models/FitnessTests';
import { FirebaseState } from '../store/reducers/firebaseReducer';
import useQuestionSelectors from './useQuestionSelectors';
import useUserSelectors from './useUserSelectors';
import { AnswerTypes } from '../models/DetailedCourse';
import { WeekProgressData } from '../models/WeekProgressData';
import { useFirebaseContext } from '../contexts/FirebaseContext';

const useProgressTableSelectors = () => {
  const { firebaseState } = useFirebaseContext();
  const { getIsWeekFinished } = useUserSelectors();
  const { getCourseQuestions, getQuestionAnswer } = useQuestionSelectors();
  const { getUserAnswers, getIsQuizCompleted } = useUserSelectors();

  const getFitnessTests = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
    ) => FitnessTests | null
  >(
    (state, courseId, weekId) => {
      const questions = getCourseQuestions(state, courseId);
      const userAnswers = getUserAnswers(state);

      return !R.isNil(userAnswers)
        ? R.reduce(
            (acc, question) => {
              const questionAnswer = getQuestionAnswer(
                weekId,
                userAnswers,
                question,
                AnswerTypes.after,
              );
              return R.mergeRight(
                acc,
                questionAnswer
                  ? {
                      [R.prop('id')(question)]: questionAnswer,
                    }
                  : {},
              );
            },
            {},
            questions,
          )
        : null;
    },
    [getCourseQuestions, getQuestionAnswer, getUserAnswers],
  );

  const getWeekProgressData = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => WeekProgressData
  >(
    (state, courseId, weekId) => {
      const isWeekFinished = getIsWeekFinished(firebaseState, courseId, weekId);
      const isQuizCompleted = getIsQuizCompleted(state, courseId, weekId);
      const fitnessTests = getFitnessTests(state, courseId, weekId);
      return {
        weekId,
        isWeekFinished,
        isQuizCompleted,
        ...fitnessTests,
      };
    },
    [firebaseState, getIsWeekFinished, getFitnessTests, getIsQuizCompleted],
  );

  return {
    getWeekProgressData,
  };
};

export default useProgressTableSelectors;
