import React from 'react';
import { Button, Text } from 'rebass';
import strings from '../../theme/strings';
import useEndWorkout from '../../hooks/useEndWorkout';

interface Props {
  courseId: string;
  weekId?: number;
}

const EndWorkoutButton: React.FC<Props> = ({ courseId, weekId }) => {
  const { handleEndWorkoutClick } = useEndWorkout(courseId, weekId);

  return (
    <Button onClick={handleEndWorkoutClick} variant="square" p={8}>
      <Text color="white" fontSize={1} lineHeight={0}>
        {strings.weekPage.endWorkoutButton}
      </Text>
    </Button>
  );
};

export default React.memo(EndWorkoutButton);
