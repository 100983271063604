import React from 'react';
import { Box, Button, Flex, Link, SxStyleProp, Text } from 'rebass';
import * as R from 'ramda';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserSelectors from '../hooks/useUserSelectors';
import { LINK_DEFAULT_STYLE, Links } from '../theme/constants';
import strings from '../theme/strings';
import authService from '../services/AuthService';
import { theme } from '../contexts/AppTheme';

const ACCOUNT_DROPDOWN_LINK_TEXT_REBASS_STYLE: SxStyleProp = {
  borderColor: `${theme.colors.gray} !important`,
  borderTop: 0,
  fontSize: 1,
  lineHeight: 0,
  padding: 3,
  transition: 'background-color .2s ease-in-out',
  ':hover': {
    bg: 'grayLight',
  },
};

const AccountDropdown: React.FC = () => {
  const { firebaseState } = useFirebaseContext();
  const { getAuthUser } = useUserSelectors();

  const authUser = React.useMemo(() => getAuthUser(firebaseState), [
    firebaseState,
    getAuthUser,
  ]);

  const handleLogoutClick = React.useCallback(() => {
    authService.auth.signOut();
    // window.location.reload();
  }, []);

  return !R.isNil(authUser) ? (
    <Box
      style={{
        position: 'absolute',
        left: '100%',
        top: '100%',
        transform: 'translateX(-100%)',
      }}
    >
      <Box
        bg="white"
        overflow="hidden"
        sx={{
          borderColor: 'gray',
          borderWidth: 0,
          borderStyle: 0,
          borderRadius: 3,
        }}
      >
        <Box minWidth={8}>
          <Text fontSize={3} lineHeight={2} pt={7} px={3} pb={3}>
            {R.prop('email')(authUser)}
          </Text>
          <Link
            href={Links.CONDITIONS}
            target="_blank"
            color="primary"
            style={LINK_DEFAULT_STYLE}
          >
            <Text sx={ACCOUNT_DROPDOWN_LINK_TEXT_REBASS_STYLE}>
              {strings.other.conditions}
            </Text>
          </Link>
          <Link
            href={Links.PRIVACY}
            target="_blank"
            color="primary"
            style={LINK_DEFAULT_STYLE}
          >
            <Text sx={ACCOUNT_DROPDOWN_LINK_TEXT_REBASS_STYLE}>
              {strings.other.privacyPolicy}
            </Text>
          </Link>
          <Flex flexDirection="column">
            <Button
              variant="square"
              onClick={handleLogoutClick}
              style={{ borderRadius: 'inherit' }}
            >
              <Text color="white" fontSize={1} lineHeight={0}>
                {strings.header.signOutButton}
              </Text>
            </Button>
          </Flex>
        </Box>
      </Box>
      <Box
        overflow="hidden"
        width="20px"
        height="10px"
        mx={4}
        sx={{
          position: 'absolute',
          top: -2,
          left: '62%',
        }}
      >
        <Box
          bg="white"
          content=""
          width="14.14427px"
          height="14.14427px"
          sx={{
            borderRadius: '2px',
            borderColor: 'gray',
            borderWidth: 0,
            borderStyle: 0,
          }}
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'rotate(45deg)',
            transformOrigin: 'top left',
          }}
        />
      </Box>
    </Box>
  ) : null;
};

export default React.memo(AccountDropdown);
