import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Box } from 'rebass';
import * as R from 'ramda';
import Header from '../components/Header';
import CourseImage from '../components/CourseImage';
import CoursePage from '../pages/CoursePage';
import TrackList from '../components/TrackList';
import { Routes } from './routes';
import useCourseNavigatorEffects from '../hooks/useCourseNavigatorEffects';
import CourseImageContent from '../components/CourseImageContent';
import WeekPage from '../pages/WeekPage';
import ProtectedWeekRoute from './ProtectedWeekRoute';

interface RouteParams {
  courseId: string;
}

export interface WeekMatchParams extends RouteParams {
  weekId: string;
}

const CourseNavigator: React.FC = () => {
  const { courseId } = useParams<RouteParams>();
  const weekMatch = useRouteMatch<WeekMatchParams>(Routes.WEEK_PAGE);

  const weekId = React.useMemo(
    () =>
      !R.isNil(weekMatch) ? parseInt(weekMatch.params.weekId, 10) : undefined,
    [weekMatch]
  );

  useCourseNavigatorEffects(courseId, weekId);

  const renderLeftSide = React.useCallback(
    () => (
      <Box
        display="flex"
        maxHeight="100vh"
        overflow="hidden"
        order={weekId && [2, 2, 1]}
        style={{ flexDirection: 'column' }}
      >
        <Box>
          <CourseImage
            display={weekId ? ['none', 'none', 'flex'] : 'flex'}
            // fix image shifting on week page
            height={weekId ? 8 : ['100%', '100%', '100vh']}
            courseId={courseId}
          >
            <CourseImageContent courseId={courseId} weekId={weekId} />
          </CourseImage>
        </Box>
        <TrackList courseId={courseId} weekId={weekId} />
      </Box>
    ),
    [courseId, weekId]
  );

  const renderRightSide = React.useCallback(
    () => (
      <>
        {!weekId && <CoursePage courseId={courseId} />}
        <ProtectedWeekRoute path={Routes.WEEK_PAGE} component={WeekPage} />
      </>
    ),
    [courseId, weekId]
  );

  return (
    <>
      <Header isBack />
      <Box
        display={['flex', 'flex', 'grid']}
        maxHeight={weekId ? ['100vh', '100vh', 'initial'] : 'initial'}
        sx={{
          gridTemplateColumns: ['100%', '100%', '55% 45%', '35% 65%'],
          gridAutoFlow: 'row',
          flexDirection: 'column',
        }}
      >
        {renderLeftSide()}
        {renderRightSide()}
      </Box>
    </>
  );
};

export default CourseNavigator;
