import React from 'react';
import { Box, Text } from 'rebass';
import * as R from 'ramda';
import FTInput from './FTInput';
import { DEFAULT_SLIDE_REBASS_STYLE } from './QuestionTest';
import { Question } from '../models/Question';

interface Props {
  question: Question;
  inputValue: string;
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const QuestionSummary: React.FC<Props> = ({
  question,
  inputValue,
  handleInputChange,
}) => (
  <Box sx={DEFAULT_SLIDE_REBASS_STYLE}>
    <Text fontSize={3} lineHeight={1}>
      {R.prop('question')(question)}
    </Text>
    <Box mt={6} p={3}>
      <FTInput
        type="text"
        pattern="[0-9]*"
        value={inputValue}
        onChange={handleInputChange}
      />
    </Box>
  </Box>
);

export default React.memo(QuestionSummary);
