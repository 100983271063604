import React from 'react';
import * as R from 'ramda';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import { usePlayerContext } from '../contexts/PlayerContext';
import useRemoteSetters from './useRemoteSetters';
import { AuthUser } from '../models/AuthUser';
import { WeekProgress } from '../models/UserData';

const DELAY_BETWEEN_UPDATES_MS = 1000;

const useStopwatchIncrease = () => {
  const { incrementTimeSpent } = useFirebaseContext();
  const {
    incrementPlayerTimeSpent,
    stopwatchUpdateInterval,
    setStopwatchUpdateInterval,
  } = usePlayerContext();

  const { updateUserProgressRemote } = useRemoteSetters();

  const updateRemoteStopwatch = React.useCallback<
    (
      authUser: AuthUser,
      courseId: string,
      weekId: number,
      userProgress: WeekProgress,
    ) => void
  >(
    (authUser, courseId, weekId, userProgress) => {
      if (!R.isNil(authUser) && !R.isNil(userProgress))
        updateUserProgressRemote(authUser, courseId, weekId, userProgress);
    },
    [updateUserProgressRemote],
  );

  const stopStopwatch = React.useCallback(() => {
    clearInterval(stopwatchUpdateInterval);
  }, [stopwatchUpdateInterval]);

  const increaseStopwatch = React.useCallback<
    (courseId: string, weekId: number) => void
  >(
    (courseId, weekId) => {
      incrementTimeSpent(courseId, weekId, DELAY_BETWEEN_UPDATES_MS);
      incrementPlayerTimeSpent(DELAY_BETWEEN_UPDATES_MS);
    },
    [incrementPlayerTimeSpent, incrementTimeSpent],
  );

  const startStopwatch = React.useCallback<
    (courseId: string, weekId: number) => void
  >(
    (courseId, weekId) => {
      const interval = window.setInterval(
        () => increaseStopwatch(courseId, weekId),
        DELAY_BETWEEN_UPDATES_MS,
      );

      setStopwatchUpdateInterval(interval);
    },
    [increaseStopwatch, setStopwatchUpdateInterval],
  );

  return {
    startStopwatch,
    stopStopwatch,
    updateRemoteStopwatch,
  };
};

export default useStopwatchIncrease;
