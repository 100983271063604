import * as R from 'ramda';
import firebase from 'firebase/compat';
import app from '../firebaseConfig';
import { Course } from '../models/Course';
import { CourseCovers } from '../models/CourseCovers';
import { TrackPreviews } from '../models/TrackPreviews';
import { TrackData, TrackMedia, TrackMedias } from '../models/TrackData';
import { TrackAudios } from '../models/TrackAudios';
import { TrackVideos } from '../models/TrackVideos';
import { CourseDocuments } from '../models/CourseDocuments';

class StorageService {
  private readonly storage: firebase.storage.Storage;

  private readonly localStorage: Storage;

  constructor() {
    this.storage = firebase.storage(app);
    this.localStorage = window.localStorage;
  }

  async getCoursesCovers(coursesList: Course[]): Promise<CourseCovers> {
    const requests = R.fromPairs(
      R.map(
        (course) => [
          course.id,
          this.storage.ref(course.cover.path).getDownloadURL(),
        ],
        coursesList,
      ),
    );

    const results = await Promise.all(
      R.values(requests).map((p) =>
        Promise.resolve(p).then(R.identity, R.empty),
      ),
    );

    return R.zipObj(Object.keys(requests), R.reject(R.isEmpty, results));
  }

  async getCourseDocuments(trackList: TrackData[]): Promise<CourseDocuments> {
    const requests = R.fromPairs(
      R.map(
        (track) => [
          track.week,
          this.storage.ref(track.document.path).getDownloadURL(),
        ],
        R.filter<TrackData>((track) => track.type === 'document', trackList),
      ),
    );

    return R.zipObj(
      Object.keys(requests),
      await Promise.all(R.values(requests)),
    );
  }

  async getTracksMedia(
    trackList: TrackData[],
    trackMedia: TrackMedia,
  ): Promise<TrackPreviews | TrackAudios | TrackVideos> {
    const requests = R.fromPairs(
      R.map(
        (track) => [
          track.id,
          this.storage.ref(track[trackMedia].path).getDownloadURL(),
        ],
        R.filter<TrackData>(
          (track) =>
            trackMedia === TrackMedias.PREVIEW
              ? !R.isNil(track[trackMedia])
              : !R.isNil(track[trackMedia].path) ||
              !R.isEmpty(track[trackMedia]),
          trackList,
        ),
      ),
    );

    const results = await Promise.all(
      R.values(requests).map((p) =>
        Promise.resolve(p).then(R.identity, R.empty),
      ),
    );

    return R.zipObj(Object.keys(requests), R.reject(R.isEmpty, results));
  }

  setEmailForSignIn(email: string) {
    this.localStorage.setItem('emailForSignIn', email);
  }

  getEmailForSignIn() {
    return this.localStorage.getItem('emailForSignIn');
  }

  removeEmailForSignIn() {
    this.localStorage.removeItem('emailForSignIn');
  }
}

const storageService = new StorageService();

export default storageService;
