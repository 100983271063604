import * as R from 'ramda';
import { appType } from './env';

export enum AppType {
  DEFAULT = 'DEFAULT',
  BLACKROLL = 'BLACKROLL',
  KRANKENKASSEN = 'KRANKENKASSEN',
  WELLSYSTEM = 'WELLSYSTEM',
}

export const AppSelect = <T>(
  specifics: { [app in AppType]?: T } & { default?: T },
): T => R.propOr(specifics.default, appType, specifics);
