import React, { memo, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import FTInput, { FTInputProps } from './FTInput';
import VisibilityOffIcon from './icons/VisibilityOffIcon';
import VisibilityIcon from './icons/VisibilityIcon';
import strings from '../theme/strings';

interface Props extends FTInputProps {
  visible?: boolean;
  onVisibilityClick?: () => void;
}

const PasswordInput: React.FC<Props> = ({
  value,
  visible,
  onVisibilityClick,
  ...props
}) => {
  const PasswordVisibilityIcon = useMemo(() => {
    if (!value) {
      return null;
    }

    return (
      <IconButton
        size="small"
        style={{
          position: 'absolute',
          right: 12,
          top: '50%',
          transform: 'translateY(-50%)',
        }}
        onClick={onVisibilityClick}
      >
        {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    );
  }, [onVisibilityClick, value, visible]);

  return (
    <FTInput
      name="password"
      type={visible ? 'text' : 'password'}
      labelText={strings.signInPage.passwordInputLabel}
      rightIcon={PasswordVisibilityIcon}
      value={value}
      {...props}
    />
  );
};

export default memo(PasswordInput);
