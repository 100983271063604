import React, { useMemo } from 'react';
import { Input, InputProps, Label } from '@rebass/forms';
import { Box } from 'rebass';

export interface FTInputProps extends Omit<InputProps, 'css'> {
  labelText?: string;
  rightIcon?: JSX.Element | null;
}

const FTInput: React.FC<FTInputProps> = ({
  name,
  labelText,
  rightIcon: RightIcon,
  ...props
}) => {
  const LabelText = useMemo(
    () =>
      labelText ? (
        <Label htmlFor={name} color="grayMedium" fontSize={0} mb={2}>
          {labelText}
        </Label>
      ) : null,
    [labelText, name]
  );

  return (
    <>
      {LabelText}
      <Box style={{ position: 'relative' }}>
        <Input
          id={name}
          name={name}
          required
          backgroundColor="grayLight"
          color="black"
          fontSize="16px"
          p={5}
          paddingRight={10}
          sx={{
            borderColor: 'gray',
            lineHeight: 1,
            borderStyle: 0,
            borderWidth: 0,
            borderRadius: 2,
            ':focus': {
              outline: 'unset',
              borderColor: 'grayDark',
            },
          }}
          {...props}
        />
        {RightIcon}
      </Box>
    </>
  );
};

export default React.memo(FTInput);
