import React, { useEffect } from 'react';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import authService from '../services/AuthService';

const useUserDataLoader = () => {
  const { loadUserData } = useFirebaseContext();

  // we need to update the token before loading the data, because for the first login it returns permission error.
  useEffect(() => {
    const { currentUser } = authService.auth;
    if (currentUser) currentUser.getIdToken(true);
  }, [loadUserData]);

  React.useEffect(() => {
    loadUserData();
  }, [loadUserData]);
};

export default useUserDataLoader;
