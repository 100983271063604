export enum FirebaseActions {
  RESET_STATE = 'RESET_STATE',
  LOAD_COURSES = 'LOAD_COURSES',
  LOAD_COURSES_COVERS = 'LOAD_COURSES_COVERS',
  LOAD_TRACKS = 'LOAD_TRACKS',
  LOAD_COURSE_DOCUMENTS = 'LOAD_COURSE_DOCUMENTS',
  LOAD_TRACKS_PREVIEWS = 'LOAD_TRACKS_PREVIEWS',
  LOAD_TRACKS_AUDIOS = 'LOAD_TRACKS_AUDIOS',
  LOAD_TRACKS_VIDEOS = 'LOAD_TRACKS_VIDEOS',
  LOAD_QUESTIONS = 'LOAD_QUESTIONS',
  LOAD_QUIZ_QUESTIONS = 'LOAD_QUIZ_QUESTIONS',
  LOAD_QUIZ_ANSWERS = 'LOAD_QUIZ_ANSWERS',
  LOAD_AUTH_USER = 'LOAD_AUTH_USER',
  LOAD_USER_DATA = 'LOAD_USER_DATA',
  SET_CURRENT_TRACK = 'SET_CURRENT_TRACK',
  INCREASE_TIME_SPENT = 'INCREASE_TIME_SPENT',
  SET_TIME_STARTED = 'SET_TIME_STARTED',
  SET_TIME_FINISHED = 'SET_TIME_FINISHED',
  SET_FINISHED_WORKOUT = 'SET_FINISHED_WORKOUT',
  SET_FINISHED_QUIZ_QUESTION = 'SET_FINISHED_QUIZ_QUESTION',
  SET_USER_ANSWER = 'SET_USER_ANSWER',
  SET_IS_TRACK_FINISHED = 'SET_IS_TRACK_FINISHED',
  SEND_SIGN_IN_LINK_SUCCESS = 'SEND_SIGN_IN_LINK_SUCCESS',
  HANDLE_SEND_SIGN_IN_LINK_ERROR = 'HANDLE_SEND_SIGN_IN_LINK_ERROR',
  HANDLE_SIGN_IN_LINK_SUCCESS = 'HANDLE_SIGN_IN_LINK_SUCCESS',
  HANDLE_SIGN_IN_LINK_ERROR = 'HANDLE_SIGN_IN_LINK_ERROR',
  HANDLE_FIRESTORE_ERROR = 'HANDLE_FIRESTORE_ERROR',
  IS_AUTH_LOADING = 'IS_AUTH_LOADING',
  IS_SUBMIT_LOADING = 'IS_SUBMIT_LOADING',
  IS_FIRESTORE_LOADING = 'IS_FIRESTORE_LOADING',
}

export enum PlayerActions {
  SET_IS_PLAYING = 'SET_IS_PLAYING',
  SET_IS_CONTROLS_VISIBLE = 'SET_IS_CONTROLS_VISIBLE',
  SET_IS_AUDIO_READY = 'SET_IS_AUDIO_READY',
  SET_IS_VIDEO_READY = 'SET_IS_VIDEO_READY',
  SET_IS_IMAGE_READY = 'SET_IS_IMAGE_READY',
  SET_PLAYER_PROGRESS = 'SET_PLAYER_PROGRESS',
  INCREMENT_PLAYER_TIME_SPENT = 'INCREMENT_PLAYER_TIME_SPENT',
  CLEAR_PLAYER = 'CLEAR_PLAYER',
  CLEAR_TIME_SPENT = 'CLEAR_TIME_SPENT',
}
