import * as React from 'react';
import { useMemo } from 'react';
import { Box } from 'rebass';
import { Link } from 'react-router-dom';
import SignInForm from '../components/SignInForm';
import { LINK_DEFAULT_STYLE } from '../theme/constants';
import { Routes } from '../navigation/routes';
import { theme } from '../contexts/AppTheme';
import useSignInForm from '../hooks/useSignInForm';
import { AppType } from '../appSelect';
import { appType } from '../env';
import DefaultLogoIcon from '../components/icons/DefaultLogoIcon';
import HomeButton, { HomeButtonType } from '../components/buttons/HomeButton';

const SignInPage: React.FC = () => {
  const formProps = useSignInForm();

  const LogoIcon = useMemo(() => {
    switch (appType) {
      case AppType.BLACKROLL:
      case AppType.WELLSYSTEM:
        return <HomeButton type={HomeButtonType.FORM} />;
      default:
        return <DefaultLogoIcon width="14rem" fill={theme.colors.white} />;
    }
  }, []);

  return (
    <Box
      display="flex"
      bg="primary"
      minHeight="100vh"
      p={6}
      style={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <Box pt={8} pb={10} sx={{ borderRadius: 4 }}>
        <Box pb={9} style={{ textAlign: 'center' }}>
          <Link to={Routes.HOME} style={LINK_DEFAULT_STYLE}>
            {LogoIcon}
          </Link>
        </Box>
        <Box
          bg="white"
          p={9}
          style={{ border: 'none', borderRadius: 'inherit' }}
        >
          <SignInForm {...formProps} />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(SignInPage);
