import React from 'react';
import { Box, SxStyleProp, Text } from 'rebass';
import * as R from 'ramda';
import WeekBottom from './WeekBottom';
import strings from '../theme/strings';
import { formatNumber } from '../utils';
import DetailedWeek, { WeekTypes } from '../models/DetailedWeek';
import PlayIcon from './icons/PlayIcon';
import CompletedIcon from './icons/CircleTickIcon';

const WEEK_DEFAULT_REBASS_STYLE: SxStyleProp = {
  borderTop: 0,
  borderColor: 'gray',
  color: 'black',
};
const WEEK_CURRENT_REBASS_STYLE: SxStyleProp = {
  backgroundColor: 'primary',
  color: 'white',
};
const WEEK_OTHER_REBASS_STYLE: SxStyleProp = {
  color: 'blackLightTransparent',
};

const ICONS_WIDTH = '2rem';

interface Props {
  week: DetailedWeek;
  isWeekOpen: boolean;
}

const WeekSummaryContent: React.FC<Props> = ({ week, isWeekOpen }) => {
  const weekRebassStyle = React.useMemo(() => {
    switch (R.prop('type')(week)) {
      case WeekTypes.CURRENT:
        return R.mergeRight(
          WEEK_DEFAULT_REBASS_STYLE,
          WEEK_CURRENT_REBASS_STYLE
        );
      case WeekTypes.OTHER:
        return R.mergeRight(
          WEEK_DEFAULT_REBASS_STYLE,
          !isWeekOpen ? WEEK_OTHER_REBASS_STYLE : {}
        );
      case WeekTypes.COMPLETED:
      default:
        return WEEK_DEFAULT_REBASS_STYLE;
    }
  }, [isWeekOpen, week]);

  const renderWeekName = React.useCallback(
    () => (
      <Text fontWeight="bold" fontSize={4} lineHeight={3}>
        {strings.coursePage.getWeekName(formatNumber(R.prop('weekId')(week)))}
      </Text>
    ),
    [week]
  );

  const renderWeekIcon = React.useCallback(() => {
    switch (R.prop('type')(week)) {
      case WeekTypes.CURRENT:
        return <PlayIcon display="block" width={ICONS_WIDTH} />;
      case WeekTypes.COMPLETED:
        return <CompletedIcon display="block" width={ICONS_WIDTH} />;
      case WeekTypes.OTHER:
      default:
        return null;
    }
  }, [week]);

  return (
    <Box
      key={R.prop('weekId')(week)}
      display="flex"
      pt={5}
      pr={9}
      pb={5}
      pl={9}
      sx={weekRebassStyle}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box flex={1} pr={7}>
        {renderWeekName()}
        <WeekBottom week={week} />
      </Box>
      <Box>{renderWeekIcon()}</Box>
    </Box>
  );
};

export default React.memo(WeekSummaryContent);
