import React from 'react';
import { Box, Button, Text } from 'rebass';
import * as R from 'ramda';
import { CircularProgress } from '@material-ui/core';
import PlayerPauseIcon from './icons/PlayerPauseIcon';
import PlayerPlayIcon from './icons/PlayerPlayIcon';
import { formatDuration } from '../utils';
import usePlayerSelectors from '../hooks/usePlayerSelectors';
import { usePlayerContext } from '../contexts/PlayerContext';
import { theme } from '../contexts/AppTheme';

const CONTROLS_DEFAULT_STYLE: React.CSSProperties = {
  transition: 'opacity ease-out .3s',
};

export const CONTROLS_FADE_OUT_INTERVAL = 4000;
const CONTROLS_ICON_WIDTH = '3.75rem';

const PlayerControls: React.FC = () => {
  const {
    getIsPlaying,
    getIsControlsVisible,
    getIsAudioReady,
    getIsVideoReady,
    getIsImageReady,
    getPlayerTimeSpent,
  } = usePlayerSelectors();

  const { playerState } = usePlayerContext();

  const isPlaying = React.useMemo(() => getIsPlaying(playerState), [
    getIsPlaying,
    playerState,
  ]);

  const isControlsVisible = React.useMemo(
    () => getIsControlsVisible(playerState),
    [getIsControlsVisible, playerState]
  );

  const isAudioReady = React.useMemo(() => getIsAudioReady(playerState), [
    getIsAudioReady,
    playerState,
  ]);

  const isVideoReady = React.useMemo(() => getIsVideoReady(playerState), [
    getIsVideoReady,
    playerState,
  ]);

  const isImageReady = React.useMemo(() => getIsImageReady(playerState), [
    getIsImageReady,
    playerState,
  ]);

  const isControlsAvailable = React.useMemo(
    () => isAudioReady && (isVideoReady || isImageReady),
    [isAudioReady, isImageReady, isVideoReady]
  );

  const controlsOpacity = React.useMemo(
    () => (isControlsVisible ? 1 : [1, 1, 0]),
    [isControlsVisible]
  );

  const playerTimeSpent = React.useMemo(() => getPlayerTimeSpent(playerState), [
    getPlayerTimeSpent,
    playerState,
  ]);

  return (
    <Box opacity={controlsOpacity} style={CONTROLS_DEFAULT_STYLE}>
      <Button
        variant="unstyled"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}
      >
        {isControlsAvailable ? (
          isPlaying ? (
            <PlayerPauseIcon width={CONTROLS_ICON_WIDTH} />
          ) : (
            <PlayerPlayIcon width={CONTROLS_ICON_WIDTH} />
          )
        ) : (
          <CircularProgress
            disableShrink
            style={{ color: theme.colors.white }}
          />
        )}
      </Button>
      <Box
        display="flex"
        p={5}
        style={{
          alignItems: 'flex-end',
          background: theme.colors.blackBottomGradient,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <Text color="white" fontSize={5} lineHeight={4}>
          {!R.isNil(playerTimeSpent) &&
            formatDuration(R.divide(playerTimeSpent)(1000))}
        </Text>
      </Box>
    </Box>
  );
};

export default React.memo(PlayerControls);
