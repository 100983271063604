import React from 'react';
import { Box, Button, Text, Link } from 'rebass';
import { useHistory } from 'react-router-dom';
import strings from '../theme/strings';
import { LINK_DEFAULT_STYLE, Links } from '../theme/constants';
import { Routes } from '../navigation/routes';

const CourseUnavailable: React.FC = () => {
  const history = useHistory();

  const onCancelClick = React.useCallback(() => history.push(Routes.HOME), [
    history,
  ]);

  return (
    <Box
      display="flex"
      bg="white"
      p={9}
      sx={{ flexDirection: 'column', borderRadius: 3 }}
    >
      <Text textAlign="center" maxWidth={7}>
        <Text fontWeight="bold" fontSize={3} lineHeight={2} mb={8}>
          {strings.modals.courseNotBought.heading}
        </Text>
        <Text fontSize={1} lineHeight={0} mb={8}>
          {strings.modals.courseNotBought.details}
        </Text>
      </Text>
      <Box display="inline-grid" mb={5}>
        <Link
          href={Links.COURSE_PURCHASE}
          target="_blank"
          style={LINK_DEFAULT_STYLE}
        >
          <Button variant="formBlueButton">
            {strings.modals.courseNotBought.homePageLink}
          </Button>
        </Link>
      </Box>
      <Button variant="formGrayButton" onClick={onCancelClick}>
        {strings.other.cancelButton}
      </Button>
    </Box>
  );
};

export default React.memo(CourseUnavailable);
