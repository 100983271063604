import React from 'react';
import { Box, SxStyleProp, Text } from 'rebass';
import * as R from 'ramda';
import DetailedWeek, { WeekTypes } from '../models/DetailedWeek';
import ProgressBar from './ProgressBar';
import LinearBar from './LinearBar';
import strings from '../theme/strings';
import { addColon, ceilDuration, getHumanDuration } from '../utils';

const WEEK_TIME_SPENT_REBASS_STYLE: SxStyleProp = {
  fontSize: 0,
  lineHeight: 0,
  marginTop: 1,
};

const WEEK_TIME_SPENT_DEFAULT = 0;

interface Props {
  week: DetailedWeek;
}

const WeekBottom: React.FC<Props> = ({ week }) => {
  switch (R.prop('type')(week)) {
    case WeekTypes.CURRENT:
      return (
        <Box mt={1}>
          <ProgressBar sectionsNum={1} progressPercent={0} height="0.125rem">
            <LinearBar width={`${R.prop('progress')(week)}%`} />
          </ProgressBar>
          <Text color="white" sx={WEEK_TIME_SPENT_REBASS_STYLE}>
            {strings.coursePage.workouts.currentTrack}
          </Text>
        </Box>
      );
    case WeekTypes.COMPLETED:
      return (
        <Text
          sx={{
            color: 'grayDark',
            ...WEEK_TIME_SPENT_REBASS_STYLE,
          }}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {addColon(strings.coursePage.workouts.timeSpent)}
          <Text as="span" color="primary">
            {getHumanDuration(
              ceilDuration(
                R.defaultTo(WEEK_TIME_SPENT_DEFAULT)(R.prop('timeSpent')(week))
              )
            )}
          </Text>
        </Text>
      );
    case WeekTypes.OTHER:
    default:
      return null;
  }
};

export default React.memo(WeekBottom);
