import React from 'react';
import { theme } from '../../contexts/AppTheme';

const PrevChartIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <circle
      cx={16}
      cy={16}
      r={15}
      stroke={theme.colors.primary}
      strokeWidth={2}
    />
    <path
      d="M11.646 16.354a.5.5 0 010-.708l3.182-3.182a.5.5 0 11.708.708L12.707 16l2.829 2.828a.5.5 0 11-.708.707l-3.182-3.181zM20 16.5h-8v-1h8v1z"
      fill={theme.colors.primary}
    />
  </svg>
);

export default React.memo(PrevChartIcon);
