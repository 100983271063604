import React from 'react';
import { Dialog, DialogProps } from '@material-ui/core';
import { theme } from '../contexts/AppTheme';

interface Props extends DialogProps {}

const ModalScreen: React.FC<Props> = ({ children, style, ...props }) => (
  <Dialog
    disablePortal
    style={{
      overflow: 'auto',
      padding: theme.space[6],
      zIndex: 2,
      ...style,
    }}
    {...props}
  >
    {children}
  </Dialog>
);

export default ModalScreen;
