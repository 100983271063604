import React from 'react';
import { Box, Heading } from 'rebass';
import { useRouteMatch } from 'react-router-dom';
import * as R from 'ramda';
import Header from '../components/Header';
import strings from '../theme/strings';
import CourseList from '../components/CourseList';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserDataLoader from '../hooks/useUserDataLoader';
import useCourseSelectors from '../hooks/useCourseSelectors';
import CourseUnavailable from '../components/CourseUnavailable';
import ModalRoute from '../navigation/ModalRoute';
import { ModalRoutes } from '../navigation/routes';

const MainPage: React.FC = () => {
  const match = useRouteMatch();

  const { firebaseState, loadCourses } = useFirebaseContext();
  const { getCourses } = useCourseSelectors();

  useUserDataLoader();

  const courses = React.useMemo(
    () => getCourses(firebaseState),
    [firebaseState, getCourses],
  );

  React.useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  const renderModalRoute = React.useCallback(
    () => (
      <ModalRoute
        path={ModalRoutes.COURSE_UNAVAILABLE}
        parentPath={R.prop('url')(match)}
        component={CourseUnavailable}
      />
    ),
    [match],
  );

  return (
    <Box>
      <Header />
      <Heading
        as="h1"
        fontSize={[5, 6, 7]}
        lineHeight={5}
        mt={10}
        mr={[9, 10, 15]}
        mb={13}
        ml={[8, 10, 11]}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {strings.mainPage.heading}
      </Heading>
      <CourseList courses={courses} />
      {renderModalRoute()}
    </Box>
  );
};

export default React.memo(MainPage);
