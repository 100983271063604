import React from 'react';
import { Box, Button, Heading, Link } from 'rebass';
import strings from '../theme/strings';
import FTInput from './FTInput';
import FTCheckbox from './FTCheckbox';
import { Links } from '../theme/constants';
import { Maybe } from '../utils';

interface Props {
  email: string;
  onEmailChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onConditionsChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onPrivacyChange: (e: React.FormEvent<HTMLInputElement>) => void;
  isFormSubmitAvailable: boolean;
  onFormSubmit: (e: React.FormEvent<HTMLInputElement>) => void;
  renderNotification: () => Maybe<JSX.Element>;
  renderPassword: () => Maybe<JSX.Element>;
}

const LINK_DEFAULT_STYLES: React.CSSProperties = {
  color: 'primary',
  textDecoration: 'none',
};

const SignInForm: React.FC<Props> = ({
  email,
  onEmailChange,
  onConditionsChange,
  onPrivacyChange,
  isFormSubmitAvailable,
  onFormSubmit,
  renderNotification,
  renderPassword,
}) => {
  const renderHeading = React.useCallback(
    () => (
      <Heading as="h1" color="black" fontSize={4} mb={5}>
        {strings.signInPage.heading}
      </Heading>
    ),
    []
  );

  const renderEmail = React.useCallback(
    () => (
      <Box>
        <FTInput
          name="email"
          type="email"
          labelText={strings.signInPage.emailInputLabel}
          value={email}
          onChange={onEmailChange}
        />
      </Box>
    ),
    [email, onEmailChange]
  );

  const renderLoginButton = React.useCallback(
    () => (
      <Button
        variant={isFormSubmitAvailable ? 'formBlueButton' : 'formGrayButton'}
        type="submit"
        disabled={!isFormSubmitAvailable}
        fontSize={1}
        mt={2}
        mx={12}
      >
        {strings.signInPage.loginButton}
      </Button>
    ),
    [isFormSubmitAvailable]
  );

  const renderConditionsCheckbox = React.useCallback(
    () => (
      <FTCheckbox name="conditions" onClick={onConditionsChange}>
        <Link href={Links.CONDITIONS} target="_blank" sx={LINK_DEFAULT_STYLES}>
          {strings.other.conditions}
        </Link>
      </FTCheckbox>
    ),
    [onConditionsChange]
  );

  const renderPrivacyCheckbox = React.useCallback(
    () => (
      <FTCheckbox name="privacy" onClick={onPrivacyChange}>
        <Link href={Links.PRIVACY} target="_blank" sx={LINK_DEFAULT_STYLES}>
          {strings.other.privacyPolicy}
        </Link>
      </FTCheckbox>
    ),
    [onPrivacyChange]
  );

  return (
    <Box sx={{ textAlign: 'center' }}>
      {renderHeading()}
      <Box
        as="form"
        onSubmit={onFormSubmit}
        display="grid"
        sx={{
          gridTemplateColumns: '18.75rem',
          gridGap: 5,
        }}
      >
        {renderEmail()}
        {renderPassword()}
        {renderNotification()}
        {renderLoginButton()}
        <Box mt={4} style={{ position: 'relative' }}>
          <Box mb={2}>{renderConditionsCheckbox()}</Box>
          <Box>{renderPrivacyCheckbox()}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(SignInForm);
