import DetailedWeek from './DetailedWeek';
import { CourseCover } from './Course';

export enum AnswerTypes {
  before = 'before',
  after = 'after',
  both = 'both',
}

export type AnswerType = AnswerTypes.before | AnswerTypes.after;

export interface AnswerData {
  afterAnswers: number[];
  answerName: string;
  beforeAnswers: number[];
}

export default interface DetailedCourse {
  id: string;
  name: string;
  cover: CourseCover;
  weeksNum: number;
  completedWeeks: number;
  weeks: DetailedWeek[] | null;
  answersData: AnswerData[] | null;
}
