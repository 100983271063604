import React from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import TrackPlayer from '../components/TrackPlayer';
import ModalRoute from '../navigation/ModalRoute';
import QuestionTest from '../components/QuestionTest';
import { ModalRoutes } from '../navigation/routes';

interface RouteParams {
  courseId?: string;
  weekId?: string;
}

const WeekPage: React.FC = () => {
  const { courseId, weekId } = useParams<RouteParams>();

  return !R.isNil(courseId) && !R.isNil(weekId) ? (
    <>
      <TrackPlayer
        courseId={courseId}
        weekId={parseInt(weekId, 10)}
        display={['flex', 'flex', 'initial']}
        order={[1, 1, 2]}
      />
      <ModalRoute component={QuestionTest} path={ModalRoutes.QUESTION_TEST} />
    </>
  ) : null;
};

export default React.memo(WeekPage);
