import { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { usePrevious, useUpdateEffect } from 'react-use';
import { routeLinks } from '../navigation/routes';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserSelectors from './useUserSelectors';
import useRemoteSetters from './useRemoteSetters';
import useEndWorkout from './useEndWorkout';
import { getStartOfWeekMs } from '../utils';
import useUserDataLoader from './useUserDataLoader';

const useCourseNavigatorEffects = (courseId: string, weekId?: number) => {
  const history = useHistory();

  const {
    firebaseState,
    loadCourses,
    loadTracks,
    loadQuestions,
    loadQuizQuestions,
    loadQuizAnswers,
    setTimeStarted,
  } = useFirebaseContext();

  const {
    getAuthUser,
    getUserAnswers,
    getFinishedWeeks,
    getWeekFinishedTracks,
    getCourseFinishedQuizQuestions,
  } = useUserSelectors();

  const {
    updateTimeStartedRemote,
    updateTimeFinishedRemote,
    updateUserAnswersRemote,
    updateFinishedWorkoutsRemote,
    updateFinishedTracksRemote,
    updateFinishedQuizQuestionsRemote,
  } = useRemoteSetters();

  const {
    isQuizCompleted,
    isQuizExists,
    timeStarted,
    nextWeekId,
    nextWeekTimeStarted,
    timeFinished,
    afterQuestions,
    isWeekFinished,
    isAllTracksFinished,
    finishWorkout,
  } = useEndWorkout(courseId, weekId);

  useUserDataLoader();

  const authUser = useMemo(
    () => getAuthUser(firebaseState),
    [firebaseState, getAuthUser],
  );

  const userAnswers = useMemo(
    () => getUserAnswers(firebaseState),
    [firebaseState, getUserAnswers],
  );

  const finishedWeeks = useMemo(
    () => getFinishedWeeks(firebaseState, courseId),
    [courseId, firebaseState, getFinishedWeeks],
  );

  const finishedWeekTracks = useMemo(
    () =>
      !R.isNil(weekId)
        ? getWeekFinishedTracks(firebaseState, courseId, weekId)
        : null,
    [courseId, firebaseState, getWeekFinishedTracks, weekId],
  );

  const finishedQuizQuestions = useMemo(
    () => getCourseFinishedQuizQuestions(firebaseState, courseId),
    [courseId, firebaseState, getCourseFinishedQuizQuestions],
  );

  const prevWeekId = usePrevious(weekId);

  useEffect(() => {
    loadCourses();
    loadQuestions();
    loadQuizQuestions();
    loadQuizAnswers();
    loadTracks(courseId);
  }, [
    loadCourses,
    loadQuestions,
    loadTracks,
    courseId,
    loadQuizQuestions,
    loadQuizAnswers,
  ]);

  useEffect(() => {
    if (!R.isNil(authUser) && !R.isNil(userAnswers))
      updateUserAnswersRemote(authUser, userAnswers);
  }, [authUser, updateUserAnswersRemote, userAnswers]);

  useEffect(() => {
    if (!R.isNil(authUser) && !R.isNil(finishedWeeks))
      updateFinishedWorkoutsRemote(authUser, courseId, finishedWeeks);
  }, [authUser, courseId, finishedWeeks, updateFinishedWorkoutsRemote]);

  useUpdateEffect(() => {
    if (!R.isNil(authUser) && !R.isNil(weekId) && !R.isNil(finishedWeekTracks))
      updateFinishedTracksRemote(
        authUser,
        courseId,
        weekId,
        finishedWeekTracks,
      );
  }, [
    authUser,
    courseId,
    finishedWeekTracks,
    updateFinishedTracksRemote,
    weekId,
  ]);

  useUpdateEffect(() => {
    if (isAllTracksFinished) {
      finishWorkout();
    }
  }, [finishWorkout, isAllTracksFinished]);

  useEffect(() => {
    if (!R.isNil(authUser) && !R.isNil(finishedQuizQuestions))
      updateFinishedQuizQuestionsRemote(
        authUser,
        courseId,
        finishedQuizQuestions,
      );
  }, [
    authUser,
    courseId,
    finishedQuizQuestions,
    updateFinishedQuizQuestionsRemote,
  ]);

  useEffect(() => {
    if (
      !R.isNil(weekId) &&
      !R.equals(weekId, prevWeekId) &&
      isAllTracksFinished &&
      ((isQuizExists && !isQuizCompleted) || !R.empty(afterQuestions))
    )
      history.push(routeLinks.getQuestionTestLink(courseId, weekId), {
        isForward: false,
      });
  }, [
    afterQuestions,
    courseId,
    history,
    isAllTracksFinished,
    isQuizCompleted,
    isQuizExists,
    isWeekFinished,
    prevWeekId,
    weekId,
  ]);

  useEffect(() => {
    if (!isWeekFinished && !timeStarted && weekId)
      setTimeStarted(courseId, weekId, getStartOfWeekMs());
  }, [courseId, isWeekFinished, setTimeStarted, timeStarted, weekId]);

  useUpdateEffect(() => {
    if (!R.isNil(authUser) && !R.isNil(courseId) && !R.isNil(weekId)) {
      if (!R.isNil(timeStarted))
        updateTimeStartedRemote(authUser, courseId, weekId, timeStarted);
      if (!R.isNil(nextWeekId) && !R.isNil(nextWeekTimeStarted))
        updateTimeStartedRemote(
          authUser,
          courseId,
          nextWeekId,
          nextWeekTimeStarted,
        );
      if (!R.isNil(timeFinished))
        updateTimeFinishedRemote(authUser, courseId, weekId, timeFinished);
    }
  }, [
    authUser,
    courseId,
    nextWeekId,
    nextWeekTimeStarted,
    timeFinished,
    timeStarted,
    updateTimeFinishedRemote,
    updateTimeStartedRemote,
    weekId,
  ]);
};

export default useCourseNavigatorEffects;
