import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import { Routes } from './routes';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserSelectors from '../hooks/useUserSelectors';

interface Props extends RouteProps {
  component: React.ComponentType;
}

const ProtectedAuthenticationRoute: React.FC<Props> = ({
  component: Component,
  ...rest
}) => {
  const { firebaseState } = useFirebaseContext();
  const { getIsAuthLoading, getAuthUser } = useUserSelectors();

  const isAuthLoading = React.useMemo(
    () => getIsAuthLoading(firebaseState),
    [firebaseState, getIsAuthLoading],
  );

  const authUser = React.useMemo(
    () => getAuthUser(firebaseState),
    [firebaseState, getAuthUser],
  );

  const renderRouteContent = React.useCallback<
    (props: RouteComponentProps) => JSX.Element | null
  >(
    (props) => {
      if (isAuthLoading) return null;

      if (!authUser) {
        return <Component {...rest} {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: Routes.HOME,
            state: {
              from: props.location,
            },
          }}
        />
      );
    },
    [Component, authUser, isAuthLoading, rest],
  );

  return <Route {...rest} render={renderRouteContent} />;
};

export default ProtectedAuthenticationRoute;
