export interface TrackAudio {
  duration: number;
  path: string;
}

export interface TrackVideo extends TrackAudio {}

export interface TrackPreview {
  path: string;
}

export interface TrackDocument extends TrackPreview {}

export enum TrackTypes {
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
  CURRENT = 'CURRENT',
  LEFT = 'LEFT',
}

export type TrackType =
  | TrackTypes.BLOCKED
  | TrackTypes.COMPLETED
  | TrackTypes.CURRENT
  | TrackTypes.LEFT;

export enum TrackMedias {
  PREVIEW = 'preview',
  AUDIO = 'audio',
  VIDEO = 'video',
}

export type TrackMedia =
  | TrackMedias.PREVIEW
  | TrackMedias.AUDIO
  | TrackMedias.VIDEO;

export interface TrackData {
  id: string;
  audio: TrackAudio;
  course: string;
  name: string;
  type: 'media' | 'document';
  order: number;
  document: TrackDocument;
  preview: TrackPreview;
  video: TrackVideo;
  week: number;
}
