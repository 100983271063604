import React from 'react';
import { Box, Text } from 'rebass';
import * as R from 'ramda';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useCourseSelectors from '../hooks/useCourseSelectors';
import strings from '../theme/strings';
import { formatNumber } from '../utils';
import { IMAGE_TEXT_REBASS_STYLE } from '../theme/constants';

interface Props {
  courseId: string;
  weekId?: number;
}

const CourseImageContent: React.FC<Props> = ({ courseId, weekId }) => {
  const { firebaseState } = useFirebaseContext();
  const { getDetailedCourse } = useCourseSelectors();

  const course = React.useMemo(
    () => getDetailedCourse(firebaseState, courseId),
    [courseId, firebaseState, getDetailedCourse]
  );

  const renderCompletedWeeksCounter = React.useCallback(
    () =>
      !R.isNil(course) && (
        <>
          <Text as="h3" fontWeight="normal" fontSize={1} lineHeight={1}>
            {strings.coursePage.completedWeeks.heading}:
          </Text>
          <Text display="flex" fontSize={9} lineHeight={8}>
            {formatNumber(R.prop('completedWeeks')(course))}
            <Text as="span" fontSize={1} lineHeight={6} ml={2}>
              / {formatNumber(R.prop('weeksNum')(course))}
            </Text>
          </Text>
        </>
      ),
    [course]
  );

  const renderCourseName = React.useCallback(
    () =>
      !R.isNil(course) && (
        <Text as="h2" fontWeight="bold" fontSize={8} lineHeight={7} pt={5}>
          {R.prop('name')(course)}
        </Text>
      ),
    [course]
  );

  const renderWeekName = React.useCallback(
    () => (
      <Text as="h2" fontWeight="bold" fontSize={6} lineHeight={5}>
        {!R.isNil(weekId) &&
          strings.coursePage.getWeekName(formatNumber(weekId))}
      </Text>
    ),
    [weekId]
  );

  return (
    <Text color="white" sx={IMAGE_TEXT_REBASS_STYLE}>
      {!R.isNil(weekId) ? (
        <Box mt={16} ml={[0, 8]}>
          {renderWeekName()}
        </Box>
      ) : (
        <Box pl={[0, 8]} pt={14} pb={[0, 6, 7, 13]}>
          {renderCompletedWeeksCounter()}
          {renderCourseName()}
        </Box>
      )}
    </Text>
  );
};

export default React.memo(CourseImageContent);
