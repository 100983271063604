const spacings = {
  space: [
    '0',
    '0.375rem',
    '0.5rem',
    '0.8rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.6rem',
    '1.875rem',
    '2.4rem',
    '3.125rem',
    '3.25rem',
    '3.75rem',
    '4.25rem',
    '7.5rem',
    '9.375rem',
    '12rem',
  ],
  sizes: [
    '100%',
    '90%',
    '0.125rem',
    '0.25rem',
    '0.375rem',
    '0.875rem',
    '5rem',
    '16rem',
    '19rem',
    '24rem',
    '28rem',
    '33rem',
  ],
  radii: [
    '0',
    '0.3125rem',
    '0.4rem',
    '0.625rem',
    '1.25rem',
    '1.5rem',
    '2.4rem',
  ],
};

export default spacings;
