import React from 'react';
import { theme } from '../../contexts/AppTheme';

const AccountIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 35 35" fill="none" {...props}>
    <path
      d="M33.5 17c0 9.113-7.387 16.5-16.5 16.5S.5 26.113.5 17 7.887.5 17 .5 33.5 7.887 33.5 17z"
      stroke="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.312 18.478a3.696 3.696 0 100-7.391 3.696 3.696 0 000 7.391zm0-1.293a2.402 2.402 0 110-4.805 2.402 2.402 0 010 4.805zm3.235 3.735a5.913 5.913 0 012.204 2.631l-1.088.464a4.73 4.73 0 00-8.74.089l-1.097-.441a5.914 5.914 0 018.72-2.743z"
      fill={theme.colors.white}
    />
  </svg>
);

export default React.memo(AccountIcon);
