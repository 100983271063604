export enum Routes {
  HOME = '/',
  SIGN_IN = '/login',
  COURSE_PAGE = '/course/:courseId',
  WEEK_PAGE = '/course/:courseId/week/:weekId',
}

export enum ModalRoutes {
  COURSE_UNAVAILABLE = '/course-unavailable',
  QUIZ_NOT_COMPLETED = '/course/:courseId/quiz-not-completed',
  MY_PROGRESS = '/course/:courseId/progress',
  QUESTION_TEST = '/course/:courseId/week/:weekId/question-test',
}

export const routeLinks = {
  getCourseLink: (courseId: string) => `/course/${courseId}`,
  getMyProgressLink: (courseId: string) => `/course/${courseId}/progress`,
  getWeekLink: (courseId: string, weekId: number) =>
    `/course/${courseId}/week/${weekId}`,
  getQuestionTestLink: (courseId: string, weekId: number) =>
    `/course/${courseId}/week/${weekId}/question-test`,
  getQuizNotCompletedLink: (courseId: string, prevWeekId: number) =>
    `/course/${courseId}/quiz-not-completed?prevWeek=${prevWeekId}`,
};
