import React from 'react';
import { Box, Text } from 'rebass';
import { Link, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import strings from '../theme/strings';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import ProgressBar from './ProgressBar';
import { ModalRoutes, routeLinks, Routes } from '../navigation/routes';
import {
  IMAGE_TEXT_REBASS_STYLE,
  LINK_DEFAULT_STYLE,
  LINK_DISABLED_STYLE,
} from '../theme/constants';
import LinearGradient from './LinearGradient';
import { Course } from '../models/Course';
import { formatFullDate, formatNumber } from '../utils';
import useCourseSelectors from '../hooks/useCourseSelectors';
import useUserSelectors from '../hooks/useUserSelectors';
import CourseImage from './CourseImage';
import { theme } from '../contexts/AppTheme';

const DEFAULT_FINISHED_WEEKS_NUM = 0;

interface Props {
  course: Course;
}

const CourseSummary: React.FC<Props> = ({ course }) => {
  const history = useHistory();

  const { firebaseState } = useFirebaseContext();
  const { getCourseCover } = useCourseSelectors();
  const {
    getIsAuthLoading,
    getAuthUser,
    getUserCourseData,
    getIsCourseAvailable,
    getFinishedWeeks,
  } = useUserSelectors();

  const isCourseAvailable = React.useMemo(
    () => getIsCourseAvailable(firebaseState, R.prop('id')(course)),
    [course, firebaseState, getIsCourseAvailable]
  );

  const courseCover = React.useMemo(
    () => getCourseCover(firebaseState, R.prop('id')(course)),
    [course, firebaseState, getCourseCover]
  );

  const backgroundImage = React.useMemo(
    () =>
      isCourseAvailable
        ? `${theme.colors.blackBottomGradient}, url(${courseCover})`
        : `${theme.colors.whiteTransparentGradient}, url(${courseCover})`,
    [courseCover, isCourseAvailable]
  );

  const courseStyle = React.useMemo(
    () => ({
      backgroundImage,
      cursor: 'pointer',
    }),
    [backgroundImage]
  );

  const coursePageLink = React.useMemo(
    () => routeLinks.getCourseLink(R.prop('id')(course)),
    [course]
  );

  const coursePageLinkStyle = React.useMemo(
    () =>
      isCourseAvailable
        ? LINK_DEFAULT_STYLE
        : R.mergeRight(LINK_DEFAULT_STYLE, LINK_DISABLED_STYLE),
    [isCourseAvailable]
  );

  const progressPercent = React.useMemo(() => {
    const finishedWeeks = getFinishedWeeks(firebaseState, R.prop('id')(course));

    const finishedWeeksNum = !R.isNil(finishedWeeks)
      ? R.length(R.keys(finishedWeeks))
      : DEFAULT_FINISHED_WEEKS_NUM;

    return R.divide(
      R.multiply(finishedWeeksNum)(100),
      R.prop('weeksNum')(course)
    );
  }, [course, firebaseState, getFinishedWeeks]);

  const isAuthLoading = React.useMemo(() => getIsAuthLoading(firebaseState), [
    firebaseState,
    getIsAuthLoading,
  ]);

  const authUser = React.useMemo(() => getAuthUser(firebaseState), [
    firebaseState,
    getAuthUser,
  ]);

  const userCourseData = React.useMemo(
    () => getUserCourseData(firebaseState, R.prop('id')(course)),
    [course, firebaseState, getUserCourseData]
  );

  const renderCourseData = React.useCallback(
    () => (
      <Text color="white" sx={IMAGE_TEXT_REBASS_STYLE}>
        <Text as="h3" fontSize={4} lineHeight={3}>
          {R.prop('name')(course)}
        </Text>
        <Text as="h4" fontWeight="normal" fontSize={1} lineHeight={0} mt={1}>
          {strings.mainPage.getWeeksNumber(
            formatNumber(R.prop('weeksNum')(course))
          )}
        </Text>
      </Text>
    ),
    [course]
  );

  const renderCourseUserInfo = React.useCallback(
    () =>
      isCourseAvailable && (
        <Box mt={3}>
          <ProgressBar
            sectionsNum={R.prop('weeksNum')(course)}
            progressPercent={progressPercent}
          >
            <LinearGradient gradientWidth={`${progressPercent}%`} />
          </ProgressBar>
          <Text
            color="white"
            fontSize={0}
            lineHeight={0}
            mt={3}
            sx={IMAGE_TEXT_REBASS_STYLE}
          >
            {!R.isNil(userCourseData) &&
              strings.mainPage.getCourseExpiration(
                formatFullDate(R.prop('expiration')(userCourseData))
              )}
          </Text>
        </Box>
      ),
    [course, isCourseAvailable, progressPercent, userCourseData]
  );

  const onCourseClick = React.useCallback(() => {
    if (!isAuthLoading && R.isNil(authUser)) {
      history.push(Routes.SIGN_IN);
    } else if (!isCourseAvailable) {
      history.push(ModalRoutes.COURSE_UNAVAILABLE);
    }
  }, [authUser, history, isAuthLoading, isCourseAvailable]);

  const renderCourseContent = React.useCallback(
    () => (
      <CourseImage
        courseId={R.prop('id')(course)}
        height="initial"
        onClick={onCourseClick}
        style={courseStyle}
        sx={{ borderRadius: 5 }}
      >
        <Box flex="1" pr={10}>
          {renderCourseData()}
          {renderCourseUserInfo()}
        </Box>
      </CourseImage>
    ),
    [courseStyle, course, onCourseClick, renderCourseData, renderCourseUserInfo]
  );

  return isCourseAvailable ? (
    <Link to={coursePageLink} style={coursePageLinkStyle}>
      {renderCourseContent()}
    </Link>
  ) : (
    renderCourseContent()
  );
};

export default React.memo(CourseSummary);
