import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import useCourseSelectors from './useCourseSelectors';
import { FirebaseState } from '../store/reducers/firebaseReducer';
import { MINIMAL_POINTS_TO_DISPLAY } from '../components/ChartCarousel';
import { AnswerData } from '../models/DetailedCourse';
import { Chart, ChartData } from '../models/Chart';
import useUserSelectors from './useUserSelectors';
import strings from '../theme/strings';

const START_OF_WEEK_CODE = 0;
const END_OF_WEEK_CODE = 7;

const useChartSelectors = () => {
  const { getCourseAnswers } = useCourseSelectors();
  const { getIsWeekFinished, getTimeStarted, getTimeFinished } =
    useUserSelectors();

  const getAnswersData = React.useCallback<
    (state: FirebaseState, courseId: string) => AnswerData[] | null
  >(
    (state, courseId) => {
      const courseAnswers = getCourseAnswers(state, courseId);
      return (
        courseAnswers &&
        R.filter(
          (answerData) =>
            R.length(answerData.beforeAnswers) >= MINIMAL_POINTS_TO_DISPLAY ||
            R.length(answerData.afterAnswers) >= MINIMAL_POINTS_TO_DISPLAY,
          courseAnswers,
        )
      );
    },
    [getCourseAnswers],
  );

  const getAnswersYAxisTicks = React.useCallback<
    (answerData: AnswerData) => number[]
  >(
    (answerData) => [
      R.min<number>(
        R.reduce<number, number>(
          R.min,
          Infinity,
          R.prop('beforeAnswers')(answerData),
        ),
        R.reduce<number, number>(
          R.min,
          Infinity,
          R.prop('afterAnswers')(answerData),
        ),
      ),
      R.max<number>(
        R.reduce<number, number>(
          R.max,
          -Infinity,
          R.prop('beforeAnswers')(answerData),
        ),
        R.reduce<number, number>(
          R.max,
          -Infinity,
          R.prop('afterAnswers')(answerData),
        ),
      ) + 1,
    ],
    [],
  );

  const getProgressXAxisTicks = React.useCallback<
    (weeks: number[]) => number[]
  >((weeks) => weeks && R.range(1, R.length(weeks) + 2), []);

  const getProgressYAxisTicks = React.useCallback(
    () => [START_OF_WEEK_CODE, END_OF_WEEK_CODE],
    [],
  );

  const getAnswersChartData = React.useCallback<
    (answerData: AnswerData, weeks: number[]) => ChartData[]
  >(
    (answerData, weeks) =>
      R.map(
        (weekId) => ({
          week: weekId,
          ...(R.nth(weekId - 1, answerData.afterAnswers) && {
            after: R.nth(weekId - 1, answerData.afterAnswers),
          }),
          ...(R.nth(weekId - 1, answerData.beforeAnswers) && {
            before: R.nth(weekId - 1, answerData.beforeAnswers),
          }),
        }),
        weeks,
      ),
    [],
  );

  const getProgressChartData = React.useCallback<
    (state: FirebaseState, courseId: string, weeks: number[]) => ChartData[]
  >(
    (state, courseId, weeks) => {
      const xAxisTicks = getProgressXAxisTicks(weeks);

      return R.map((weekId) => {
        const isWeekFinished = getIsWeekFinished(state, courseId, weekId);
        const timeStarted = getTimeStarted(state, courseId, weekId);
        const timeFinished = getTimeFinished(state, courseId, weekId);
        const daysSpent =
          timeStarted &&
          timeFinished &&
          Math.max(
            Math.floor(
              moment
                .duration(timeFinished - timeStarted, 'milliseconds')
                .asDays(),
            ),
            0,
          );

        return R.mergeRight(
          { week: weekId },
          isWeekFinished && !R.isNil(daysSpent)
            ? {
                week: weekId,
                daysSpentActual: R.inc(daysSpent),
                daysSpentDisplay: R.lte(daysSpent)(6)
                  ? R.inc(daysSpent)
                  : END_OF_WEEK_CODE,
              }
            : { week: weekId },
        );
      }, xAxisTicks);
    },
    [getIsWeekFinished, getProgressXAxisTicks, getTimeFinished, getTimeStarted],
  );

  const getAnswersChart = React.useCallback<
    (answerData: AnswerData, weeks: number[]) => Chart
  >(
    (answerData, weeks) => {
      const yAxisTicks = getAnswersYAxisTicks(answerData);
      const data = getAnswersChartData(answerData, weeks);

      return {
        name: answerData.answerName,
        xAxisTicks: weeks,
        yAxisTicks,
        data,
      };
    },
    [getAnswersYAxisTicks, getAnswersChartData],
  );

  const getProgressChart = React.useCallback<
    (state: FirebaseState, courseId: string, weeks: number[]) => Chart
  >(
    (state, courseId, weeks) => {
      const xAxisTicks = getProgressXAxisTicks(weeks);
      const yAxisTicks = getProgressYAxisTicks();
      const data = getProgressChartData(state, courseId, weeks);

      return {
        name: strings.coursePage.progressChart.heading,
        xAxisTicks,
        yAxisTicks,
        data,
      };
    },
    [getProgressXAxisTicks, getProgressYAxisTicks, getProgressChartData],
  );

  return {
    getAnswersData,
    getAnswersChart,
    getProgressChart,
  };
};

export default useChartSelectors;
