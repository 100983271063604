import React from 'react';
import { Box, Text } from 'rebass';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { QuizQuestion } from '../models/QuizQuestion';
import { DEFAULT_SLIDE_REBASS_STYLE } from './QuestionTest';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useQuestionSelectors from '../hooks/useQuestionSelectors';
import { QuizAnswer } from '../models/QuizAnswer';
import { theme } from '../contexts/AppTheme';

const useStyles = makeStyles({
  radio: {
    '&.Mui-checked': {
      color: theme.colors.primary,
    },
  },
  radioLabel: {
    color: theme.colors.grayMedium,
    fontSize: '14px',
    lineHeight: '21px',
  },
});

interface Props {
  quizQuestion: QuizQuestion;
  handleQuizAnswerChange: (
    event: React.SyntheticEvent<HTMLInputElement, Event>
  ) => void;
}

const QuizQuestionSummary: React.FC<Props> = ({
  quizQuestion,
  handleQuizAnswerChange,
}) => {
  const classes = useStyles();
  const { firebaseState } = useFirebaseContext();
  const { getQuizQuestionAnswers } = useQuestionSelectors();

  const quizQuestionAnswers = React.useMemo(
    () => getQuizQuestionAnswers(firebaseState, R.prop('id')(quizQuestion)),
    [firebaseState, getQuizQuestionAnswers, quizQuestion]
  );

  const renderQuizAnswer = React.useCallback<
    (quizAnswer: QuizAnswer) => JSX.Element
  >(
    (quizAnswer) => (
      <Box mb={2} key={R.prop('id')(quizAnswer)}>
        <FormControlLabel
          value={R.prop('id')(quizAnswer)}
          control={<Radio className={R.prop('radio')(classes)} />}
          label={R.prop('name')(quizAnswer)}
          className={R.prop('radioLabel')(classes)}
        />
      </Box>
    ),
    [classes]
  );

  return (
    <Box sx={DEFAULT_SLIDE_REBASS_STYLE}>
      <Text fontSize={3} lineHeight={1} key={R.prop('id')(quizQuestion)}>
        {R.prop('name')(quizQuestion)}
      </Text>
      <Box mt={6} p={3}>
        <FormControl>
          <RadioGroup
            aria-label="quizQuestion"
            name={R.prop('id')(quizQuestion)}
            onChange={handleQuizAnswerChange}
          >
            {R.map(renderQuizAnswer, quizQuestionAnswers)}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default React.memo(QuizQuestionSummary);
