/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as R from 'ramda';
import { FirebaseState } from '../store/reducers/firebaseReducer';
import { Question } from '../models/Question';
import { UserAnswers } from '../models/UserData';
import { AnswerType, AnswerTypes } from '../models/DetailedCourse';
import { QuizQuestion } from '../models/QuizQuestion';
import { QuizAnswer } from '../models/QuizAnswer';

const useQuestionSelectors = () => {
  const getQuestions = React.useCallback<(state: FirebaseState) => Question[]>(
    R.prop('questions'),
    [],
  );

  const getCourseQuestions = React.useCallback<
    (state: FirebaseState, courseId: string) => Question[]
  >((state, courseId) => {
    const courseQuestions = R.filter(R.propEq('course', courseId))(
      getQuestions(state),
    );

    const orderComparator = R.comparator<Question>((a, b) =>
      R.lt(R.prop('order', a), R.prop('order', b)),
    );

    return R.sort(orderComparator, courseQuestions);
  }, []);

  const getBeforeQuestions = React.useCallback<
    (state: FirebaseState, courseId: string) => Question[]
  >(
    (state, courseId) =>
      R.filter(
        (question) =>
          R.propEq('type', AnswerTypes.before, question) ||
          R.propEq('type', AnswerTypes.both, question),
        getCourseQuestions(state, courseId),
      ),
    [getCourseQuestions],
  );

  const getAfterQuestions = React.useCallback<
    (state: FirebaseState, courseId: string) => Question[]
  >(
    (state, courseId) =>
      R.filter(
        (question) =>
          R.propEq('type', AnswerTypes.after, question) ||
          R.propEq('type', AnswerTypes.both, question),
        getCourseQuestions(state, courseId),
      ),
    [getCourseQuestions],
  );

  const getQuestionAnswers = React.useCallback<
    (
      weeks: number[],
      answers: UserAnswers,
      question: Question,
      answerType: AnswerType,
    ) => number[]
  >(
    (weeks, answers, question, answerType) =>
      R.filter(
        (week) => !R.isNil(week),
        R.map(
          (week) => R.path([question.id, week, answerType], answers) as number,
          weeks,
        ),
      ),
    [],
  );

  const getQuestionAnswer = React.useCallback<
    (
      weekId: number,
      answers: UserAnswers,
      question: Question,
      answerType: AnswerType,
    ) => number | undefined
  >(
    (weekId, answers, question, answerType) =>
      R.path([question.id, weekId, answerType], answers),
    [],
  );

  const getQuizQuestions = (state: FirebaseState) =>
    R.prop('quizQuestions', state);

  const getCourseQuizQuestions = React.useCallback<
    (state: FirebaseState, courseId: string) => QuizQuestion[]
  >((state, courseId) => {
    const courseQuizQuestions = R.filter(
      R.propEq('course')(courseId),
      getQuizQuestions(state),
    );

    const orderComparator = R.comparator<QuizQuestion>((a, b) =>
      R.lt(R.prop('order', a), R.prop('order', b)),
    );

    return R.sort(orderComparator, courseQuizQuestions);
  }, []);

  const getWeekQuizQuestions = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => QuizQuestion[]
  >(
    (state, courseId, weekId) =>
      R.filter(
        R.propEq('week')(weekId),
        getCourseQuizQuestions(state, courseId),
      ),
    [getCourseQuizQuestions],
  );

  const getIsQuizExists = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => boolean
  >(
    (state, courseId, weekId) =>
      !R.isEmpty(getWeekQuizQuestions(state, courseId, weekId)),
    [getWeekQuizQuestions],
  );

  const getQuizAnswers = React.useCallback<
    (state: FirebaseState) => QuizAnswer[]
  >((state) => R.prop('quizAnswers')(state), []);

  const getQuizQuestionAnswers = React.useCallback<
    (state: FirebaseState, questionId: string) => QuizAnswer[]
  >(
    (state, questionId) =>
      R.filter(R.propEq('quizQuestion')(questionId), getQuizAnswers(state)),
    [getQuizAnswers],
  );

  return {
    getCourseQuestions,
    getBeforeQuestions,
    getAfterQuestions,
    getCourseQuizQuestions,
    getWeekQuizQuestions,
    getIsQuizExists,
    getQuestionAnswers,
    getQuestionAnswer,
    getQuizQuestionAnswers,
  };
};

export default useQuestionSelectors;
