import React from 'react';
import { Box, BoxProps } from 'rebass';
import * as R from 'ramda';

const DEFAULT_PROGRESS_BAR_STYLE: React.CSSProperties = {
  position: 'relative',
  justifyContent: 'space-evenly',
};

interface Props extends Omit<BoxProps, 'css'> {
  sectionsNum: number;
  progressPercent: number;
}

const ProgressBar: React.FC<Props> = ({
  children,
  sectionsNum,
  progressPercent,
  ...props
}) => {
  const renderDivider = React.useCallback<(index: number) => JSX.Element>(
    (index) => (
      <Box
        key={index}
        sx={{
          borderRight: 0,
          borderColor: 'white',
          zIndex: R.gt(
            R.dec(R.divide(progressPercent)(R.divide(100)(sectionsNum))),
            index
          )
            ? 'initial'
            : 1,
        }}
      />
    ),
    [progressPercent, sectionsNum]
  );

  const renderDividers = React.useCallback(
    () => R.times(renderDivider, sectionsNum > 0 ? R.dec(sectionsNum) : 0),
    [renderDivider, sectionsNum]
  );

  return (
    <Box
      display="flex"
      height={4}
      bg="rgba(255, 255, 255, 0.2)"
      sx={{ borderRadius: 3 }}
      style={DEFAULT_PROGRESS_BAR_STYLE}
      {...props}
    >
      {children}
      {renderDividers()}
    </Box>
  );
};

export default ProgressBar;
