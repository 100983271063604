/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import firestoreService from '../services/FirestoreService';
import { AuthUser } from '../models/AuthUser';
import {
  CourseFinishedQuizQuestions,
  CourseFinishedWeeks,
  UserAnswers,
  WeekFinishedTracks,
  WeekProgress,
} from '../models/UserData';

const useRemoteSetters = () => {
  const updateUserProgressRemote = React.useCallback<
    (
      authUser: AuthUser,
      courseId: string,
      weekId: number,
      progress: WeekProgress,
    ) => void
  >(firestoreService.updateUserProgress.bind(firestoreService), []);

  const updateTimeStartedRemote = React.useCallback<
    (
      authUser: AuthUser,
      courseId: string,
      weekId: number,
      timeStarted: number,
    ) => void
  >(firestoreService.updateTimeStarted.bind(firestoreService), []);

  const updateTimeFinishedRemote = React.useCallback<
    (
      authUser: AuthUser,
      courseId: string,
      weekId: number,
      timeFinished: number,
    ) => void
  >(firestoreService.updateTimeFinished.bind(firestoreService), []);

  const updateFinishedWorkoutsRemote = React.useCallback<
    (
      authUser: AuthUser,
      courseId: string,
      finishedWorkouts: CourseFinishedWeeks,
    ) => void
  >(firestoreService.updateFinishedWorkouts.bind(firestoreService), []);

  const updateFinishedTracksRemote = React.useCallback<
    (
      authUser: AuthUser,
      courseId: string,
      weekId: number,
      finishedTracks: WeekFinishedTracks,
    ) => void
  >(firestoreService.updateWeekFinishedTracks.bind(firestoreService), []);

  const updateFinishedQuizQuestionsRemote = React.useCallback<
    (
      authUser: AuthUser,
      courseId: string,
      finishedQuizQuestions: CourseFinishedQuizQuestions,
    ) => void
  >(firestoreService.updateFinishedQuizQuestions.bind(firestoreService), []);

  const updateUserAnswersRemote = React.useCallback<
    (authUser: AuthUser, userAnswers: UserAnswers) => void
  >(firestoreService.updateUserAnswers.bind(firestoreService), []);

  return {
    updateUserProgressRemote,
    updateTimeStartedRemote,
    updateTimeFinishedRemote,
    updateFinishedWorkoutsRemote,
    updateFinishedTracksRemote,
    updateFinishedQuizQuestionsRemote,
    updateUserAnswersRemote,
  };
};

export default useRemoteSetters;
