import React from 'react';
import * as R from 'ramda';

const INIT_INPUT_STATE = '';

const useInputState = (): [
  string,
  (e: React.FormEvent<HTMLInputElement>) => void,
] => {
  const [inputState, setState] = React.useState(INIT_INPUT_STATE);

  const setInputState = React.useCallback<
    (e: React.FormEvent<HTMLInputElement>) => void
  >(
    (e) => setState(R.pathOr(INIT_INPUT_STATE, ['currentTarget', 'value'], e)),
    [],
  );

  return [inputState, setInputState];
};

export default useInputState;
