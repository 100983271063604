export enum WeekTypes {
  COMPLETED = 'COMPLETED',
  CURRENT = 'CURRENT',
  OTHER = 'OTHER',
}

export type WeekType =
  | WeekTypes.COMPLETED
  | WeekTypes.CURRENT
  | WeekTypes.OTHER;

export default interface DetailedWeek {
  weekId: number;
  progress: number | null;
  timeSpent: number | null | undefined;
  type: WeekType;
}
