const colors = {
  colors: {
    primary: '#279BE5',
    bg: '#E5E5E5',
    gray: '#D5E0E7',
    grayDark: '#8C8C8C',
    grayMedium: '#606972',
    grayMediumLight: '#C4C4C4',
    grayLight: '#E6E9EC',
    black: '#000000',
    blackTransparent: 'rgba(0, 0, 0, 0.4)',
    blackLightTransparent: 'rgba(0, 0, 0, 0.2)',
    blackTransparentGradient:
      'linear-gradient(90deg, rgba(229, 229, 229, 0.3) 0%, rgba(229, 229, 229, 0.3) 100%)',
    blackBottomGradient:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 38.67%, #000000 100%)',
    blackTopBottomGradient:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 43.09%, rgba(0, 0, 0, 0.85) 100%)',
    white: '#FFFFFF',
    whiteTransparentGradient:
      'linear-gradient(90deg, rgba(229, 229, 229, 0.3) 0%, rgba(229, 229, 229, 0.3) 100%)',
    whiteTransparentLightGradient:
      'linear-gradient(90deg, rgba(229, 229, 229, 0.1) 0%, rgba(229, 229, 229, 0.1) 100%)',
    completed: '#28DA5A',
    lightGreen: '#0EC715',
    greenLighter: '#C0F4BF',
    error: '#CD0000',
    redLight: '#F4A3BB',
  },
};

export default colors;
