import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom';
import { ModalRoutes, Routes } from './routes';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserSelectors from '../hooks/useUserSelectors';
import useFirestoreSelectors from '../hooks/useFirestoreSelectors';

interface MatchParams {
  courseId?: string;
}

interface Props extends RouteProps {
  component: React.ComponentType;
}

const ProtectedCourseRoute: React.FC<Props> = ({
  component: Component,
  ...rest
}) => {
  const courseMatch = useRouteMatch<MatchParams>(Routes.COURSE_PAGE);

  const courseId = courseMatch?.params.courseId;

  const { firebaseState } = useFirebaseContext();

  const { getIsFirestoreLoading } = useFirestoreSelectors();

  const { getIsCourseAvailable, getIsAuthLoading, getAuthUser } =
    useUserSelectors();

  const isCourseAvailable = React.useMemo(
    () => courseId && getIsCourseAvailable(firebaseState, courseId),
    [courseId, firebaseState, getIsCourseAvailable],
  );

  const isAuthLoading = React.useMemo(
    () => getIsAuthLoading(firebaseState),
    [firebaseState, getIsAuthLoading],
  );

  const authUser = React.useMemo(
    () => getAuthUser(firebaseState),
    [firebaseState, getAuthUser],
  );

  const isFirestoreLoading = React.useMemo(
    () => getIsFirestoreLoading(firebaseState),
    [firebaseState, getIsFirestoreLoading],
  );

  const renderRouteContent = React.useCallback<
    (props: RouteComponentProps<MatchParams>) => React.ReactNode
  >(
    (props) => {
      if (!isAuthLoading && !authUser)
        return (
          <Redirect
            to={{
              pathname: Routes.SIGN_IN,
              state: {
                from: props.location,
              },
            }}
          />
        );
      if (!isFirestoreLoading && !isCourseAvailable)
        return (
          <Redirect
            to={{
              pathname: ModalRoutes.COURSE_UNAVAILABLE,
              state: {
                from: props.location,
              },
            }}
          />
        );
      return <Component {...rest} {...props} />;
    },
    [
      Component,
      authUser,
      isAuthLoading,
      isCourseAvailable,
      isFirestoreLoading,
      rest,
    ],
  );

  return <Route {...rest} render={renderRouteContent} />;
};

export default ProtectedCourseRoute;
