import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { routeLinks } from '../navigation/routes';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserSelectors from './useUserSelectors';
import useCourseSelectors from './useCourseSelectors';
import useQuestionSelectors from './useQuestionSelectors';
import {
  getCurrentTimeMs,
  getStartOfNextWeekMs,
  getStartOfWeekMs,
} from '../utils';
import { QuestionTestLocationState } from './useQuestionTest';

const useEndWorkout = (courseId: string, weekId?: number) => {
  const history = useHistory<QuestionTestLocationState>();

  const { firebaseState, setFinishedWorkout, setTimeStarted, setTimeFinished } =
    useFirebaseContext();
  const {
    getIsQuizCompleted,
    getTimeStarted,
    getTimeFinished,
    getIsWeekFinished,
    getIsAllTracksFinished,
  } = useUserSelectors();

  const { getWeeks } = useCourseSelectors();
  const { getAfterQuestions, getIsQuizExists } = useQuestionSelectors();

  const isQuizCompleted = useMemo(
    () =>
      !R.isNil(courseId) && !R.isNil(weekId)
        ? getIsQuizCompleted(firebaseState, courseId, weekId)
        : null,
    [courseId, firebaseState, getIsQuizCompleted, weekId],
  );

  const isQuizExists = useMemo(
    () =>
      !R.isNil(courseId) && !R.isNil(weekId)
        ? getIsQuizExists(firebaseState, courseId, weekId)
        : null,
    [courseId, firebaseState, getIsQuizExists, weekId],
  );

  const timeStarted = useMemo(
    () =>
      !R.isNil(courseId) && !R.isNil(weekId)
        ? getTimeStarted(firebaseState, courseId, weekId)
        : null,
    [courseId, firebaseState, getTimeStarted, weekId],
  );

  const nextWeekId = useMemo(() => {
    if (R.isNil(courseId) || R.isNil(weekId)) return null;

    const weeks = getWeeks(firebaseState, courseId);
    return !R.isNil(weeks) &&
      !R.isEmpty(weeks) &&
      R.lte(R.inc(weekId), R.length(weeks))
      ? R.inc(weekId)
      : null;
  }, [courseId, firebaseState, getWeeks, weekId]);

  const nextWeekTimeStarted = useMemo(
    () =>
      !R.isNil(courseId) && !R.isNil(nextWeekId)
        ? getTimeStarted(firebaseState, courseId, nextWeekId)
        : null,
    [courseId, firebaseState, getTimeStarted, nextWeekId],
  );

  const timeFinished = useMemo(
    () =>
      !R.isNil(courseId) && !R.isNil(weekId)
        ? getTimeFinished(firebaseState, courseId, weekId)
        : null,
    [courseId, firebaseState, getTimeFinished, weekId],
  );

  const afterQuestions = useMemo(
    () =>
      !R.isNil(courseId) ? getAfterQuestions(firebaseState, courseId) : null,
    [courseId, firebaseState, getAfterQuestions],
  );

  const isWeekFinished = useMemo(
    () =>
      !R.isNil(courseId) && !R.isNil(weekId)
        ? R.defaultTo(false)(getIsWeekFinished(firebaseState, courseId, weekId))
        : null,
    [courseId, firebaseState, getIsWeekFinished, weekId],
  );

  const isAllTracksFinished = useMemo(
    () =>
      !R.isNil(weekId)
        ? getIsAllTracksFinished(firebaseState, courseId, weekId)
        : false,
    [courseId, firebaseState, getIsAllTracksFinished, weekId],
  );

  const finishWorkout = useCallback(() => {
    if (R.isNil(courseId) || R.isNil(weekId)) return;

    if (!isWeekFinished) setFinishedWorkout(courseId, weekId);

    if (R.isNil(timeStarted))
      setTimeStarted(courseId, weekId, getStartOfWeekMs());

    if (!R.isNil(nextWeekId) && R.isNil(nextWeekTimeStarted))
      setTimeStarted(courseId, nextWeekId, getStartOfNextWeekMs());

    if (R.isNil(timeFinished))
      setTimeFinished(courseId, weekId, getCurrentTimeMs());
  }, [
    courseId,
    isWeekFinished,
    nextWeekId,
    nextWeekTimeStarted,
    setFinishedWorkout,
    setTimeFinished,
    setTimeStarted,
    timeFinished,
    timeStarted,
    weekId,
  ]);

  const handleEndWorkoutClick = useCallback(() => {
    if (R.isNil(courseId) || R.isNil(weekId)) return;

    if (
      isAllTracksFinished &&
      ((isQuizExists && !isQuizCompleted) || !R.isEmpty(afterQuestions))
    )
      history.push(
        routeLinks.getQuestionTestLink(courseId, weekId),
        isWeekFinished && isQuizExists && !isQuizCompleted
          ? { isForward: false }
          : { isForward: true },
      );
    else history.goBack();
  }, [
    afterQuestions,
    courseId,
    history,
    isAllTracksFinished,
    isQuizCompleted,
    isQuizExists,
    isWeekFinished,
    weekId,
  ]);

  return {
    isQuizCompleted,
    isQuizExists,
    timeStarted,
    nextWeekId,
    nextWeekTimeStarted,
    timeFinished,
    afterQuestions,
    isWeekFinished,
    isAllTracksFinished,
    finishWorkout,
    handleEndWorkoutClick,
  };
};

export default useEndWorkout;
