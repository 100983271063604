import React from 'react';
import { Box, BoxProps, Image } from 'rebass';
import ReactPlayer from 'react-player';
import * as R from 'ramda';
import { usePlayerContext } from '../contexts/PlayerContext';
import { getIsLandscapeOrientation } from '../utils';
import { theme } from '../contexts/AppTheme';

interface Props extends Omit<BoxProps, 'css'> {
  trackVideo: string | null | undefined;
  trackImage: string | null | undefined;
  isPlaying: boolean;
}

const IMAGE_STYLES_DEFAULT = {
  height: [9, 9, 'auto'],
  maxHeight: [9, 9, ''],
  width: 0,
};

const IMAGE_STYLES_LANDSCAPE = {
  height: '100vh',
  width: '100vw',
};

const TrackVideoPlayer: React.FC<Props> = ({
  trackVideo,
  trackImage,
  isPlaying,
  ...props
}) => {
  const [isLandscapeOrientation, setIsLandscapeOrientation] =
    React.useState(false);

  const { setIsVideoReady, setIsImageReady } = usePlayerContext();

  const imageStyles = React.useMemo(
    () =>
      isLandscapeOrientation &&
      window.screen.width <= parseInt(theme.breakpoints[1], 10)
        ? IMAGE_STYLES_LANDSCAPE
        : IMAGE_STYLES_DEFAULT,
    [isLandscapeOrientation],
  );

  React.useEffect(() => {
    setIsLandscapeOrientation(getIsLandscapeOrientation());

    window.addEventListener('orientationchange', () =>
      setIsLandscapeOrientation(getIsLandscapeOrientation()),
    );
  }, []);

  const handleVideoReady = React.useCallback(
    () => setIsVideoReady(true),
    [setIsVideoReady],
  );

  const handleImageReady = React.useCallback(
    () => setIsImageReady(true),
    [setIsImageReady],
  );

  const renderVideo = React.useCallback(
    () =>
      trackVideo && (
        <ReactPlayer
          height="100%"
          width="100%"
          loop
          playing={isPlaying}
          playsinline
          onReady={handleVideoReady}
          stopOnUnmount={false}
          url={trackVideo}
          style={{
            // fix z-index problem on iOS Safari
            transform: 'translate3d(0, 0, 0)',
            objectFit: 'cover',
          }}
        />
      ),
    [handleVideoReady, isPlaying, trackVideo],
  );

  return (
    <Box flex={1} {...props}>
      {!R.isNil(trackImage) ? (
        <Image
          // fix player bottom line displaying bug
          mb="-3px"
          src={trackImage}
          onLoad={handleImageReady}
          style={{
            minHeight: '100%',
            objectFit: 'cover',
          }}
          {...imageStyles}
        />
      ) : (
        renderVideo()
      )}
    </Box>
  );
};

export default React.memo(TrackVideoPlayer);
