import firebase from 'firebase/compat';
import app from '../firebaseConfig';
import { Course } from '../models/Course';
import { Question } from '../models/Question';
import {
  CourseFinishedQuizQuestions,
  CourseFinishedWeeks,
  UserAnswers,
  UserData,
  WeekFinishedTracks,
  WeekProgress,
} from '../models/UserData';
import { AuthUser } from '../models/AuthUser';
import { TrackData } from '../models/TrackData';
import { QuizQuestion } from '../models/QuizQuestion';
import { QuizAnswer } from '../models/QuizAnswer';

class FirestoreService {
  private readonly db: firebase.firestore.Firestore;

  constructor() {
    this.db = firebase.firestore(app);
  }

  async getCourses(): Promise<Course[]> {
    return this.db
      .collection('courses')
      .get()
      .then((coursesSnapshot) =>
        coursesSnapshot.docs.map((doc) => {
          const docData = doc.data();
          return {
            id: doc.id,
            name: docData.name,
            cover: docData.cover,
            weeks: docData.weeks ? docData.weeks : {},
            weeksNum: Object.keys(docData.weeks ? docData.weeks : {}).length,
          };
        }),
      );
  }

  async getQuestions(): Promise<Question[]> {
    const questionsSnapshot = await this.db
      .collection('questions')
      .where('inputType', '==', 'number')
      .get();
    return questionsSnapshot.docs.map((doc) => {
      const docData = doc.data();
      return {
        id: doc.id,
        course: docData.course,
        inputType: docData.inputType,
        order: docData.order,
        question: docData.question,
        type: docData.type,
      };
    });
  }

  async getQuizQuestions(): Promise<QuizQuestion[]> {
    const quizQuestionsSnapshot = await this.db
      .collection('quizQuestions')
      .get();
    return quizQuestionsSnapshot.docs.map((doc) => {
      const docData = doc.data();
      return {
        id: doc.id,
        correctAnswer: docData.correctAnswer,
        course: docData.course,
        name: docData.name,
        order: docData.order,
        week: docData.week,
      };
    });
  }

  async getQuizAnswers(): Promise<QuizAnswer[]> {
    const quizAnswersSnapshot = await this.db.collection('quizAnswers').get();
    return quizAnswersSnapshot.docs.map((doc) => {
      const docData = doc.data();
      return {
        id: doc.id,
        name: docData.name,
        order: docData.order,
        quizQuestion: docData.quizQuestion,
      };
    });
  }

  async getTracks(courseId: string): Promise<TrackData[]> {
    const tracksSnapshot = await this.db
      .collection('tracks')
      .where('course', '==', courseId)
      .get();
    return tracksSnapshot.docs.map((doc) => {
      const docData = doc.data();
      return {
        id: doc.id,
        audio: docData.audio,
        course: docData.course,
        name: docData.name,
        type: docData.type,
        order: docData.order,
        document: docData.document,
        preview: docData.preview,
        video: docData.video,
        week: docData.week,
      };
    });
  }

  getUserData(authUser: AuthUser, onUpdate: (data: UserData | null) => void) {
    this.db
      .collection('users')
      .doc(authUser.uid)
      .onSnapshot((userSnapshot) => {
        const userData = userSnapshot.data();
        onUpdate(
          userData
            ? {
                answers: userData.answers,
                courses: userData.courses,
                finishedWorkouts: userData.finishedWorkouts,
                finishedTracks: userData.finishedTracks,
                ...(userData.finishedQuizQuestions && {
                  finishedQuizQuestions: userData.finishedQuizQuestions,
                }),
                progress: userData.progress,
                isDemo: userData.isDemo,
              }
            : null,
        );
      });
  }

  async updateUserProgress(
    authUser: AuthUser,
    courseId: string,
    weekId: number,
    progress: WeekProgress,
  ): Promise<void> {
    await this.db
      .collection('users')
      .doc(authUser.uid)
      .update({
        [`progress.${courseId}.${weekId}`]: progress,
      });
  }

  async updateTimeStarted(
    authUser: AuthUser,
    courseId: string,
    weekId: number,
    timeStarted: number,
  ): Promise<void> {
    await this.db
      .collection('users')
      .doc(authUser.uid)
      .update({
        [`progress.${courseId}.${weekId}.timeStarted`]: timeStarted,
      });
  }

  async updateTimeFinished(
    authUser: AuthUser,
    courseId: string,
    weekId: number,
    timeFinished: number,
  ): Promise<void> {
    await this.db
      .collection('users')
      .doc(authUser.uid)
      .update({
        [`progress.${courseId}.${weekId}.timeFinished`]: timeFinished,
      });
  }

  async updateFinishedWorkouts(
    authUser: AuthUser,
    courseId: string,
    finishedWorkouts: CourseFinishedWeeks,
  ): Promise<void> {
    await this.db
      .collection('users')
      .doc(authUser.uid)
      .update({
        [`finishedWorkouts.${courseId}`]: finishedWorkouts,
      });
  }

  async updateWeekFinishedTracks(
    authUser: AuthUser,
    courseId: string,
    weekId: number,
    finishedTracks: WeekFinishedTracks,
  ): Promise<void> {
    await this.db
      .collection('users')
      .doc(authUser.uid)
      .update({
        [`finishedTracks.${courseId}.${weekId}`]: finishedTracks,
      });
  }

  async updateFinishedQuizQuestions(
    authUser: AuthUser,
    courseId: string,
    finishedQuizQuestions: CourseFinishedQuizQuestions,
  ): Promise<void> {
    await this.db
      .collection('users')
      .doc(authUser.uid)
      .update({
        [`finishedQuizQuestions.${courseId}`]: finishedQuizQuestions,
      });
  }

  async updateUserAnswers(
    authUser: AuthUser,
    userAnswers: UserAnswers,
  ): Promise<void> {
    await this.db.collection('users').doc(authUser.uid).update({
      answers: userAnswers,
    });
  }
}

const firestoreService = new FirestoreService();

export default firestoreService;
