import React from 'react';
import firebaseReducer, {
  FirebaseState,
} from '../store/reducers/firebaseReducer';
import firestoreService from '../services/FirestoreService';
import storageService from '../services/StorageService';
import authService from '../services/AuthService';
import { FirebaseActions } from '../store/actions/actionTypes';
import { AuthUser } from '../models/AuthUser';
import { Course } from '../models/Course';
import { createCtx } from '../utils';
import { TrackData, TrackMedias } from '../models/TrackData';
import { AnswerType } from '../models/DetailedCourse';

interface Context {
  firebaseState: FirebaseState;
  resetState: () => void;
  loadCourses: () => Promise<void>;
  loadTracks: (courseId: string) => Promise<void>;
  loadQuestions: () => Promise<void>;
  loadQuizQuestions: () => Promise<void>;
  loadQuizAnswers: () => Promise<void>;
  setIsAuthLoading: (isAuthLoading: boolean) => void;
  loadAuthUser: (authUser: AuthUser) => void;
  loadUserData: () => void;
  setCurrentTrack: (
    courseId: string,
    weekId: number,
    currentTrackId: string,
  ) => void;
  incrementTimeSpent: (
    courseId: string,
    weekId: number,
    increment: number,
  ) => void;
  setTimeStarted: (
    courseId: string,
    weekId: number,
    timeStarted: number,
  ) => void;
  setTimeFinished: (
    courseId: string,
    weekId: number,
    timeFinished: number,
  ) => void;
  setFinishedWorkout: (courseId: string, weekId: number) => void;
  setFinishedQuizQuestion: (
    courseId: string,
    weekId: number,
    questionId: string,
  ) => void;
  setUserAnswer: (
    questionId: string,
    weekId: number,
    type: AnswerType,
    answer: number,
  ) => void;
  setIsTrackFinished: (
    courseId: string,
    weekId: number,
    trackId: string,
    isFinished: boolean,
  ) => void;
  sendSignInLink: (email: string) => Promise<void>;
  signInWithPassword: (email: string, password: string) => Promise<void>;
  handleSignInLink: () => Promise<void>;
}

export const [useFirebaseContext, FirebaseContext] = createCtx<Context>();

export const initFirebaseState: FirebaseState = {
  courses: [],
  coursesCovers: {},
  tracks: [],
  courseDocuments: {},
  tracksPreviews: {},
  tracksAudios: {},
  tracksVideos: {},
  questions: [],
  quizQuestions: [],
  quizAnswers: [],
  authUser: null,
  userData: null,
  error: null,
  isAuthLoading: true,
  isSubmitLoading: false,
  isFirestoreLoading: true,
};

export const FirebaseContextProvider: React.FC = ({ children }) => {
  const [firebaseState, dispatch] = React.useReducer(
    firebaseReducer,
    initFirebaseState,
  );

  const resetState = React.useCallback(
    () => dispatch({ type: FirebaseActions.RESET_STATE }),
    [],
  );

  const loadCoursesCovers = React.useCallback<
    (courses: Course[]) => Promise<void>
  >(async (courses) => {
    const coursesCovers = await storageService.getCoursesCovers(courses);
    dispatch({
      type: FirebaseActions.LOAD_COURSES_COVERS,
      payload: coursesCovers,
    });
  }, []);

  const loadCourses = React.useCallback(async () => {
    try {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: true });
      const courses = await firestoreService.getCourses();
      dispatch({ type: FirebaseActions.LOAD_COURSES, payload: courses });
      await loadCoursesCovers(courses);
    } catch (error) {
      dispatch({
        type: FirebaseActions.HANDLE_FIRESTORE_ERROR,
        payload: error,
      });
    } finally {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: false });
    }
  }, [loadCoursesCovers]);

  const loadCourseDocuments = React.useCallback<
    (tracks: TrackData[]) => Promise<void>
  >(async (tracks) => {
    const courseDocuments = await storageService.getCourseDocuments(tracks);
    dispatch({
      type: FirebaseActions.LOAD_COURSE_DOCUMENTS,
      payload: courseDocuments,
    });
  }, []);

  const loadTracksPreviews = React.useCallback<
    (tracks: TrackData[]) => Promise<void>
  >(async (tracks) => {
    const trackPreviews = await storageService.getTracksMedia(
      tracks,
      TrackMedias.PREVIEW,
    );
    dispatch({
      type: FirebaseActions.LOAD_TRACKS_PREVIEWS,
      payload: trackPreviews,
    });
  }, []);

  const loadTracksAudios = React.useCallback<
    (tracks: TrackData[]) => Promise<void>
  >(async (tracks) => {
    const trackAudios = await storageService.getTracksMedia(
      tracks,
      TrackMedias.AUDIO,
    );
    dispatch({
      type: FirebaseActions.LOAD_TRACKS_AUDIOS,
      payload: trackAudios,
    });
  }, []);

  const loadTracksVideos = React.useCallback<
    (tracks: TrackData[]) => Promise<void>
  >(async (tracks) => {
    const trackVideos = await storageService.getTracksMedia(
      tracks,
      TrackMedias.VIDEO,
    );
    dispatch({
      type: FirebaseActions.LOAD_TRACKS_VIDEOS,
      payload: trackVideos,
    });
  }, []);

  const loadTracks = React.useCallback<(courseId: string) => Promise<void>>(
    async (courseId) => {
      try {
        dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: true });
        const tracks = await firestoreService.getTracks(courseId);
        dispatch({ type: FirebaseActions.LOAD_TRACKS, payload: tracks });
        await Promise.all([
          loadCourseDocuments(tracks),
          loadTracksPreviews(tracks),
          loadTracksAudios(tracks),
          loadTracksVideos(tracks),
        ]);
      } catch (error) {
        dispatch({
          type: FirebaseActions.HANDLE_FIRESTORE_ERROR,
          payload: error,
        });
      } finally {
        dispatch({
          type: FirebaseActions.IS_FIRESTORE_LOADING,
          payload: false,
        });
      }
    },
    [
      loadCourseDocuments,
      loadTracksAudios,
      loadTracksPreviews,
      loadTracksVideos,
    ],
  );

  const loadQuestions = React.useCallback(async () => {
    try {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: true });
      const questions = await firestoreService.getQuestions();
      dispatch({ type: FirebaseActions.LOAD_QUESTIONS, payload: questions });
    } catch (error) {
      dispatch({
        type: FirebaseActions.HANDLE_FIRESTORE_ERROR,
        payload: error,
      });
    } finally {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: false });
    }
  }, []);

  const loadQuizQuestions = React.useCallback(async () => {
    try {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: true });
      const quizQuestions = await firestoreService.getQuizQuestions();
      dispatch({
        type: FirebaseActions.LOAD_QUIZ_QUESTIONS,
        payload: quizQuestions,
      });
    } catch (error) {
      dispatch({
        type: FirebaseActions.HANDLE_FIRESTORE_ERROR,
        payload: error,
      });
    } finally {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: false });
    }
  }, []);

  const loadQuizAnswers = React.useCallback(async () => {
    try {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: true });
      const quizAnswers = await firestoreService.getQuizAnswers();
      dispatch({
        type: FirebaseActions.LOAD_QUIZ_ANSWERS,
        payload: quizAnswers,
      });
    } catch (error) {
      dispatch({
        type: FirebaseActions.HANDLE_FIRESTORE_ERROR,
        payload: error,
      });
    } finally {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: false });
    }
  }, []);

  const setIsAuthLoading = React.useCallback<(isAuthLoading: boolean) => void>(
    (isAuthLoading) =>
      dispatch({
        type: FirebaseActions.IS_AUTH_LOADING,
        payload: isAuthLoading,
      }),
    [],
  );

  const loadAuthUser = React.useCallback<(authUser: AuthUser) => void>(
    (authUser) =>
      dispatch({ type: FirebaseActions.LOAD_AUTH_USER, payload: authUser }),
    [],
  );

  const loadUserData = React.useCallback(async () => {
    try {
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: true });
      if (firebaseState.authUser) {
        firestoreService.getUserData(firebaseState.authUser, (userData) => {
          dispatch({ type: FirebaseActions.LOAD_USER_DATA, payload: userData });
          dispatch({
            type: FirebaseActions.IS_FIRESTORE_LOADING,
            payload: false,
          });
        });
      }
    } catch (error) {
      dispatch({
        type: FirebaseActions.HANDLE_FIRESTORE_ERROR,
        payload: error,
      });
      dispatch({ type: FirebaseActions.IS_FIRESTORE_LOADING, payload: false });
    }
  }, [firebaseState.authUser]);

  const setCurrentTrack = React.useCallback<
    (courseId: string, weekId: number, currentTrackId: string) => void
  >((courseId, weekId, currentTrackId) => {
    dispatch({
      type: FirebaseActions.SET_CURRENT_TRACK,
      payload: { courseId, weekId, currentTrackId },
    });
  }, []);

  const incrementTimeSpent = React.useCallback<
    (courseId: string, weekId: number, increment: number) => void
  >((courseId, weekId, increment) => {
    dispatch({
      type: FirebaseActions.INCREASE_TIME_SPENT,
      payload: { courseId, weekId, increment },
    });
  }, []);

  const setTimeStarted = React.useCallback<
    (courseId: string, weekId: number, timeStarted: number) => void
  >((courseId, weekId, timeStarted) => {
    dispatch({
      type: FirebaseActions.SET_TIME_STARTED,
      payload: { courseId, weekId, timeStarted },
    });
  }, []);

  const setTimeFinished = React.useCallback<
    (courseId: string, weekId: number, timeFinished: number) => void
  >((courseId, weekId, timeFinished) => {
    dispatch({
      type: FirebaseActions.SET_TIME_FINISHED,
      payload: { courseId, weekId, timeFinished },
    });
  }, []);

  const setFinishedWorkout = React.useCallback<
    (courseId: string, weekId: number) => void
  >((courseId, weekId) => {
    dispatch({
      type: FirebaseActions.SET_FINISHED_WORKOUT,
      payload: { courseId, weekId },
    });
  }, []);

  const setFinishedQuizQuestion = React.useCallback<
    (courseId: string, weekId: number, questionId: string) => void
  >((courseId, weekId, questionId) => {
    dispatch({
      type: FirebaseActions.SET_FINISHED_QUIZ_QUESTION,
      payload: { courseId, weekId, questionId },
    });
  }, []);

  const setUserAnswer = React.useCallback<
    (
      questionId: string,
      weekId: number,
      type: AnswerType,
      answer: number,
    ) => void
  >((questionId, weekId, type, answer) => {
    dispatch({
      type: FirebaseActions.SET_USER_ANSWER,
      payload: { questionId, weekId, type, answer },
    });
  }, []);

  const setIsTrackFinished = React.useCallback<
    (
      courseId: string,
      weekId: number,
      trackId: string,
      isFinished: boolean,
    ) => void
  >((courseId, weekId, trackId, isFinished) => {
    dispatch({
      type: FirebaseActions.SET_IS_TRACK_FINISHED,
      payload: { courseId, weekId, trackId, isFinished },
    });
  }, []);

  const sendSignInLink = React.useCallback<(email: string) => Promise<void>>(
    async (email) => {
      try {
        dispatch({ type: FirebaseActions.IS_SUBMIT_LOADING, payload: true });
        await authService.sendSignInLink(email);
        dispatch({ type: FirebaseActions.SEND_SIGN_IN_LINK_SUCCESS });
      } catch (error) {
        dispatch({
          type: FirebaseActions.HANDLE_SEND_SIGN_IN_LINK_ERROR,
          payload: error,
        });
      } finally {
        dispatch({ type: FirebaseActions.IS_SUBMIT_LOADING, payload: false });
      }
    },
    [],
  );

  const signInWithPassword = React.useCallback<
    (email: string, password: string) => Promise<void>
  >(async (email, password) => {
    try {
      dispatch({ type: FirebaseActions.IS_SUBMIT_LOADING, payload: true });
      await authService.signInWithPassword(email, password);
      dispatch({ type: FirebaseActions.SEND_SIGN_IN_LINK_SUCCESS });
    } catch (error) {
      dispatch({
        type: FirebaseActions.HANDLE_SEND_SIGN_IN_LINK_ERROR,
        payload: error,
      });
    } finally {
      dispatch({ type: FirebaseActions.IS_SUBMIT_LOADING, payload: false });
    }
  }, []);

  const handleSignInLink = React.useCallback(async () => {
    try {
      await authService.handleSignInLink();
      dispatch({ type: FirebaseActions.HANDLE_SIGN_IN_LINK_SUCCESS });
    } catch (error) {
      dispatch({
        type: FirebaseActions.HANDLE_SIGN_IN_LINK_ERROR,
        payload: error,
      });
    }
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        firebaseState,
        resetState,
        loadCourses,
        loadTracks,
        loadQuestions,
        loadQuizQuestions,
        loadQuizAnswers,
        setIsAuthLoading,
        loadAuthUser,
        loadUserData,
        setCurrentTrack,
        incrementTimeSpent,
        setTimeStarted,
        setTimeFinished,
        setFinishedWorkout,
        setFinishedQuizQuestion,
        setUserAnswer,
        setIsTrackFinished,
        sendSignInLink,
        signInWithPassword,
        handleSignInLink,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
