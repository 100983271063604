import React from 'react';
import { Box, Text } from 'rebass';
import * as R from 'ramda';
import { formatDuration } from '../utils';
import ProgressBar from './ProgressBar';
import { TrackData, TrackType, TrackTypes } from '../models/TrackData';
import LinearGradient from './LinearGradient';
import { usePlayerContext } from '../contexts/PlayerContext';
import usePlayerSelectors from '../hooks/usePlayerSelectors';
import { theme } from '../contexts/AppTheme';

interface Props {
  track: TrackData;
  trackType: TrackType;
}

const TrackProgress: React.FC<Props> = ({ track, trackType }) => {
  const { playerState } = usePlayerContext();
  const { getPlayerProgress } = usePlayerSelectors();

  const playerProgress = React.useMemo(() => getPlayerProgress(playerState), [
    getPlayerProgress,
    playerState,
  ]);

  const renderTrackData = React.useCallback<(color?: string) => JSX.Element>(
    (color) => (
      <Box
        display="flex"
        sx={{
          whiteSpace: 'nowrap',
          position: 'absolute',
          top: 0,
          right: 12,
          bottom: 0,
          left: 12,
        }}
      >
        <Box display="flex" style={{ alignItems: 'center' }}>
          <Text color={color} fontWeight="bold" fontSize={2} lineHeight={1}>
            {formatDuration(R.pathOr(0, ['audio', 'duration'])(track))}
          </Text>
          <Text
            color={R.defaultTo('grayDark', color)}
            fontSize={1}
            lineHeight={0}
            sx={{
              position: 'absolute',
              left: 12,
            }}
          >
            {R.prop('name')(track)}
          </Text>
        </Box>
      </Box>
    ),
    [track]
  );

  return (
    <ProgressBar
      bg={theme.colors.white}
      sectionsNum={1}
      progressPercent={0}
      height={0}
      sx={{ borderRadius: 0 }}
      style={{ position: 'relative', justifyContent: 'initial', flex: 1 }}
    >
      {renderTrackData()}
      {R.equals(trackType, TrackTypes.CURRENT) && !R.isNil(playerProgress) && (
        <LinearGradient
          gradientWidth={`${R.multiply(R.prop('played')(playerProgress))(
            100
          )}%`}
          flex={1}
          opacity={1}
          style={{
            position: 'relative',
            background: theme.colors.primary,
            transition: 'max-width .1s linear',
          }}
        >
          <Box
            overflow="hidden"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            {renderTrackData('white')}
          </Box>
        </LinearGradient>
      )}
    </ProgressBar>
  );
};

export default React.memo(TrackProgress);
