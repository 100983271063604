import React from 'react';
import * as R from 'ramda';
import { FirebaseActions } from '../actions/actionTypes';
import { Course } from '../../models/Course';
import { TrackData } from '../../models/TrackData';
import { AuthUser } from '../../models/AuthUser';
import { UserData } from '../../models/UserData';
import { CourseCovers } from '../../models/CourseCovers';
import { Question } from '../../models/Question';
import { TrackPreviews } from '../../models/TrackPreviews';
import { TrackAudios } from '../../models/TrackAudios';
import { TrackVideos } from '../../models/TrackVideos';
import { CourseDocuments } from '../../models/CourseDocuments';
import { QuizQuestion } from '../../models/QuizQuestion';
import { QuizAnswer } from '../../models/QuizAnswer';
import { AnswerType } from '../../models/DetailedCourse';
import { initFirebaseState } from '../../contexts/FirebaseContext';

export type FirebaseAction =
  | {
      type: typeof FirebaseActions.RESET_STATE;
    }
  | {
      type: typeof FirebaseActions.LOAD_COURSES;
      payload: Course[];
    }
  | {
      type: typeof FirebaseActions.LOAD_COURSES_COVERS;
      payload: CourseCovers;
    }
  | {
      type: typeof FirebaseActions.LOAD_TRACKS;
      payload: TrackData[];
    }
  | {
      type: typeof FirebaseActions.LOAD_COURSE_DOCUMENTS;
      payload: CourseDocuments;
    }
  | {
      type: typeof FirebaseActions.LOAD_TRACKS_PREVIEWS;
      payload: TrackPreviews;
    }
  | {
      type: typeof FirebaseActions.LOAD_TRACKS_AUDIOS;
      payload: TrackAudios;
    }
  | {
      type: typeof FirebaseActions.LOAD_TRACKS_VIDEOS;
      payload: TrackVideos;
    }
  | {
      type: typeof FirebaseActions.LOAD_QUESTIONS;
      payload: Question[];
    }
  | {
      type: typeof FirebaseActions.LOAD_QUIZ_QUESTIONS;
      payload: QuizQuestion[];
    }
  | {
      type: typeof FirebaseActions.LOAD_QUIZ_ANSWERS;
      payload: QuizAnswer[];
    }
  | {
      type: typeof FirebaseActions.LOAD_AUTH_USER;
      payload: AuthUser | null;
    }
  | {
      type: typeof FirebaseActions.LOAD_USER_DATA;
      payload: UserData | null;
    }
  | {
      type: typeof FirebaseActions.SET_CURRENT_TRACK;
      payload: {
        courseId: string;
        weekId: number;
        currentTrackId: string;
      };
    }
  | {
      type: typeof FirebaseActions.INCREASE_TIME_SPENT;
      payload: {
        courseId: string;
        weekId: number;
        increment: number;
      };
    }
  | {
      type: typeof FirebaseActions.SET_TIME_STARTED;
      payload: {
        courseId: string;
        weekId: number;
        timeStarted: number;
      };
    }
  | {
      type: typeof FirebaseActions.SET_TIME_FINISHED;
      payload: {
        courseId: string;
        weekId: number;
        timeFinished: number;
      };
    }
  | {
      type: typeof FirebaseActions.SET_FINISHED_WORKOUT;
      payload: {
        courseId: string;
        weekId: number;
      };
    }
  | {
      type: typeof FirebaseActions.SET_FINISHED_QUIZ_QUESTION;
      payload: {
        courseId: string;
        weekId: number;
        questionId: string;
      };
    }
  | {
      type: typeof FirebaseActions.SET_USER_ANSWER;
      payload: {
        questionId: string;
        weekId: number;
        type: AnswerType;
        answer: number;
      };
    }
  | {
      type: typeof FirebaseActions.SET_IS_TRACK_FINISHED;
      payload: {
        courseId: string;
        weekId: number;
        trackId: string;
        isFinished: boolean;
      };
    }
  | {
      type:
        | typeof FirebaseActions.SEND_SIGN_IN_LINK_SUCCESS
        | typeof FirebaseActions.HANDLE_SIGN_IN_LINK_SUCCESS;
    }
  | {
      type:
        | typeof FirebaseActions.HANDLE_SEND_SIGN_IN_LINK_ERROR
        | typeof FirebaseActions.HANDLE_SIGN_IN_LINK_ERROR
        | typeof FirebaseActions.HANDLE_FIRESTORE_ERROR;
      payload: unknown;
    }
  | {
      type:
        | typeof FirebaseActions.IS_AUTH_LOADING
        | typeof FirebaseActions.IS_SUBMIT_LOADING
        | typeof FirebaseActions.IS_FIRESTORE_LOADING;
      payload: boolean;
    };

export interface FirebaseState {
  courses: Course[];
  coursesCovers: CourseCovers;
  tracks: TrackData[];
  courseDocuments: CourseDocuments;
  tracksPreviews: TrackPreviews;
  tracksAudios: TrackAudios;
  tracksVideos: TrackVideos;
  questions: Question[];
  quizQuestions: QuizQuestion[];
  quizAnswers: QuizAnswer[];
  authUser: AuthUser | null;
  userData: UserData | null;
  error: unknown | null;
  isAuthLoading: boolean;
  isSubmitLoading: boolean;
  isFirestoreLoading: boolean;
}

const firebaseReducer: React.Reducer<FirebaseState, FirebaseAction> = (
  prevState,
  action,
): FirebaseState => {
  switch (action.type) {
    case FirebaseActions.RESET_STATE:
      return initFirebaseState;

    case FirebaseActions.LOAD_COURSES:
      return R.assoc('courses', action.payload)(prevState);

    case FirebaseActions.LOAD_COURSES_COVERS:
      return R.assoc('coursesCovers', action.payload)(prevState);

    case FirebaseActions.LOAD_TRACKS:
      return R.assoc('tracks', action.payload)(prevState);

    case FirebaseActions.LOAD_COURSE_DOCUMENTS:
      return R.assoc('courseDocuments', action.payload)(prevState);

    case FirebaseActions.LOAD_TRACKS_PREVIEWS:
      return R.assoc('tracksPreviews', action.payload)(prevState);

    case FirebaseActions.LOAD_TRACKS_AUDIOS:
      return R.assoc('tracksAudios', action.payload)(prevState);

    case FirebaseActions.LOAD_TRACKS_VIDEOS:
      return R.assoc('tracksVideos', action.payload)(prevState);

    case FirebaseActions.LOAD_QUESTIONS:
      return R.assoc('questions', action.payload)(prevState);

    case FirebaseActions.LOAD_QUIZ_QUESTIONS:
      return R.assoc('quizQuestions', action.payload)(prevState);

    case FirebaseActions.LOAD_QUIZ_ANSWERS:
      return R.assoc('quizAnswers', action.payload)(prevState);

    case FirebaseActions.LOAD_AUTH_USER:
      return R.assoc('authUser', action.payload)(prevState);

    case FirebaseActions.LOAD_USER_DATA:
      // AWFUL CODE, SORRY
      // we need load_user_data only for initial render. Unfortunately, when we sign in for the first time
      // and create the account for the user, it takes some time for functions to assign courses
      // due to this the user will see all the courses not available.
      // to fix this, we added the onSnapshot for the user fields, however it works on every update
      // and breaks all the other logic. To prevent this, we use this workaround, when we save the data
      // only when we have no progress
      return prevState.userData?.progress
        ? prevState
        : R.assoc('userData', action.payload)(prevState);

    case FirebaseActions.SET_CURRENT_TRACK:
      return R.assocPath<string, FirebaseState>(
        [
          'userData',
          'progress',
          action.payload.courseId,
          action.payload.weekId.toString(),
          'currentTrack',
        ],
        action.payload.currentTrackId,
      )(prevState);

    case FirebaseActions.INCREASE_TIME_SPENT:
      return R.assocPath<number, FirebaseState>(
        [
          'userData',
          'progress',
          action.payload.courseId,
          action.payload.weekId.toString(),
          'timeSpent',
        ],
        R.defaultTo(
          0,
          prevState.userData?.progress?.[action.payload.courseId]?.[
            action.payload.weekId
          ]?.timeSpent,
        ) + action.payload.increment,
      )(prevState);

    case FirebaseActions.SET_TIME_STARTED:
      return R.assocPath<number, FirebaseState>(
        [
          'userData',
          'progress',
          action.payload.courseId,
          action.payload.weekId.toString(),
          'timeStarted',
        ],
        action.payload.timeStarted,
      )(prevState);

    case FirebaseActions.SET_TIME_FINISHED:
      return R.assocPath<number, FirebaseState>(
        [
          'userData',
          'progress',
          action.payload.courseId,
          action.payload.weekId.toString(),
          'timeFinished',
        ],
        action.payload.timeFinished,
      )(prevState);

    case FirebaseActions.SET_FINISHED_WORKOUT:
      return R.assocPath<boolean, FirebaseState>(
        [
          'userData',
          'finishedWorkouts',
          action.payload.courseId,
          action.payload.weekId.toString(),
        ],
        true,
      )(prevState);

    case FirebaseActions.SET_FINISHED_QUIZ_QUESTION:
      return R.assocPath<boolean, FirebaseState>(
        [
          'userData',
          'finishedQuizQuestions',
          action.payload.courseId,
          action.payload.weekId.toString(),
          action.payload.questionId,
        ],
        true,
      )(prevState);

    case FirebaseActions.SET_USER_ANSWER:
      return R.assocPath<number, FirebaseState>(
        [
          'userData',
          'answers',
          action.payload.questionId,
          action.payload.weekId.toString(),
          action.payload.type,
        ],
        action.payload.answer,
      )(prevState);

    case FirebaseActions.SET_IS_TRACK_FINISHED:
      return R.assocPath<boolean, FirebaseState>(
        [
          'userData',
          'finishedTracks',
          action.payload.courseId,
          action.payload.weekId.toString(),
          action.payload.trackId,
        ],
        action.payload.isFinished,
      )(prevState);

    case FirebaseActions.SEND_SIGN_IN_LINK_SUCCESS:
    case FirebaseActions.HANDLE_SIGN_IN_LINK_SUCCESS:
      return R.assoc('error', null)(prevState);

    case FirebaseActions.HANDLE_SEND_SIGN_IN_LINK_ERROR:
    case FirebaseActions.HANDLE_SIGN_IN_LINK_ERROR:
    case FirebaseActions.HANDLE_FIRESTORE_ERROR:
      return R.assoc('error', action.payload)(prevState);

    case FirebaseActions.IS_AUTH_LOADING:
      return R.assoc('isAuthLoading', action.payload)(prevState);

    case FirebaseActions.IS_SUBMIT_LOADING:
      return R.assoc('isSubmitLoading', action.payload)(prevState);

    case FirebaseActions.IS_FIRESTORE_LOADING:
      return R.assoc('isFirestoreLoading', action.payload)(prevState);

    default:
      return prevState;
  }
};

export default firebaseReducer;
