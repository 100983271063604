import React from 'react';
import { Box, Button, Text } from 'rebass';
import Slider from 'react-slick';
import * as R from 'ramda';
import { Question } from '../models/Question';
import strings from '../theme/strings';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import QuizQuestionSummary from './QuizQuestionSummary';
import { QuizQuestion } from '../models/QuizQuestion';
import QuestionSummary from './QuestionSummary';
import useQuestionTest from '../hooks/useQuestionTest';

export const DEFAULT_SLIDE_REBASS_STYLE = {
  ':focus': {
    outline: 'none',
  },
};

const QuestionTest: React.FC = () => {
  const {
    currentAfterAnswer,
    isAnswerWrong,
    isQuizCompleted,
    sliderRef,
    quizQuestions,
    afterQuestions,
    handleQuizAnswerChange,
    handleAnswerInputChange,
    handleSlideChange,
    handleCancelClick,
    handleSubmitClick,
  } = useQuestionTest();

  const [firstIsQuizCompleted, setFirstIsQuizCompleted] = React.useState<
    boolean
  >();

  // we want to avoid the situation, when the quiz is completed inside the slider, and the slider disappear.
  // that's why we only track the first value
  React.useEffect(() => {
    setFirstIsQuizCompleted((prev) =>
      prev === false ? false : isQuizCompleted
    );
  }, [isQuizCompleted]);

  const renderQuizQuestion = React.useCallback<
    (quizQuestion: QuizQuestion) => JSX.Element | null
  >(
    (quizQuestion) => (
      <QuizQuestionSummary
        key={R.prop('id')(quizQuestion)}
        quizQuestion={quizQuestion}
        handleQuizAnswerChange={handleQuizAnswerChange}
      />
    ),
    [handleQuizAnswerChange]
  );

  const renderQuestion = React.useCallback<
    (question: Question) => JSX.Element | null
  >(
    (question) => (
      <QuestionSummary
        key={R.prop('id')(question)}
        question={question}
        inputValue={currentAfterAnswer}
        handleInputChange={handleAnswerInputChange}
      />
    ),
    [currentAfterAnswer, handleAnswerInputChange]
  );

  const renderSlider = React.useCallback(
    () => (
      <Slider
        ref={sliderRef}
        slidesToShow={1}
        slidesToScroll={1}
        infinite={false}
        draggable={false}
        adaptiveHeight
        beforeChange={handleSlideChange}
      >
        {!R.isNil(quizQuestions) &&
          !firstIsQuizCompleted &&
          R.addIndex<QuizQuestion, JSX.Element | null>(R.map)(
            renderQuizQuestion,
            quizQuestions
          )}
        {!R.isNil(afterQuestions) &&
          R.addIndex<Question, JSX.Element | null>(R.map)(
            renderQuestion,
            afterQuestions
          )}
      </Slider>
    ),
    [
      afterQuestions,
      handleSlideChange,
      firstIsQuizCompleted,
      quizQuestions,
      renderQuestion,
      renderQuizQuestion,
      sliderRef,
    ]
  );

  const renderNotification = React.useCallback(
    () =>
      isAnswerWrong && (
        <Text color="error" textAlign="center" fontSize={1} mb={5}>
          {strings.modals.questionTest.wrongQuizAnswer}
        </Text>
      ),
    [isAnswerWrong]
  );

  const renderSliderBottom = React.useCallback(
    () => (
      <Box
        display="flex"
        sx={{
          justifyContent: 'center',
        }}
      >
        <Button onClick={handleCancelClick} variant="formGrayButton">
          <Text color="white" fontSize={1} lineHeight={0}>
            {strings.other.cancelButton}
          </Text>
        </Button>
        <Button onClick={handleSubmitClick} variant="formBlueButton" ml={5}>
          <Text color="white" fontSize={1} lineHeight={0}>
            {strings.modals.questionTest.submitButton}
          </Text>
        </Button>
      </Box>
    ),
    [handleCancelClick, handleSubmitClick]
  );

  return (
    <Box bg="white" maxWidth={10} p={9} sx={{ borderRadius: 3 }}>
      {renderSlider()}
      <Box mt={8}>
        {renderNotification()}
        {renderSliderBottom()}
      </Box>
    </Box>
  );
};

export default React.memo(QuestionTest);
