import * as React from 'react';
import { theme } from '../../contexts/AppTheme';

const TickIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 12 10" fill="none" {...props}>
    <path d="M1 5l4 3 6-7" stroke={theme.colors.completed} strokeWidth={2} />
  </svg>
);

export default React.memo(TickIcon);
