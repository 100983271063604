import React from 'react';
import { Button, Text } from 'rebass';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import AccountIcon from '../icons/AccountIcon';
import strings from '../../theme/strings';
import { useFirebaseContext } from '../../contexts/FirebaseContext';
import { Routes } from '../../navigation/routes';
import { LINK_DEFAULT_STYLE } from '../../theme/constants';
import { theme } from '../../contexts/AppTheme';
import useUserSelectors from '../../hooks/useUserSelectors';

const AccountButton: React.FC = () => {
  const { firebaseState } = useFirebaseContext();
  const { getAuthUser } = useUserSelectors();

  const authUser = React.useMemo(
    () => getAuthUser(firebaseState),
    [firebaseState, getAuthUser],
  );

  const renderButton = React.useCallback(
    () => (
      <Button
        variant="rounded"
        display="flex"
        pt={[2, 4]}
        pb={[2, 4]}
        style={{ alignItems: 'center', border: 'none' }}
      >
        <AccountIcon
          width="2.2em"
          stroke={theme.colors.white}
          fill={theme.colors.primary}
        />
        <Text
          fontWeight="bold"
          color="white"
          fontSize={1}
          lineHeight={2}
          ml={[4, 7, 8]}
          mr={[4, 7, 8]}
        >
          {strings.header.accountButton}
        </Text>
      </Button>
    ),
    [],
  );

  const renderLink = React.useCallback(
    () => (
      <Link to={Routes.SIGN_IN} style={LINK_DEFAULT_STYLE}>
        {renderButton()}
      </Link>
    ),
    [renderButton],
  );

  return R.ifElse(R.isNil, renderLink, renderButton)(authUser);
};

export default React.memo(AccountButton);
