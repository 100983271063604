import React from 'react';
import { Box, Button, Text } from 'rebass';
import { useParams, useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import * as R from 'ramda';
import strings from '../theme/strings';
import { routeLinks } from '../navigation/routes';
import { theme } from '../contexts/AppTheme';

interface RouteParams {
  courseId: string | undefined;
}

const QuizNotCompleted: React.FC = () => {
  const history = useHistory();
  const { courseId } = useParams<RouteParams>();
  const prevWeekParam = useSearchParam('prevWeek');

  const prevWeekId = React.useMemo(
    () => R.unless(R.isNil, (week) => parseInt(week, 10))(prevWeekParam),
    [prevWeekParam]
  );

  const handleButtonClick = React.useCallback(
    () =>
      !R.isNil(courseId) &&
      !R.isNil(prevWeekId) &&
      history.replace(routeLinks.getWeekLink(courseId, prevWeekId)),
    [courseId, history, prevWeekId]
  );

  return !R.isNil(courseId) && !R.isNil(prevWeekId) ? (
    <Box
      display="flex"
      bg={theme.colors.white}
      p={9}
      sx={{ flexDirection: 'column', borderRadius: 3 }}
    >
      <Text textAlign="center" maxWidth={7}>
        <Text fontWeight="bold" fontSize={3} lineHeight={2} mb={8}>
          {strings.modals.quizNotCompleted.heading}
        </Text>
        <Text fontSize={1} lineHeight={0} mb={8}>
          {strings.modals.quizNotCompleted.details}
        </Text>
      </Text>
      <Box display="inline-grid" mb={5}>
        <Button variant="formBlueButton" onClick={handleButtonClick}>
          {strings.modals.quizNotCompleted.prevWeekQuizLink}
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default React.memo(QuizNotCompleted);
