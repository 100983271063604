import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import * as R from 'ramda';
import { ModalRoutes, Routes } from './routes';
import MainPage from '../pages/MainPage';
import EmailLinkHandler from '../pages/EmailLinkHandler';

const HomeNavigator: React.FC = () => {
  const renderRouteContent = React.useCallback<
    (props: RouteComponentProps) => JSX.Element
  >(
    ({ location }) =>
      location.pathname === Routes.HOME ||
      R.includes(location.pathname, R.values(ModalRoutes)) ? (
        <MainPage />
      ) : (
        <EmailLinkHandler />
      ),
    [],
  );

  return <Route path={Routes.HOME} render={renderRouteContent} />;
};

export default HomeNavigator;
