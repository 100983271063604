import React, { useCallback, useMemo } from 'react';
import { Box } from 'rebass';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { usePlayerContext } from '../contexts/PlayerContext';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserSelectors from '../hooks/useUserSelectors';
import useStopwatchIncrease from '../hooks/useStopwatchIncrease';
import { isTrackListeningRestricted } from '../env';

interface Props extends ReactPlayerProps {
  courseId: string;
  weekId: number;
  trackAudio: string | null | undefined;
  isPlaying: boolean;
}

const TrackAudioPlayer: React.FC<Props> = ({
  courseId,
  weekId,
  trackAudio,
  isPlaying,
  ...props
}) => {
  const { setIsAudioReady, setPlayerProgress, clearPlayer } =
    usePlayerContext();

  const { firebaseState, setCurrentTrack, setIsTrackFinished } =
    useFirebaseContext();

  const { getCurrentTrackId, getNextTrackId, getIsTrackFinished } =
    useUserSelectors();
  const { startStopwatch, stopStopwatch } = useStopwatchIncrease();

  const currentTrackId = useMemo(
    () => getCurrentTrackId(firebaseState, courseId, weekId),
    [courseId, firebaseState, getCurrentTrackId, weekId],
  );

  const nextTrackId = React.useMemo(
    () => getNextTrackId(firebaseState, courseId, weekId),
    [courseId, firebaseState, getNextTrackId, weekId],
  );

  const isCurrentTrackFinished = useMemo(
    () =>
      !!currentTrackId &&
      getIsTrackFinished(firebaseState, courseId, weekId, currentTrackId),
    [courseId, currentTrackId, firebaseState, getIsTrackFinished, weekId],
  );

  const handleAudioReady = React.useCallback(
    () => setIsAudioReady(true),
    [setIsAudioReady],
  );

  const handleAudioStarted = useCallback(() => {
    if (!currentTrackId) return;

    const isFinishedOnStart = !(
      isTrackListeningRestricted && !isCurrentTrackFinished
    );
    setIsTrackFinished(courseId, weekId, currentTrackId, isFinishedOnStart);
  }, [
    courseId,
    currentTrackId,
    isCurrentTrackFinished,
    setIsTrackFinished,
    weekId,
  ]);

  const handleAudioEnded = React.useCallback(() => {
    if (!(currentTrackId && nextTrackId)) return;

    stopStopwatch();
    clearPlayer();

    if (isTrackListeningRestricted)
      setIsTrackFinished(courseId, weekId, currentTrackId, true);
    setCurrentTrack(courseId, weekId, nextTrackId);
    startStopwatch(courseId, weekId);
  }, [
    clearPlayer,
    courseId,
    currentTrackId,
    nextTrackId,
    setCurrentTrack,
    setIsTrackFinished,
    startStopwatch,
    stopStopwatch,
    weekId,
  ]);

  return trackAudio ? (
    <Box display="none">
      <ReactPlayer
        playing={isPlaying}
        playsinline
        progressInterval={100}
        onReady={handleAudioReady}
        onProgress={setPlayerProgress}
        onEnded={handleAudioEnded}
        onStart={handleAudioStarted}
        stopOnUnmount={false}
        url={trackAudio}
        {...props}
      />
    </Box>
  ) : null;
};

export default React.memo(TrackAudioPlayer);
