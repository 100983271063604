/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { isNil } from 'ramda';
import { FirebaseState } from '../store/reducers/firebaseReducer';
import {
  CourseFinishedQuizQuestions,
  CourseFinishedWeeks,
  FinishedQuizQuestions,
  UserAnswers,
  UserCourseData,
  UserCourseProgress,
  UserCourses,
  UserData,
  WeekFinishedQuizQuestions,
  WeekFinishedTracks,
  WeekProgress,
} from '../models/UserData';
import { AuthUser } from '../models/AuthUser';
import useTrackSelectors from './useTrackSelectors';
import { TrackData, TrackType, TrackTypes } from '../models/TrackData';
import useQuestionSelectors from './useQuestionSelectors';
import { isAllContentAvailable } from '../utils';
import { isTrackListeningRestricted } from '../env';

const WEEK_ID_DEFAULT = 1;

const useUserSelectors = () => {
  const { getWeekQuizQuestions, getIsQuizExists } = useQuestionSelectors();
  const { getWeekTrack, getWeekTracks } = useTrackSelectors();

  const getAuthUser = React.useCallback<
    (state: FirebaseState) => AuthUser | null
  >(R.prop('authUser'), []);

  const getIsAuthLoading = React.useCallback<(state: FirebaseState) => boolean>(
    R.prop('isAuthLoading'),
    [],
  );

  const getUserData = React.useCallback<
    (state: FirebaseState) => UserData | null
  >(R.prop('userData'), []);

  const getUserCourses = React.useCallback<
    (state: FirebaseState) => UserCourses | undefined
  >((state) => R.propOr(null, 'courses')(getUserData(state)), [getUserData]);

  const getUserCourseData = React.useCallback<
    (state: FirebaseState, courseId: string) => UserCourseData | null
  >(
    (state, courseId) => R.propOr(null, courseId)(getUserCourses(state)),
    [getUserCourses],
  );

  const getIsCourseAvailable = React.useCallback<
    (state: FirebaseState, courseId: string) => boolean
  >(
    (state, courseId) => {
      const authUser = getAuthUser(state);
      const allContentAvailable = isAllContentAvailable();

      if (!authUser?.email) return false;
      if (allContentAvailable) return true;

      const userCourse = getUserCourseData(state, courseId);

      return !R.isNil(userCourse)
        ? R.propSatisfies<number, UserCourseData>(
            (expiration) => R.gt(expiration, moment().valueOf()),
            'expiration',
            userCourse,
          )
        : false;
    },
    [getUserCourseData],
  );

  const getFinishedWeeks = React.useCallback<
    (state: FirebaseState, courseId: string) => CourseFinishedWeeks | null
  >(
    (state, courseId) =>
      R.pathOr(null, ['finishedWorkouts', courseId], getUserData(state)),
    [getUserData],
  );

  const getWeekFinishedTracks = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
    ) => WeekFinishedTracks | null
  >(
    (state, courseId, weekId) =>
      R.pathOr(
        null,
        ['finishedTracks', courseId, weekId.toString()],
        getUserData(state),
      ),
    [],
  );

  const getIsWeekFinished = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => boolean
  >(
    (state, courseId, weekId) =>
      R.propOr(false, weekId.toString(), getFinishedWeeks(state, courseId)),
    [getFinishedWeeks],
  );

  const getIsTrackFinished = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
      trackId: string,
    ) => boolean | null
  >(
    (state, courseId, weekId, trackId) =>
      R.propOr(null, trackId, getWeekFinishedTracks(state, courseId, weekId)),
    [],
  );

  const getIsAllTracksFinished = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => boolean
  >((state, courseId, weekId) => {
    const weekTracks = getWeekTracks(state, weekId);
    const weekFinishedTracks = getWeekFinishedTracks(state, courseId, weekId);

    return (
      !R.isNil(weekFinishedTracks) &&
      weekTracks.length === R.keys(weekFinishedTracks).length
    );
  }, []);

  const getFinishedQuizQuestions = React.useCallback<
    (state: FirebaseState) => FinishedQuizQuestions | null
  >(
    (state) => R.propOr(null, 'finishedQuizQuestions', getUserData(state)),
    [getUserData],
  );

  const getCourseFinishedQuizQuestions = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
    ) => CourseFinishedQuizQuestions | null
  >(
    (state, courseId) =>
      R.propOr(null, courseId, getFinishedQuizQuestions(state)),
    [getFinishedQuizQuestions],
  );

  const getWeekFinishedQuizQuestions = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
    ) => WeekFinishedQuizQuestions | undefined | null
  >(
    (state, courseId, weekId) =>
      R.propOr(
        null,
        weekId.toString(),
        getCourseFinishedQuizQuestions(state, courseId),
      ),
    [getCourseFinishedQuizQuestions],
  );

  const getIsQuizCompleted = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => boolean
  >(
    (state, courseId, weekId) => {
      const weekQuizQuestions = getWeekQuizQuestions(state, courseId, weekId);
      const weekFinishedQuizQuestions = getWeekFinishedQuizQuestions(
        state,
        courseId,
        weekId,
      );
      return !R.isNil(weekQuizQuestions) &&
        !R.isNil(weekFinishedQuizQuestions) &&
        !R.isEmpty(weekFinishedQuizQuestions)
        ? R.length(weekQuizQuestions) ===
            R.length(R.keys(weekFinishedQuizQuestions))
        : false;
    },
    [getWeekFinishedQuizQuestions, getWeekQuizQuestions],
  );

  const getUserProgress = React.useCallback<
    (state: FirebaseState, courseId: string) => UserCourseProgress | null
  >(
    (state, courseId) =>
      R.pathOr(null, ['progress', courseId], getUserData(state)),
    [getUserData],
  );

  const getUserWeekProgress = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
    ) => WeekProgress | undefined
  >(
    (state, courseId, weekId) =>
      R.propOr(null, weekId.toString(), getUserProgress(state, courseId)),
    [getUserProgress],
  );

  const getCurrentTrackId = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => string | null
  >(
    (state, courseId, weekId) => {
      const userWeekProgress = getUserWeekProgress(state, courseId, weekId);
      const weekTracks = getWeekTracks(state, weekId);

      return !R.isNil(userWeekProgress)
        ? R.propOr(
            R.propOr(null, 'id', R.head(weekTracks)),
            'currentTrack',
          )(userWeekProgress)
        : R.propOr(null, 'id', R.head(weekTracks));
    },
    [getUserWeekProgress, getWeekTracks],
  );

  const getNextTrackId = React.useCallback<
    (
      firebaseState: FirebaseState,
      courseId: string,
      weekId: number,
    ) => string | null
  >(
    (firebaseState, courseId, weekId) => {
      const tracks = getWeekTracks(firebaseState, weekId);

      const currentTrackId = getCurrentTrackId(firebaseState, courseId, weekId);

      const currentTrackIndex = !R.isNil(currentTrackId)
        ? R.findIndex(R.propEq('id', currentTrackId), tracks)
        : null;

      const nextTrack =
        !R.isNil(currentTrackIndex) &&
        currentTrackIndex !== -1 &&
        R.nth(R.inc(currentTrackIndex), tracks);

      return R.propOr(null, 'id', nextTrack);
    },
    [getCurrentTrackId, getWeekTracks],
  );

  const getTimeSpent = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
    ) => number | undefined
  >(
    (state, courseId, weekId) =>
      R.propOr(null, 'timeSpent', getUserWeekProgress(state, courseId, weekId)),
    [getUserWeekProgress],
  );

  const getIsUserDemo = React.useCallback<
    (state: FirebaseState) => boolean | null
  >(
    R.pipe<FirebaseState, UserData | null, boolean | null>(
      getUserData,
      R.propOr(null, 'isDemo'),
    ),
    [],
  );

  const getTimeStarted = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
    ) => number | undefined
  >(
    (state, courseId, weekId) =>
      R.propOr(
        null,
        'timeStarted',
        getUserWeekProgress(state, courseId, weekId),
      ),
    [getUserWeekProgress],
  );

  const getTimeFinished = React.useCallback<
    (
      state: FirebaseState,
      courseId: string,
      weekId: number,
    ) => number | undefined
  >(
    (state, courseId, weekId) =>
      R.propOr(
        null,
        'timeFinished',
        getUserWeekProgress(state, courseId, weekId),
      ),
    [getUserWeekProgress],
  );

  const getIsWeekOpen = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => boolean
  >(
    (state, courseId, weekId) => {
      const allContentAvailable = isAllContentAvailable();
      const isCurrentWeekFinished = getIsWeekFinished(state, courseId, weekId);
      const isUserDemo = getIsUserDemo(state);

      if (allContentAvailable) return true;
      if (isUserDemo) return true;
      if (isCurrentWeekFinished) return true;

      const isPreviousWeekFinished = getIsWeekFinished(
        state,
        courseId,
        R.dec(weekId),
      );

      const timeStarted = getTimeStarted(state, courseId, weekId);

      const isWorkoutCalendarWeek =
        !R.isNil(timeStarted) && moment().valueOf() > timeStarted;

      return (
        (isPreviousWeekFinished && isWorkoutCalendarWeek) ||
        R.equals(weekId)(WEEK_ID_DEFAULT)
      );
    },
    [getIsWeekFinished, getTimeStarted, getIsUserDemo],
  );

  const getIsWeekAvailable = React.useCallback<
    (state: FirebaseState, courseId: string, weekId: number) => boolean
  >((state, courseId, weekId) => {
    const allContentAvailable = isAllContentAvailable();

    if (allContentAvailable) {
      return true;
    }

    const isWeekOpen = getIsWeekOpen(state, courseId, weekId);

    const isPrevWeekQuizExists = getIsQuizExists(
      state,
      courseId,
      R.dec(weekId),
    );

    const isPrevWeekQuizCompleted = getIsQuizCompleted(
      state,
      courseId,
      R.dec(weekId),
    );

    return isWeekOpen
      ? isPrevWeekQuizExists
        ? isPrevWeekQuizCompleted
        : true
      : false;
  }, []);

  const getTrackType = React.useCallback<
    (
      firebaseState: FirebaseState,
      courseId: string,
      weekId: number,
      track: TrackData,
    ) => TrackType
  >(
    (firebaseState, courseId, weekId, track) => {
      const currentTrackId = getCurrentTrackId(firebaseState, courseId, weekId);
      const isCurrentTrackFinished = getIsTrackFinished(
        firebaseState,
        courseId,
        weekId,
        track.id,
      );

      if (track.id === currentTrackId) return TrackTypes.CURRENT;
      if (isTrackListeningRestricted && isNil(isCurrentTrackFinished))
        return TrackTypes.BLOCKED;
      if (isCurrentTrackFinished) return TrackTypes.COMPLETED;

      return TrackTypes.LEFT;
    },
    [getCurrentTrackId, getWeekTrack],
  );

  const getUserAnswers = React.useCallback<
    (state: FirebaseState) => UserAnswers | null
  >((state) => R.propOr(null, 'answers', getUserData(state)), [getUserData]);

  return {
    getAuthUser,
    getIsAuthLoading,
    getUserData,
    getUserCourseData,
    getIsCourseAvailable,
    getUserAnswers,
    getIsWeekFinished,
    getFinishedWeeks,
    getWeekFinishedTracks,
    getCourseFinishedQuizQuestions,
    getWeekFinishedQuizQuestions,
    getIsQuizCompleted,
    getIsWeekOpen,
    getIsWeekAvailable,
    getIsTrackFinished,
    getIsAllTracksFinished,
    getUserWeekProgress,
    getCurrentTrackId,
    getTimeSpent,
    getTimeStarted,
    getTimeFinished,
    getNextTrackId,
    getTrackType,
  };
};

export default useUserSelectors;
