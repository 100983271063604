import { SxStyleProp } from 'rebass';

export const BUTTON_DEFAULT_STYLES: SxStyleProp = {
  cursor: 'pointer',
  border: 'none',
  ':focus': {
    outline: 'unset',
    borderColor: 'grayDark',
  },
};

const FORM_BUTTON_DEFAULT_STYLES: SxStyleProp = {
  borderRadius: 1,
};

const buttons = {
  buttons: {
    rounded: {
      ...BUTTON_DEFAULT_STYLES,
      borderRadius: 6,
      padding: [3, 4],
    },
    square: {
      ...BUTTON_DEFAULT_STYLES,
      borderRadius: 0,
      padding: [3, 4],
    },
    formGrayButton: {
      ...BUTTON_DEFAULT_STYLES,
      ...FORM_BUTTON_DEFAULT_STYLES,
      background: 'linear-gradient(180deg, #959595 0%, #868686 100%)',
      boxShadow: 1,
      padding: 5,
    },
    formBlueButton: {
      ...BUTTON_DEFAULT_STYLES,
      ...FORM_BUTTON_DEFAULT_STYLES,
      background: 'linear-gradient(180deg, #4DB6F9 0%, #279BE5 100%)',
      boxShadow: 0,
      padding: 5,
    },
    unstyled: {
      ...BUTTON_DEFAULT_STYLES,
      border: 'none',
      background: 'none',
      display: 'flex',
      alignItems: 'center',
      padding: 0,
    },
  },
};

export default buttons;
