const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: window.location.origin,
  // This must be true.
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.firsttracks.fittracks',
  // },
  // android: {
  //   packageName: 'com.fittracks',
  //   installApp: true,
  //   minimumVersion: '12',
  // },
};

export default actionCodeSettings;
