import React from 'react';
import { theme } from '../../contexts/AppTheme';

const PlayIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <circle
      cx={16}
      cy={16}
      r={15}
      stroke={theme.colors.white}
      strokeWidth={2}
    />
    <path d="M21 16l-7.5 4.33v-8.66L21 16z" fill={theme.colors.white} />
  </svg>
);

export default React.memo(PlayIcon);
