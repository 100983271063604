import React from 'react';
import * as R from 'ramda';
import { PlayerActions } from '../actions/actionTypes';
import { PlayerProgress } from '../../models/PlayerProgress';
import { initPlayerState } from '../../contexts/PlayerContext';

export type PlayerAction =
  | {
      type:
        | typeof PlayerActions.SET_IS_PLAYING
        | typeof PlayerActions.SET_IS_CONTROLS_VISIBLE
        | typeof PlayerActions.SET_IS_AUDIO_READY
        | typeof PlayerActions.SET_IS_VIDEO_READY
        | typeof PlayerActions.SET_IS_IMAGE_READY;
      payload: boolean;
    }
  | {
      type: typeof PlayerActions.SET_PLAYER_PROGRESS;
      payload: PlayerProgress;
    }
  | {
      type: typeof PlayerActions.INCREMENT_PLAYER_TIME_SPENT;
      payload: number;
    }
  | {
      type:
        | typeof PlayerActions.CLEAR_PLAYER
        | typeof PlayerActions.CLEAR_TIME_SPENT;
    };

export interface PlayerState {
  isPlaying: boolean;
  isControlsVisible: boolean;
  isAudioReady: boolean;
  isVideoReady: boolean;
  isImageReady: boolean;
  playerProgress: PlayerProgress | null;
  playerTimeSpent: number;
}

const playerReducer: React.Reducer<PlayerState, PlayerAction> = (
  prevState,
  action,
): PlayerState => {
  switch (action.type) {
    case PlayerActions.SET_IS_PLAYING:
      return R.assoc('isPlaying', action.payload)(prevState);

    case PlayerActions.SET_IS_CONTROLS_VISIBLE:
      return R.assoc('isControlsVisible', action.payload)(prevState);

    case PlayerActions.SET_IS_AUDIO_READY:
      return R.assoc('isAudioReady', action.payload)(prevState);

    case PlayerActions.SET_IS_VIDEO_READY:
      return R.assoc('isVideoReady', action.payload)(prevState);

    case PlayerActions.SET_IS_IMAGE_READY:
      return R.assoc('isImageReady', action.payload)(prevState);

    case PlayerActions.SET_PLAYER_PROGRESS:
      return R.assoc('playerProgress', action.payload)(prevState);

    case PlayerActions.INCREMENT_PLAYER_TIME_SPENT:
      return R.assoc(
        'playerTimeSpent',
        prevState.playerTimeSpent + action.payload,
      )(prevState);

    case PlayerActions.CLEAR_PLAYER:
      return R.assoc(
        'playerTimeSpent',
        prevState.playerTimeSpent,
      )(initPlayerState);

    case PlayerActions.CLEAR_TIME_SPENT:
      return initPlayerState;

    default:
      return prevState;
  }
};

export default playerReducer;
