import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import colors from '../theme/colors';
import fonts from '../theme/fonts';
import spacings from '../theme/spacings';
import effects from '../theme/effects';
import buttons from '../theme/buttons';
import breakpoints from '../theme/breakponits';
import borders from '../theme/borders';

export const theme = {
  ...colors,
  ...fonts,
  ...spacings,
  ...effects,
  ...buttons,
  ...breakpoints,
  ...borders,
};

const AppTheme: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default AppTheme;
