import React from 'react';
import { Box, Text } from 'rebass';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import * as R from 'ramda';
import { Chart, ChartData } from '../models/Chart';
import { AXIS_WIDTH, RESPONSIVE_CONTAINER_STYLE } from './AnswersChartSummary';
import { formatNumber } from '../utils';
import { theme } from '../contexts/AppTheme';
import strings from '../theme/strings';

const AXIS_HEIGHT = 40;
const WEEK_DAYS_NUM = 7;
const TICK_COUNT_DEFAULT = 0;
const AXIS_DOMAIN_DEFAULT = 0;

interface Props {
  chart: Chart;
}

const ProgressChartSummary: React.FC<Props> = ({ chart }) => {
  const firstYAxisTick = React.useMemo(
    () => R.head<number>(R.prop('yAxisTicks')(chart)),
    [chart]
  );

  const lastYAxisTick = React.useMemo(
    () => R.last<number>(R.prop('yAxisTicks')(chart)),
    [chart]
  );

  const xAxisTickFormatter = React.useCallback<(tick: number) => string>(
    (tick) => {
      const lastTick = R.last<number>(R.prop('xAxisTicks')(chart));
      return !R.isNil(lastTick) && !R.equals(tick)(lastTick)
        ? formatNumber(tick)
        : '';
    },
    [chart]
  );

  const renderXAxis = React.useCallback(
    () => (
      <XAxis
        dataKey="week"
        scale="pow"
        axisLine={false}
        tickLine={false}
        tickFormatter={xAxisTickFormatter}
        textAnchor="start"
        width={AXIS_WIDTH}
        height={AXIS_HEIGHT}
        ticks={R.prop('xAxisTicks')(chart)}
        interval={0}
      >
        <Label offset={0} position="insideBottom">
          {strings.coursePage.progressChart.xAxisLabel}
        </Label>
      </XAxis>
    ),
    [chart, xAxisTickFormatter]
  );

  const renderYAxis = React.useCallback(
    () => (
      <YAxis
        type="number"
        axisLine={false}
        interval={0}
        tickLine={false}
        tickMargin={4}
        tickCount={R.inc(R.defaultTo(TICK_COUNT_DEFAULT)(lastYAxisTick))}
        domain={[
          R.defaultTo(AXIS_DOMAIN_DEFAULT)(firstYAxisTick),
          R.defaultTo(AXIS_DOMAIN_DEFAULT)(lastYAxisTick),
        ]}
        tickFormatter={(tick: number) =>
          tick > R.defaultTo(0)(firstYAxisTick)
            ? strings.coursePage.progressChart.yAxisTicks[tick - 1]
            : ''
        }
        width={AXIS_WIDTH}
      />
    ),
    [firstYAxisTick, lastYAxisTick]
  );

  const renderBar = React.useCallback(
    () => (
      <Bar
        dataKey="daysSpentDisplay"
        fill={theme.colors.greenLighter}
        isAnimationActive={false}
      >
        {R.addIndex<ChartData, JSX.Element | null>(R.map)(
          (item, index) =>
            !R.isNil(item) ? (
              <Cell
                key={index}
                fill={
                  R.gt(R.propOr(0, 'daysSpentActual', item), WEEK_DAYS_NUM)
                    ? theme.colors.redLight
                    : theme.colors.greenLighter
                }
              />
            ) : null,
          R.prop('data')(chart)
        )}
      </Bar>
    ),
    [chart]
  );

  return (
    <Box
      height={0}
      display="flex"
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        text: {
          fontSize: 0,
          fill: 'grayDark',
        },
        ...RESPONSIVE_CONTAINER_STYLE,
      }}
    >
      <Text fontWeight="bold" fontSize={3} lineHeight={2} mb={2}>
        {R.prop('name')(chart)}
      </Text>
      <ResponsiveContainer height="100%">
        <BarChart data={R.prop('data')(chart)} barCategoryGap={-1}>
          {renderXAxis()}
          {renderYAxis()}
          {renderBar()}
          <CartesianGrid strokeDasharray="3 3" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(ProgressChartSummary);
