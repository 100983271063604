import React from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import * as R from 'ramda';
import { Routes } from '../navigation/routes';
import { useFirebaseContext } from '../contexts/FirebaseContext';
import useUserSelectors from './useUserSelectors';
import useQuestionSelectors from './useQuestionSelectors';
import { Question } from '../models/Question';
import { AnswerTypes } from '../models/DetailedCourse';
import { WeekMatchParams } from '../navigation/CourseNavigator';

const CURRENT_SLIDE_DEFAULT = 0;
const IS_FORWARD_DEFAULT = true;

export type QuestionTestLocationState = {
  isForward: boolean;
};

const useQuestionTest = () => {
  const history = useHistory();
  const location = useLocation<QuestionTestLocationState>();
  const weekMatch = useRouteMatch<WeekMatchParams>(Routes.WEEK_PAGE);

  const [isAnswerWrong, setIsAnswerWrong] = React.useState(false);
  const [currentQuizAnswer, setCurrentQuizAnswer] = React.useState('');
  const [currentAfterAnswer, setCurrentAfterAnswer] = React.useState('');
  const [currentSlideNum, setCurrentSlideNum] = React.useState(
    CURRENT_SLIDE_DEFAULT,
  );

  const { firebaseState, setUserAnswer, setFinishedQuizQuestion } =
    useFirebaseContext();

  const { getIsQuizCompleted } = useUserSelectors();
  const { getWeekQuizQuestions, getAfterQuestions } = useQuestionSelectors();

  const sliderRef = React.useRef<Slider>(null);

  const isForward = React.useMemo(
    () => R.defaultTo(IS_FORWARD_DEFAULT)(R.prop('isForward', location.state)),
    [location.state],
  );

  const courseId = React.useMemo(
    () => R.pathOr<string | null>(null, ['params', 'courseId'])(weekMatch),
    [weekMatch],
  );

  const weekId = React.useMemo(
    () =>
      R.unless(R.isNil, (week) => parseInt(week, 10))(
        R.pathOr(null, ['params', 'weekId'])(weekMatch),
      ),
    [weekMatch],
  );

  const quizQuestions = React.useMemo(
    () =>
      !R.isNil(courseId) && !R.isNil(weekId)
        ? getWeekQuizQuestions(firebaseState, courseId, weekId)
        : null,
    [courseId, firebaseState, getWeekQuizQuestions, weekId],
  );

  const isQuizCompleted = React.useMemo(
    () =>
      !R.isNil(courseId) &&
      !R.isNil(weekId) &&
      getIsQuizCompleted(firebaseState, courseId, weekId),
    [courseId, firebaseState, getIsQuizCompleted, weekId],
  );

  const [quizQuestionsNum, setQuizQuestionsNum] = React.useState(0);

  // we want to avoid the situation, when the quiz is completed inside the slider, and the slider disappear.
  // that's why we only track the first value
  React.useEffect(() => {
    setQuizQuestionsNum((prevQuizQuestionNum) =>
      prevQuizQuestionNum !== 0
        ? prevQuizQuestionNum
        : !quizQuestions || isQuizCompleted
        ? 0
        : R.length(quizQuestions),
    );
  }, [isQuizCompleted, quizQuestions]);

  const afterQuestions = React.useMemo(
    () =>
      !R.isNil(courseId) ? getAfterQuestions(firebaseState, courseId) : null,
    [courseId, firebaseState, getAfterQuestions],
  );

  const afterQuestionsNum = React.useMemo(
    () => (afterQuestions ? R.length(afterQuestions) : 0),
    [afterQuestions],
  );

  const currentQuizQuestion = React.useMemo(
    () =>
      quizQuestions && currentSlideNum < quizQuestionsNum
        ? R.nth(currentSlideNum)(quizQuestions)
        : null,
    [currentSlideNum, quizQuestions, quizQuestionsNum],
  );

  const isQuizAnswerCorrect = React.useMemo(
    () =>
      !R.isNil(currentQuizQuestion)
        ? R.propEq('correctAnswer', currentQuizAnswer)(currentQuizQuestion)
        : null,
    [currentQuizAnswer, currentQuizQuestion],
  );

  const handleQuizAnswerChange = React.useCallback<
    (event: React.SyntheticEvent<HTMLInputElement, Event>) => void
  >((event) => {
    setCurrentQuizAnswer(event.currentTarget.value);
  }, []);

  const handleAnswerInputChange = React.useCallback<
    (e: React.FormEvent<HTMLInputElement>) => void
  >(
    (e) => {
      const answer =
        e.currentTarget.validity.valid || e.currentTarget.value === ''
          ? e.currentTarget.value
          : currentAfterAnswer;
      setCurrentAfterAnswer(answer);
    },
    [currentAfterAnswer],
  );

  const handleSlideChange = React.useCallback<
    (currentSlide: number, nextSlide: number) => void
  >((currentSlide, nextSlide) => setCurrentSlideNum(nextSlide), []);

  const handleCancelClick = React.useCallback(
    () => history.goBack(),
    [history],
  );

  const handleSubmitClick = React.useCallback(() => {
    if (R.isNil(courseId) || R.isNil(weekId)) return;

    if (!R.isNil(currentQuizQuestion)) {
      if (!isQuizAnswerCorrect) {
        setIsAnswerWrong(true);
        return;
      }

      setIsAnswerWrong(false);
      setFinishedQuizQuestion(
        courseId,
        weekId,
        R.prop('id')(currentQuizQuestion),
      );
    }

    if (
      !R.isNil(afterQuestions) &&
      R.gt(currentSlideNum, R.dec(quizQuestionsNum))
    ) {
      const currentQuestion = R.nth<Question>(
        currentSlideNum - quizQuestionsNum,
        afterQuestions,
      );

      if (!R.isNil(currentQuestion))
        setUserAnswer(
          R.prop('id')(currentQuestion),
          weekId,
          AnswerTypes.after,
          parseInt(currentAfterAnswer, 10),
        );
    }

    if (R.equals(currentSlideNum, quizQuestionsNum + R.dec(afterQuestionsNum)))
      if (isForward) history.go(-2);
      else history.goBack();

    if (
      sliderRef.current &&
      (!R.isEmpty(currentAfterAnswer) || !R.isEmpty(currentQuizAnswer))
    )
      sliderRef.current.slickNext();

    setCurrentAfterAnswer('');
    setCurrentQuizAnswer('');
  }, [
    afterQuestions,
    afterQuestionsNum,
    courseId,
    currentAfterAnswer,
    currentQuizAnswer,
    currentQuizQuestion,
    currentSlideNum,
    history,
    isForward,
    isQuizAnswerCorrect,
    quizQuestionsNum,
    setFinishedQuizQuestion,
    setUserAnswer,
    weekId,
  ]);

  return {
    currentAfterAnswer,
    isAnswerWrong,
    isQuizCompleted,
    sliderRef,
    quizQuestions,
    afterQuestions,
    handleQuizAnswerChange,
    handleAnswerInputChange,
    handleSlideChange,
    handleCancelClick,
    handleSubmitClick,
  };
};

export default useQuestionTest;
