import * as React from 'react';
import { theme } from '../../contexts/AppTheme';

const BackIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <circle cx={18} cy={18} r={17.5} stroke={theme.colors.white} />
    <path
      d="M12.646 18.354a.5.5 0 010-.708l3.182-3.182a.5.5 0 11.707.708L13.707 18l2.828 2.828a.5.5 0 11-.707.707l-3.182-3.181zM23 18.5H13v-1h10v1z"
      fill={theme.colors.white}
    />
  </svg>
);

export default React.memo(BackIcon);
