/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as R from 'ramda';
import { FirebaseState } from '../store/reducers/firebaseReducer';

const useFirestoreSelectors = () => {
  const getIsFirestoreLoading = React.useCallback<
    (state: FirebaseState) => boolean
  >(R.prop('isFirestoreLoading'), []);

  return {
    getIsFirestoreLoading,
  };
};

export default useFirestoreSelectors;
