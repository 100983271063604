import React from 'react';
import { theme } from '../../contexts/AppTheme';

const NextChartIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <circle
      cx={16}
      cy={16}
      r={15}
      stroke={theme.colors.primary}
      strokeWidth={2}
    />
    <path
      d="M20.354 16.354a.5.5 0 000-.708l-3.182-3.182a.5.5 0 10-.707.708L19.293 16l-2.828 2.828a.5.5 0 10.707.707l3.182-3.181zM12 16.5h8v-1h-8v1z"
      fill={theme.colors.primary}
    />
  </svg>
);

export default React.memo(NextChartIcon);
