import * as React from 'react';
import { useFirebaseContext } from '../contexts/FirebaseContext';

const useEmailLinkHandler = () => {
  const { handleSignInLink } = useFirebaseContext();

  React.useEffect(() => {
    handleSignInLink();
  }, [handleSignInLink]);
};

export default useEmailLinkHandler;
