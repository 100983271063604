/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as R from 'ramda';
import { FirebaseState } from '../store/reducers/firebaseReducer';
import { TrackData } from '../models/TrackData';
import { TrackPreviews } from '../models/TrackPreviews';
import { TrackAudios } from '../models/TrackAudios';
import { TrackVideos } from '../models/TrackVideos';
import { CourseDocuments } from '../models/CourseDocuments';

const useTrackSelectors = () => {
  const getTracks = React.useCallback<(state: FirebaseState) => TrackData[]>(
    R.prop('tracks'),
    []
  );

  const getTracksPreviews = React.useCallback<
    (state: FirebaseState) => TrackPreviews
  >(R.prop('tracksPreviews'), []);

  const getTrackPreview = React.useCallback<
    (state: FirebaseState, trackId: string) => string | null
  >((state, trackId) => R.prop(trackId)(getTracksPreviews(state)), []);

  const getTracksAudios = React.useCallback<
    (state: FirebaseState) => TrackAudios
  >(R.prop('tracksAudios'), []);

  const getTrackAudio = React.useCallback<
    (state: FirebaseState, trackId: string) => string | null
  >((state, trackId) => R.prop(trackId)(getTracksAudios(state)), []);

  const getTracksVideos = React.useCallback<
    (state: FirebaseState) => TrackVideos
  >(R.prop('tracksVideos'), []);

  const getTrackVideo = React.useCallback<
    (state: FirebaseState, trackId: string) => string | null
  >((state, trackId) => R.prop(trackId)(getTracksVideos(state)), []);

  const getCourseDocuments = React.useCallback<
    (state: FirebaseState) => CourseDocuments
  >(R.prop('courseDocuments'), []);

  const getWeekDocument = React.useCallback<
    (state: FirebaseState, weekId: number) => string | null
  >((state, weekId) => getCourseDocuments(state)[weekId], []);

  const getWeekTracks = React.useCallback<
    (state: FirebaseState, weekId: number) => TrackData[]
  >((state, weekId) => {
    const weekTracks = R.filter(
      (track) =>
        R.propEq('week', weekId)(track) &&
        R.propEq('type', 'media')(track) &&
        !R.isEmpty(track.audio),
      getTracks(state)
    );
    const trackOrderComparator = R.comparator<TrackData>((a, b) =>
      R.lt(R.prop('order', a), R.prop('order', b))
    );
    return R.sort(trackOrderComparator, weekTracks);
  }, []);

  const getWeekTrack = React.useCallback<
    (
      state: FirebaseState,
      weekId: number,
      trackId: string
    ) => TrackData | undefined
  >(
    (state, weekId, trackId) =>
      R.find(R.propEq('id', trackId), getWeekTracks(state, weekId)),
    [getWeekTracks]
  );

  return {
    getTrackPreview,
    getTrackAudio,
    getTrackVideo,
    getWeekDocument,
    getWeekTracks,
    getWeekTrack,
  };
};

export default useTrackSelectors;
